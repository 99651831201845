/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { XlHome02 } from '../../websites/ads/AdManagerXl';
import { Ads055 } from '../../websites/ads/Ads';
import AdsContainer1 from '../../websites/ads/AdsContainer1';
import { EducationHead } from '../../websites/comps/SectionHead';
import BanglaConverter from '../comps/BanglaConverter';
import EducationMain from '../comps/EducationMain';
import { FacebookLikeSm } from '../comps/FacebookLike';
import LatestMain from '../comps/LatestMain';
import LeadLarge from '../comps/LeadLarge';
import LeadSmall from '../comps/LeadSmall';
import OpinionMain from '../comps/OpinionMain';

function LeftRow2({ article1, article2, article3 }) {
    return (
        <>
            <LeadSmall article={article1 || null} />
            <LeadSmall article={article2 || null} />
            <LeadSmall article={article3 || null} />
        </>
    );
}

function LeftRow3() {
    return (
        <div className="lead-left-3">
            <div className="flex-row align-space-between h-100p">
                <div>
                    <AdsContainer1 />
                </div>
                <div>
                    <AdsContainer1 />
                </div>
                <div>
                    <AdsContainer1 />
                </div>
            </div>
        </div>
    );
}

function LeadSmContainer({ lead, latest, opinion, education, politics, international }) {
    const ref = useRef();

    return (
        <>
            <div className="lead-lead1">
                <LeadLarge article={lead ? lead[0] : null} />
            </div>

            <div className="lead-ads">
                <div className="h-100p" ref={ref}>
                    {/* <Img pRef={ref} cName="w-100p h-100p object-cover" /> */}
                    <Ads055 show />
                </div>
            </div>

            <LeftRow2
                article1={lead ? lead[1] : null}
                article2={lead ? lead[2] : null}
                article3={lead ? lead[3] : null}
            />

            <LeftRow2
                article1={lead ? lead[4] : null}
                article2={lead ? lead[5] : null}
                article3={lead ? lead[6] : null}
            />

            <LeftRow2
                article1={lead ? lead[7] : null}
                article2={lead ? lead[8] : null}
                article3={lead ? lead[9] : null}
            />

            <LatestMain latest={latest} />

            <div className="lead-right-ads">
                <XlHome02 />
            </div>

            <div className="lead-right-opinion">
                <OpinionMain opinion={opinion} />
            </div>

            <div className="lead-right-fb-like">
                <FacebookLikeSm />
            </div>
            <div className="lead-right-converter">
                <BanglaConverter />
            </div>

            <div className="lead-right-education">
                <EducationMain
                    articles={education}
                    limit={7}
                    head={<EducationHead title="শিক্ষা" />}
                />
            </div>

            <div className="lead-right-education">
                <EducationMain
                    moreLink="/politics/"
                    articles={politics}
                    head={<EducationHead title="রাজনীতি" className="red-gradient" />}
                />
            </div>
        </>
    );
}

export default LeadSmContainer;
