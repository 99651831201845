import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSearch } from '../../../contexts/SearchProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import Img from '../../../utils/Img';
import { GetDate } from '../../../utils/Show';

function ShowImage({ image }) {
    const ref = useRef();

    return (
        <div className="sr-img" ref={ref}>
            <Img src={image} alt="news" pRef={ref} />
        </div>
    );
}

function ShowLoadMore({ count, articles, onClickLoadMore }) {
    const { setMessage } = useTemplate();
    const ref = useRef();

    const onclickHandler = () => {
        setMessage('লোড হচ্ছে...');
        ref.current.disabled = true;
        onClickLoadMore()
            .then(() => {
                setMessage(null);
                ref.current.disabled = false;
            })
            .catch(() => {
                ref.current.disabled = false;
            });
    };

    if (!count) {
        return null;
    }

    return count > articles?.length ? (
        <div className="center">
            <button
                type="button"
                onClick={onclickHandler}
                ref={ref}
                className="btn btn-red"
                style={{ margin: '10px auto' }}
            >
                আরও দেখান
            </button>
        </div>
    ) : (
        <p className="sr-message-2">
            সকল ফলাফল দেখানো হয়েছে.
            <br /> আরও ফলাফল পেতে (লেখক, সেকশন, ধরন অথবা তারিখ দ্বারা) ফিল্টার করুন
        </p>
    );
}

function SearchResultPanel() {
    const {
        result: { count, articles },
        onClickLoadMore
    } = useSearch();

    if (articles === null) {
        return <h5 className="sr-message">লোড হচ্ছে...</h5>;
    }

    if (!count) {
        return (
            <>
                <h5 className="sr-message">কিছু পাওয়া যায়নি</h5>
                <p className="sr-message-2">
                    আরও ফলাফল পেতে (লেখক, সেকশন, ধরন অথবা তারিখ দ্বারা) ফিল্টার করুন
                </p>
            </>
        );
    }

    return (
        <div>
            {articles?.map(({ sl, title, summery, published, image, link }) => (
                <Link
                    to={link}
                    className="sr-article flex-row align-space-between position-middle"
                    key={sl}
                >
                    <div className="sr-info fill">
                        <h4>{title}</h4>
                        <h5>{summery}</h5>
                        <span>{ConvertStringBn(GetDate(published))}</span>
                    </div>
                    <ShowImage image={image} />
                </Link>
            ))}

            <ShowLoadMore count={count} articles={articles} onClickLoadMore={onClickLoadMore} />
        </div>
    );
}

export default SearchResultPanel;
