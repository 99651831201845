import React from 'react';
import { Link } from 'react-router-dom';
import { EducationHead } from '../../websites/comps/SectionHead';
import LeadLarge from './LeadLarge';
import LeadSmall from './LeadSmall';

function NationalMain({ articles, moreLink, title, className }) {
    return (
        <div className="national-main flex-row flex-wrap align-space-between position-stretch">
            <div>
                <EducationHead title={title} className={className} />
            </div>

            <div>
                <LeadLarge article={articles ? articles[0] : null} placed="national" />
            </div>
            <div>
                <LeadLarge article={articles ? articles[1] : null} placed="national" />
            </div>

            <div>
                <LeadSmall article={articles ? articles[2] : null} />
            </div>
            <div>
                <LeadSmall article={articles ? articles[3] : null} />
            </div>
            <div>
                <LeadSmall article={articles ? articles[4] : null} />
            </div>

            <div className="national-footer flex-row align-right position-middle">
                <Link to={moreLink}> আরও...</Link>
            </div>
        </div>
    );
}

export default NationalMain;
