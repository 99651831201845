import React, { useState } from 'react';
import { icons } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import { TextInput } from '../../../utils/inputs/FormInput';

function RememberMe() {
    return <small>লগআউট না করা পর্যন্ত, আপনার লগইন ব্রাউসারে সংরক্ষিত থাকবে।</small>;
}

function ForgetPassword() {
    return <a href="/">পাসওয়ার্ড ভুলে গেছেন?</a>;
}

function BtnLogin() {
    return (
        <div className="login-btn">
            <button type="submit" className="auth-btn">
                লগইন
            </button>
        </div>
    );
}

function SocialButtons({ signInWithGoogle, signInWithFacebook }) {
    const { setMessage } = useTemplate();

    const handelGoogleLogin = () => {
        setMessage('গুগল এর মাধ্যমে লগইন হচ্ছে...');

        signInWithGoogle()
            .then(({ user }) => {
                if (user?.uid) {
                    setMessage('লগইন সফল হয়েছে!');
                }
            })
            .catch((err) => {
                if (
                    err.toString() === 'FirebaseError: Firebase: Error (auth/popup-closed-by-user).'
                ) {
                    setMessage('লগইন বাতিল হয়েছে!');
                }
            });
    };

    const handelFacebookLogin = () => {
        setMessage('ফেইসবুক এর মাধ্যমে লগইন হচ্ছে...');

        signInWithFacebook()
            .then(({ user }) => {
                if (user?.uid) {
                    setMessage('লগইন সফল হয়েছে!');
                }
            })
            .catch((err) => {
                if (
                    err.toString() === 'FirebaseError: Firebase: Error (auth/popup-closed-by-user).'
                ) {
                    setMessage('লগইন বাতিল হয়েছে!');
                }
            });
    };

    return (
        <div className="flex-row align-center position-middle">
            <button type="button" className="auth-btn google" onClick={handelGoogleLogin}>
                গুগল
            </button>
            <button type="button" className="auth-btn facebook" onClick={handelFacebookLogin}>
                ফেইসবুক
            </button>
        </div>
    );
}

function RegisterIcons() {
    return (
        <div className="text-center">
            <span>আপনার কোন অ্যাকাউন্ট নাই?</span> <a href="/">অ্যাকাউন্ট খুলুন</a>
        </div>
    );
}

function UserLoginModal({ show, closeModal, signIn, signInWithGoogle, signInWithFacebook }) {
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handelFormData = (ev) => {
        const { name, value } = ev.target;
        setFormData((cuVal) => ({ ...cuVal, [name]: value }));
    };

    const handelFormSubmit = (ev) => {
        ev.preventDefault();

        const email = formData.email.trim();
        const { password } = formData;

        if (!email) {
            setMessage('আপনার ইমেইল এড্রেস লিখুন');
            return;
        }

        if (!password) {
            setMessage('আপনার পাসওয়ার্ড লিখুন');
            return;
        }

        setMessage('লগইন হচ্ছে...');
        signIn(formData.email, formData.password)
            .then(({ user }) => {
                if (user?.uid) {
                    setMessage('লগইন সফল হয়েছে!');
                }
            })
            .catch((err) => {
                if (err.toString() === 'FirebaseError: Firebase: Error (auth/wrong-password).') {
                    setMessage('ইমেইল/পাসওয়ার্ড ঠিক নাই!');
                } else if (
                    err.toString() === 'FirebaseError: Firebase: Error (auth/user-not-found).'
                ) {
                    setMessage('আপনার অ্যাকাউন্ট খুঁজে পাওয়া যায় নাই!');
                }
            });
    };

    if (!show) {
        return false;
    }

    return (
        <div className="modal auth">
            <div className="modal-main">
                <div className="modal-head flex-row align-space-between position-middle">
                    <h3>পাঠকের লগইন</h3>
                    <span role="button" tabIndex={-1} onClick={closeModal}>
                        <img src={icons.close} alt="" />
                    </span>
                </div>
                <div className="modal-body">
                    <form onSubmit={handelFormSubmit}>
                        <TextInput
                            name="email"
                            value={formData.email}
                            setValue={handelFormData}
                            label="ইমেইল"
                            type="email"
                            placeholder="আপনার ইমেইল এড্রেস লিখুন"
                            className={null}
                        />

                        <TextInput
                            name="password"
                            value={formData.password}
                            setValue={handelFormData}
                            label="পাসওয়ার্ড"
                            type="password"
                            placeholder="আপনার পাসওয়ার্ড লিখুন"
                            className={null}
                        />

                        <div className="f-pass">
                            <RememberMe />
                            <br />
                            <ForgetPassword />
                        </div>

                        <BtnLogin />

                        <div className="text-center">অথবা</div>

                        <SocialButtons
                            signInWithGoogle={signInWithGoogle}
                            signInWithFacebook={signInWithFacebook}
                        />

                        <RegisterIcons />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserLoginModal;
