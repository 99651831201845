import React from 'react';
import MenuCont from './MenuCont';

function MenuDevice() {
    return (
        <div className="_menu red-gradient-dark">
            <div className="_container h-100p">
                <MenuCont />
            </div>
        </div>
    );
}

export default MenuDevice;
