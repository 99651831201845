import React, { useEffect } from 'react';
import { templateIcons } from '../../../configs/Icons';

function MessageTemplate({ message, setMessage }) {
    const handleClose = () => {
        setMessage(null);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage(null);
        }, message?.timeout || 8000);

        return () => {
            clearTimeout(timer);
        };
    }, [message, setMessage]);

    if (!message) {
        return null;
    }

    return (
        <div className="template-message flex-row align-space-between position-middle">
            <span>{message?.text || message}</span>
            <button type="button" onClick={handleClose}>
                <img src={templateIcons.tClose} alt="" />
            </button>
        </div>
    );
}

export default MessageTemplate;
