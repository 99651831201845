import { useRef } from 'react';
import { templateIcons } from '../../../configs/Icons';

function ConfirmTemplate({ confirm }) {
    // setConfirm
    const ref = useRef();
    const { icon, text, buttons } = confirm || {};

    if (!confirm) {
        return null;
    }

    return (
        <div className="modal template-confirm" ref={ref}>
            <div className="modal-main">
                <div className="modal-body text-center">
                    <div className="confirm-content flex-col align-center position-space-around">
                        <img src={icon || templateIcons.tConfirm} alt="" />
                        <div>{text}</div>
                        <div className="flex-row align-center position-middle">
                            {buttons.map((button) => (
                                <div key={button.props.id}>{button}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmTemplate;
