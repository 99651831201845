import React from 'react';
import { logos } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import { useComment } from '../../contexts/CommentProvider';
import { useTemplate } from '../../contexts/TemplateProvider';
import FormHandler from '../FormHandler';
import CommentPreview from './CommentPreview';

function PostInput({ addComments }) {
    const { openLoginModal } = useAuth();
    const { setMessage } = useTemplate();
    const { formData, setFormData, handle } = FormHandler({});

    const handelSubmitComment = (ev) => {
        ev.preventDefault();
        setMessage('সংরক্ষণ হচ্ছে...');

        addComments(0, formData)
            .then(({ error, message, action }) => {
                if (error === 0) {
                    setFormData({});
                    setMessage('কমেন্টটি সংরক্ষণ করা হয়েছে।');
                } else {
                    setMessage(message);

                    if (action === 'openLoginWindow') {
                        openLoginModal();
                    }
                }
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <form className="input-section" onSubmit={handelSubmitComment}>
            <div className="flex-row position-stretch">
                <img src={logos.bahannoLogoSm} alt="" className="auth-image" />
                <textarea
                    placeholder="আপনার মন্তব্যটি লিখুন..."
                    className="comment-input fill"
                    spellCheck="false"
                    name="comment"
                    value={formData.comment || ''}
                    onChange={handle}
                />
            </div>
            <div className="flex-row align-right">
                <button type="submit" className={`${formData.comment ? 'active' : ''}`}>
                    সংরক্ষণ করুন
                </button>
            </div>
        </form>
    );
}

function PostPreview({ comments, removeComment, updateLike }) {
    return comments
        ?.sort((a, b) => {
            if (a.time > b.time) return -1;
            if (a.time < b.time) return 1;
            return 0;
        })
        ?.map((item) => (
            <CommentPreview
                comment={item}
                key={item.commentId}
                removeComment={removeComment}
                updateLike={updateLike}
            />
        ));
}

function CommentSection() {
    const { comments, addComments, removeComment, updateLike } = useComment();

    return (
        <div className="comment">
            <div>মন্তব্য করুন</div>
            <hr />
            <PostInput addComments={addComments} />

            <PostPreview
                comments={comments}
                removeComment={removeComment}
                updateLike={updateLike}
            />
        </div>
    );
}

export default CommentSection;
