/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLinkProperty } from '../../../configs/ConfigLinks';
import { useInit } from '../../contexts/InitProvider';
import LayoutProvider from '../../contexts/LayoutProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import HomeLayout from './layouts/HomeLayout';
import HomeLgLayout from './layouts/HomeLgLayout';
import HomeMdLayout from './layouts/HomeMdLayout';
import HomeSmLayout from './layouts/HomeSmLayout';

const req = {
    recent: 5,

    'selected/lead': 10,
    'selected/latest': 5,
    'selected/special': 1,

    'category/opinion': 2,
    'category/education': 6,
    'category/national': 7,
    'category/politics': 6,
    'category/sports': 5,
    'category/crime': 6,
    'category/technology': 6,
    'category/lifestyle': 6,
    'category/expatriation': 6,
    'category/jobs': 6,
    'category/economics': 6,
    'category/international': 6,
    'category/bangladesh': 7,
    'category/health': 5,
    'category/entertainment': 8,
    'category/exclusive': 5,
    'category/literature': 5,
    'category/football': 20,

    gallery: 5
};

function HomePage() {
    const { setSelectedMenu, categories } = useInit();
    const { pathname } = useLocation();
    const category = useMemo(
        () => getLinkProperty(pathname, categories) || {},
        [pathname, categories]
    );
    const [articles, setArticles] = useState(null);

    useEffect(() => {
        AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
            .post(req)
            .then(({ data }) => {
                setArticles(data);
            });
    }, []);

    useEffect(() => {
        const { link, title } = category;
        setSelectedMenu(link);

        document.title = (title !== '' ? `${title} || ` : '') + process.env.REACT_APP_COMPANY_TITLE;
    }, [category, setSelectedMenu]);

    // --Scroll to Top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <LayoutProvider>
            <HomeLayout articles={articles} />
            <HomeLgLayout articles={articles} layout="lg" />
            <HomeMdLayout articles={articles} layout="md" />
            <HomeSmLayout articles={articles} layout={['sm', 'xs', 'xxs']} />
        </LayoutProvider>
    );
}

export default HomePage;
