import React from 'react';
import LeadSmall from '../../home/comps/LeadSmall';
import { EducationHead } from '../../websites/comps/SectionHead';

function CatSectionDetails({ cat, articles }) {
    const arts = articles?.filter((item) => item.page === cat.id) || [];

    if (arts.length === 0) {
        return null;
    }

    return (
        <div>
            <EducationHead title={cat.title} />
            <div
                className={`archive-article flex-row flex-wrap align-space-between ${
                    !articles ? 'placeholder-mode' : ''
                }`}
            >
                {arts.map((item) => (
                    <div key={item.sl}>
                        <LeadSmall article={item} />
                    </div>
                ))}
                {/* Empty Fill */}
                {arts.length % 3 === 2 ? <div /> : null}
            </div>
        </div>
    );
}

function ArchiveCatSection({ articles, categories }) {
    return categories?.map((item) => (
        <CatSectionDetails cat={item} articles={articles} key={item.id} />
    ));
}

export default ArchiveCatSection;
