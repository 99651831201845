import React from 'react';
import SearchFilterPanel from './SearchFilterPanel';
import SearchResultPanel from './SearchResultPanel';
import SearchSectionHead from './SearchSectionHead';

function SearchSection() {
    return (
        <div className="search-section">
            <SearchSectionHead />

            <hr className="search-section-hr" />

            <div className="search-area flex-row align-space-between">
                <div className="search-section-panel">
                    <SearchFilterPanel />
                </div>
                <div className="search-section-result">
                    <SearchResultPanel />
                </div>
            </div>
        </div>
    );
}

export default SearchSection;
