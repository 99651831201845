import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../configs/Icons';
import SocialMenu from '../../app/websites/comps/SocialMenu';
import { useInit } from '../../contexts/InitProvider';

function LeftSideMenu({ showLeftMenu, setShowLeftMenu }) {
    const { categories } = useInit();
    const ref = useRef();

    const handelClose = () => {
        setShowLeftMenu(false);
    };

    useEffect(() => {
        const handleClick = (event) => {
            if (!ref.current.contains(event.target)) {
                setShowLeftMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setShowLeftMenu]);

    return (
        <div className={`modal ${showLeftMenu ? 'show' : ''} left-sidebar-menu`}>
            <div className="modal-main flex-col align-stretch position-space-none" ref={ref}>
                <div className="modal-head flex-row align-space-between position-middle">
                    <h3>পৃষ্ঠা সমূহ:</h3>
                    <span role="button" tabIndex={-1} onClick={handelClose}>
                        <img src={icons.close} alt="" />
                    </span>
                </div>
                <div className="modal-body">
                    <ul className="mobile-menu">
                        <li>
                            <Link to="/" onClick={handelClose}>
                                প্রচ্ছদ
                            </Link>
                        </li>
                        <li>
                            <Link to="/latest/" onClick={handelClose}>
                                সর্বশেষ
                            </Link>
                        </li>
                        {categories?.map(({ id, title, link }) => (
                            <li key={id}>
                                <Link to={link} onClick={handelClose}>
                                    {title}
                                </Link>
                            </li>
                        ))}
                        <li>
                            <Link to="/archive/" onClick={handelClose}>
                                আর্কাইভ
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://converter.bahannonews.com/"
                                className="link-title"
                                target="_blank"
                                rel="noreferrer"
                            >
                                বাংলা কনভার্টার
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="mobile-menu-footer">
                    <span className="mobile-menu-footer-text">আমাদের অনুসরণ করুন</span>
                    <br />
                    <div className="mobile-menu-footer-icons">
                        <SocialMenu />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeftSideMenu;
