/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import mixupTag from '../../../utils/mixupTag';

function findDepth(obj, depth = 0) {
    if (obj.parent) {
        return findDepth(obj.parent, depth + 1);
    }
    return depth;
}

function InstagramMedia({ node }) {
    return <div className="media-instagram-preview">{convertNodeToElement(node)}</div>;
}

function TwitterMedia({ node }) {
    return <div className="media-twitter-preview">{convertNodeToElement(node)}</div>;
}

function MoreRelatedNews({ related }) {
    const { title, link, page, sl } = related || {
        title: '----------',
        link: '/'
    };

    const relatedContent = link
        ? `<a href="/${page}/${sl}/" target="_self">${title}</a>`
        : `<p>${title}</p>`;

    return `<div class="related-news_cont">
            <p class="title">আরও পড়ুন</p>
            <div class="content">
                ${relatedContent}
            </div>
        </div>`;
}

function DetSectionContent({ article, related }) {
    // http://localhost:3000/photogallery/10075037/
    // const htmlString = article?.details || '';
    const [instLoaded, setInstLoaded] = useState(false);

    const transform = (node, index) => {
        // const depth = findDepth(node);
        // if (depth === 0) {
        //     console.log(node, index, depth);
        // }

        if (
            node.type === 'tag' &&
            node.name === 'blockquote' &&
            node.attribs.class === 'instagram-media'
        ) {
            return <InstagramMedia node={node} key={`node${index}`} />;
        }

        if (
            node.type === 'tag' &&
            node.name === 'blockquote' &&
            node.attribs.class === 'twitter-tweet'
        ) {
            return <TwitterMedia node={node} key={`node${index}`} />;
        }

        if (node.type === 'tag' && node.name === 'a') {
            const myNode = { ...node };
            myNode.attribs.target = '_blank';
            return convertNodeToElement(myNode);
        }

        if (node.type === 'script' && node.name === 'script') {
            return null;
        }

        return undefined;
    };

    // console.log(related);

    const { p, figure } = mixupTag(article?.details, ['p', 'figure']);

    // Add border to the second figure element
    if (figure[1]) {
        figure[1] = figure[1].replace(
            '<figure',
            '<figure style="border: 1px solid #000; border-bottom: none;"'
        );
        figure[1] = figure[1].replace(/<table/g, '<table style="border-collapse: collapse; "');
        figure[1] = figure[1].replace(
            /<td/g,
            '<td style="border-bottom:1px solid #000; padding: 3px 10px;"'
        );
        figure[1] = figure[1].replace(/<strong/g, '<strong style="font-weight: bolder;"');
    }
    // console.log(firstTd);

    // Add a div before the last and second-to-last <p> elements
    const lastParagraphIndex = p.length - 1;
    const secondLastParagraphIndex = p.length - 2;
    p.splice(lastParagraphIndex, 0, MoreRelatedNews({ related: related?.[0] }));
    p.splice(secondLastParagraphIndex, 0, MoreRelatedNews({ related: related?.[1] }));

    // join p and figure to use it as htmlString to ReactHtmlParser
    const figureHtml = figure?.join('');
    const pHtml = p?.join('');

    // Combine the figure and p elements
    const htmlString = figureHtml + pHtml;

    // Parse the htmlString and transform the nodes
    const content = ReactHtmlParser(htmlString, { transform });

    useEffect(() => {
        if (content) {
            // Re-initialize SDKs after the content has been loaded
            window.twttr?.widgets?.load();

            window.FB?.XFBML?.parse();

            window.instgrm?.Embeds?.process();
        }
    }, [content]);

    // eslint-disable-next-line

    return <div className="det-cont">{content}</div>;
}

export default DetSectionContent;
