import React from 'react';
import { useLayout } from '../../contexts/LayoutProvider';
import Calendar from '../../utils/calender/Calendar';
import LatestMain from '../home/comps/LatestMain';
import ThreeCat from '../home/comps/ThreeCat';

function SidebarLg({ articles }) {
    const layout = useLayout();
    console.log(layout);

    return (
        <div className="flex-row align-space-between position-stretch bottom-sidebar">
            <div>
                <ThreeCat articles={articles} />
            </div>
            <div>
                <LatestMain latest={articles ? articles.recent : null} />
            </div>
            <div>
                <Calendar />
            </div>
        </div>
    );
}

export default SidebarLg;
