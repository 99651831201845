import { Outlet } from 'react-router-dom';
import SidebarMenu from './comps/SidebarMenu';

function UserPanel() {
    return (
        <div className="_container">
            <div className="user_panel_cont flex-row align-space-between">
                <SidebarMenu />
                <div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default UserPanel;
