import React from 'react';
import { EducationHead } from '../websites/comps/SectionHead';

function ContactPage() {
    return (
        <div className="_static">
            <div className="_container">
                <EducationHead title="যোগাযোগ" />
                <div className="contract-gap"> </div>
                <div className="flex-row align-space-none position-stretch">
                    <div className="contract-left">
                        <div className="contract-info">
                            <h3>Office</h3>
                            <p>151/6, Gazi Tower, Green Road, Panthapath, Dhaka-1205.</p>
                        </div>

                        <div className="contract-info">
                            <h3>Contact No</h3>
                            <p>
                                Mobile:{' '}
                                <a href="tel:+8801535234554" rel="noreferrer">
                                    01535234554
                                </a>
                            </p>
                        </div>

                        <div className="contract-info">
                            <h3>Email</h3>
                            <p>
                                <a
                                    href="mailto:bahannonews@gmail.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    bahannonews@gmail.com
                                </a>
                            </p>
                        </div>

                        <div className="contract-info">
                            <h3>Website</h3>
                            <p>
                                <a
                                    href="https://www.bahannonews.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https://www.bahannonews.com
                                </a>
                            </p>
                        </div>

                        <div className="contract-info">
                            <h3>Follow us on</h3>
                            <p>
                                <a
                                    href="https://www.facebook.com/bahannonews"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https://www.facebook.com/bahannonews
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="contract-right">
                        <div className="contract-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d663.6022162479824!2d90.38697059453227!3d23.7506156586415!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9a96873d0ef%3A0xa62dfa3eb6eb64cb!2sBahanno%20News!5e0!3m2!1sen!2sbd!4v1681033314623!5m2!1sen!2sbd"
                                width="100%"
                                height="100%"
                                style={{ border: 0, width: '100%' }}
                                allowFullScreen=""
                                loading="lazy"
                                title="Bahanno News Location"
                            />
                        </div>
                    </div>
                </div>

                <div className="contract-gap"> </div>
            </div>
        </div>
    );
}

export default ContactPage;
