/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import articleDemoData from '../../data/articleDemoData';
import sidebarDemoData from '../../data/sidebarDemoData';
import AxiosAuth from '../utils/AxiosAuth';

const loadPageOn = 3000;
const MultiPageContext = createContext();

export function useMultiPage() {
    return useContext(MultiPageContext);
}

const req = {
    recent: 20,
    gallery: 5,
    'category/sports': 5,
    'category/entertainment': 5,
    'category/jobs': 5
};

function MultiPageProvider({ children, id }) {
    // const { setSelectedMenu, categories } = useInit();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState(articleDemoData);
    const [index, setIndex] = useState(0); // Current/Visible Index
    const [sidebarData, setSidebarData] = useState(sidebarDemoData);

    // --Articles
    // const article = articles?.articleDetails || null;
    // const pathname = `/${article?.page}/`;
    // const category = getLinkProperty(pathname, categories) || null;

    // --Load Full Page Data (1st Content & Sidebar)
    useEffect(() => {
        setLoading(true);
        AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
            .post({ ...req, articleDetails: id })
            .then(({ data }) => {
                //--
                const arts = data.articleDetails.thisPage
                    .map((item) => ({
                        id: item,
                        data: null
                    }))
                    .map((item) => ({
                        id: item.id,
                        data:
                            data.articleDetails.details.id === item.id
                                ? data.articleDetails.details
                                : null,
                        related:
                            data.articleDetails.details.id === item.id
                                ? data.articleDetails.related
                                : null
                    }));

                setArticles(arts);
                setSidebarData({
                    recent: data.recent,
                    gallery: data.gallery,
                    'category/sports': data['category/sports'],
                    'category/entertainment': data['category/entertainment'],
                    'category/jobs': data['category/jobs']
                });
                setLoading(false);
            });
    }, [id]);

    // --On Scroll Load More Content
    useEffect(() => {
        const loadPage = (nId) => {
            setLoading(true);

            setArticles((cuData) => {
                const arts = [...cuData];

                return arts.map((item) => ({
                    id: item.id,
                    data: nId === item.id ? {} : item.data,
                    related: nId === item.id ? null : item.related
                }));
            });

            AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
                .post({ articleDetails: nId })
                .then(({ data }) => {
                    setArticles((cuData) => {
                        const arts = [...cuData];

                        return arts.map((item) => ({
                            id: item.id,
                            data:
                                data.articleDetails.details.id === item.id
                                    ? data.articleDetails.details
                                    : item.data,
                            related:
                                data.articleDetails.details.id === item.id
                                    ? data.articleDetails.related
                                    : item.related
                        }));
                    });

                    setLoading(false);
                });
        };

        const onScroll = (ev) => {
            const nId = articles.find((item) => item.data === null)?.id;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.offsetHeight;
            const topPosition = ev?.target?.documentElement?.scrollTop || 0;

            if (loadPageOn + windowHeight + topPosition > documentHeight) {
                if (!loading && nId) {
                    loadPage(nId);
                }
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [articles, loading]);

    // --Title & Sidebar
    useEffect(() => {
        if (articles[index]?.data && !articles[index].data.demo) {
            document.title = `${articles[index].data.title} - Bahanno News`;

            const newURL = articles[index].data.link;
            const currentState = window.history.state;
            window.history.replaceState(currentState, '', newURL);
        }
    }, [articles, index, navigate]);

    const value = useMemo(() => {
        const changeIndex = (cId) => {
            setIndex(articles.findIndex((item) => item.id === cId));
        };

        return { index, changeIndex, articles, setArticles, sidebarData };
    }, [articles, index, sidebarData]);

    return <MultiPageContext.Provider value={value}>{children}</MultiPageContext.Provider>;
}

export default MultiPageProvider;
