import React from 'react';
import { EducationHead } from '../websites/comps/SectionHead';

function TcPage() {
    return (
        <div className="_static">
            <div className="_container">
                <EducationHead title="নীতিমালা" />
                <div className="tc-gap"> </div>

                <div className="tc-info">
                    <h3>LOG FILES</h3>
                    <p>
                        Like many other Web sites, bahannonews.com makes use of log files. The
                        information inside the log files includes internet protocol (IP)addresses,
                        type of browser, Internet Service Provider ( ISP ), date/time stamp,
                        referring/exit pages, and number of clicks to analyze trends, administer the
                        site, track user’s movement around the site, and gather demographic
                        information. IP addresses, and other such information are not linked to any
                        information that is personally identifiable.
                    </p>
                </div>

                <div className="tc-info">
                    <h3>COOKIES AND WEB BEACONS</h3>
                    <p>
                        <a href="https://www.bahannonews.com/" target="blank">
                            bahannonews.com
                        </a>{' '}
                        does use cookies to store information about visitors preferences, record
                        user-specific information on which pages the user access or visit, customize
                        Web page content based on visitors browser type or other information that
                        the visitor sends via their browser.
                    </p>
                </div>

                <div className="tc-info">
                    <h3>DOUBLECLICK DART COOKIE</h3>
                    <p>
                        <ol>
                            <li>
                                Google, as a third party vendor, uses cookies to serve ads on
                                bahannonews.com.
                            </li>
                            <li>
                                Google&apos;s use of the DART cookie enables it to serve ads to
                                users based on their visit to{' '}
                                <a href="https://www.bahannonews.com/" target="blank">
                                    bahannonews.com
                                </a>{' '}
                                and other sites on the Internet.
                            </li>
                        </ol>
                    </p>
                </div>

                <div className="tc-gap"> </div>
            </div>
        </div>
    );
}

export default TcPage;
