import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ArchivePage from './app/archive/ArchivePage';
import CategoryPage from './app/category/CategoryPage';
import DetailsPage from './app/details/DetailsPage';
import HomePage from './app/home/HomePage';
import LatestPage from './app/latest/LatestPage';
import SearchPage from './app/search/SearchPage';
import ContactPage from './app/system/ContactPage';
import NotFoundPage from './app/system/NotFoundPage';
import PrivacyPolicyPage from './app/system/PrivacyPolicyPage';
import TcCommentPage from './app/system/TcCommentPage';
import TcPage from './app/system/TcPage';
import TagsPage from './app/tags/TagsPage';
import MenuBrowseHistory from './app/user/comps/MenuBrowseHistory';
import MenuComments from './app/user/comps/MenuComments';
import MenuProfile from './app/user/comps/MenuProfile';
import MenuSavedNews from './app/user/comps/MenuSavedNews';
import UserPanel from './app/user/UserPanel';
import FooterRow1 from './app/websites/footer/FooterRow1';
import FooterRow2 from './app/websites/footer/FooterRow2';
import FooterRow3 from './app/websites/footer/FooterRow3';
import { useInit } from './contexts/InitProvider';
import JustFoundProvider from './contexts/JustFoundProvider';
import DebugInfo from './utils/DebugInfo';
import CookieAccept from './utils/features/CookieAccept';
import FloatingButton from './utils/features/FloatingButton';
import JustFound from './utils/features/JustFound';
import PushNotification from './utils/features/PushNotification';
import ScrollToTop from './utils/features/ScrollToTop';
import TvStyle from './utils/features/TvStyle';
import MenuManage from './utils/menu/MenuManage';

function RouteHandler() {
    const { categories } = useInit();

    return (
        <BrowserRouter>
            <MenuManage />
            <div className="h-20" />

            <Routes>
                {/* Index Page */}
                <Route path="/" element={<HomePage />} />

                {/* Profile Page */}
                <Route path="/user" element={<UserPanel />}>
                    <Route path="profile" element={<MenuProfile />} />
                    <Route path="comments" element={<MenuComments />} />
                    <Route path="saved-news" element={<MenuSavedNews />} />
                    <Route path="history" element={<MenuBrowseHistory />} />
                </Route>

                {/* Latest Page */}
                <Route path="/latest/" element={<LatestPage />} />

                {/* Category Page */}
                {/* <Route path="/:id/" element={<CategoryPage />} /> */}
                {categories.map(({ id }) => (
                    <Route path={id} element={<CategoryPage />} key={id} />
                ))}

                {/* Details Page */}
                {categories.map(({ id }) => (
                    <Route path={`${id}/:id/`} element={<DetailsPage />} key={id} />
                ))}

                {/* Archive Page */}
                <Route path="/archive/" element={<ArchivePage />} />
                <Route path="/archive/:date/" element={<ArchivePage />} />

                {/* Tags Page */}
                <Route path="/tags/:tag/" element={<TagsPage />} />

                {/* Search Page */}
                <Route path="/search/" element={<SearchPage />} />

                {/* Static Pages */}
                <Route path="/contact/" element={<ContactPage />} />
                <Route path="/terms-condition/" element={<TcPage />} />
                <Route path="/terms-condition/comment/" element={<TcCommentPage />} />
                <Route path="/privacy-policy/" element={<PrivacyPolicyPage />} />

                {/* Not Found */}
                <Route path="/*" element={<NotFoundPage />} />
            </Routes>

            <div className="_footer">
                <div className="_footer_row_1">
                    <div className="_container">
                        <FooterRow1 />
                    </div>
                </div>
                <div className="_footer_row_2">
                    <div className="_container h-100p">
                        <FooterRow2 />
                    </div>
                </div>
                <div className="_footer_row_3">
                    <div className="_container h-100p">
                        <FooterRow3 />
                    </div>
                </div>
            </div>

            <CookieAccept />
            <PushNotification timeDelay={5000} />
            <JustFoundProvider>
                <JustFound />
            </JustFoundProvider>
            <ScrollToTop />
            <FloatingButton />
            <TvStyle />
            <DebugInfo show={false} />
        </BrowserRouter>
    );
}
export default RouteHandler;
