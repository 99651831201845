import React from 'react';
import { icons } from '../../../../configs/Icons';

function SocialMenu() {
    return (
        <div className="flex-row align-space-between header-right-buttons">
            <div>
                <a href="." className="btn btn-30">
                    <img src={icons.fbRound} alt="" />
                </a>
            </div>
            <div>
                <a href="." className="btn btn-30">
                    <img src={icons.twRound} alt="" />
                </a>
            </div>
            <div>
                <a href="." className="btn btn-30">
                    <img src={icons.ytRound} alt="" />
                </a>
            </div>
            <div>
                <span className="btn btn-30">
                    <img src={icons.emailRound} alt="" />
                </span>
            </div>
        </div>
    );
}

export default SocialMenu;
