import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/messaging';

// TODO: Replace the following with your app's Firebase project configuration
export const firebaseConfig = {
    name: 'Bahanno News',
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const ConfigFirebase = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
export const rtDb = firebase.database();

export const messaging = navigator.serviceWorker ? firebase.messaging() : null;

export default ConfigFirebase;
