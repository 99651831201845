import { MD5 } from 'crypto-js';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import EmptyPlaceholder from '../placeholders/EmptyPlaceholder';
import AxiosAuth from '../utils/AxiosAuth';

const InitContext = createContext();

export function useInit() {
    return useContext(InitContext);
}

function setLsLocale(country, currency, language) {
    localStorage.setItem('locale', JSON.stringify({ country, currency, language }));
}

function getLsLocale() {
    return JSON.parse(localStorage.getItem('locale'));
}

function InitProvider({ children }) {
    const [initData, setInitData] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState('index');
    const locale = initData?.initData?.locale || getLsLocale();

    const handelChangeLocale = (country, currency, language) => {
        setLsLocale(country, currency, language);
        setInitData((cuData) => ({
            ...cuData,
            initData: { ...cuData.initData, locale: { country, currency, language } }
        }));
    };

    useEffect(() => {
        AxiosAuth.setApiUrl('APP_INIT')
            .post()
            .then(({ data }) => {
                if (data.error === 0) {
                    // Init Info
                    setInitData(data.data);
                    localStorage.setItem('init-id', data.data.initId);
                    localStorage.setItem('init-hash', data.data.initHash);
                    localStorage.setItem('locale', JSON.stringify(data.data.initData.locale));
                }
            });
    }, []);

    useEffect(() => {
        const handleChangeVisibility = () => {
            if (document.visibilityState === 'visible') {
                if (
                    MD5(JSON.stringify(locale)).toString() !==
                    MD5(localStorage.getItem('locale')).toString()
                ) {
                    if (Object.keys(locale).length > 0) {
                        window.location.reload();
                    }
                }
            }
        };
        document.addEventListener('visibilitychange', handleChangeVisibility);

        return () => {
            document.removeEventListener('visibilitychange', handleChangeVisibility);
        };
    }, [locale]);

    const value = useMemo(() => {
        return {
            initData,
            categories: initData?.siteData?.categories || {},
            packages: initData?.siteData?.packages || [],
            currencies: initData?.initData?.currencies || [],
            countries: initData?.initData?.countries || [],
            currentTime: initData?.initData?.currentTime || 0,
            configs: initData?.siteData?.configs || {},
            latestArticles: initData?.siteData?.latestArticles || [],
            locale,
            handelChangeLocale,

            selectedMenu,
            setSelectedMenu
        };
    }, [initData, locale, selectedMenu]);

    if (initData === null) {
        return <EmptyPlaceholder />;
    }

    return <InitContext.Provider value={value}>{children}</InitContext.Provider>;
}

export default InitProvider;
