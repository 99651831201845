export const months = [
    { code: 0, name: 'January', nameSort: 'Jan', days: 31 },
    { code: 1, name: 'February', nameSort: 'Feb', days: 28 },
    { code: 2, name: 'March', nameSort: 'Mar', days: 31 },
    { code: 3, name: 'April', nameSort: 'Apr', days: 30 },
    { code: 4, name: 'May', nameSort: 'May', days: 31 },
    { code: 5, name: 'June', nameSort: 'Jun', days: 30 },
    { code: 6, name: 'July', nameSort: 'Jul', days: 31 },
    { code: 7, name: 'August', nameSort: 'Aug', days: 31 },
    { code: 8, name: 'September', nameSort: 'Sep', days: 30 },
    { code: 9, name: 'October', nameSort: 'Oct', days: 31 },
    { code: 10, name: 'November', nameSort: 'Dec', days: 30 },
    { code: 11, name: 'December', nameSort: 'Jan', days: 31 }
];

export const dayNames = [
    { index: 0, name: 'Sunday', sortName: 'Sun' },
    { index: 1, name: 'Monday', sortName: 'Mon' },
    { index: 2, name: 'Tuesday', sortName: 'Tue' },
    { index: 3, name: 'Wednesday', sortName: 'Wed' },
    { index: 4, name: 'Thursday', sortName: 'Thu' },
    { index: 5, name: 'Friday', sortName: 'Fri' },
    { index: 6, name: 'Saturday', sortName: 'Sat' }
];

export const weeks = [0, 1, 2, 3, 4, 5, 6];

const CalConfig = {
    dayStart: 6,
    holiday: 5
};

export default CalConfig;
