import bahannoLogoLg from '../assets/images/bahanno-logo-lg.svg';
import bahannoLogoSm from '../assets/images/bahanno-logo-sm.svg';

import bellDot from '../assets/images/icons/bell-dot.svg';
import bell from '../assets/images/icons/bell.svg';
import close from '../assets/images/icons/close.svg';
import icoDelete from '../assets/images/icons/delete.svg';
import editor2 from '../assets/images/icons/editor-2.svg';
import editor from '../assets/images/icons/editor.svg';
import emailRound from '../assets/images/icons/email.svg';
import gallery from '../assets/images/icons/gallery.svg';
import likeDownFill from '../assets/images/icons/like-down-fill.svg';
import likeDown from '../assets/images/icons/like-down.svg';
import likeUpFill from '../assets/images/icons/like-up-fill.svg';
import likeUp from '../assets/images/icons/like-up.svg';
import logoutFill from '../assets/images/icons/logout-fill.svg';
import menuWhite from '../assets/images/icons/menu-white.svg';
import menu from '../assets/images/icons/menu.svg';
import profileFill from '../assets/images/icons/profile-fill.svg';
import reply from '../assets/images/icons/reply.svg';
import searchBtn from '../assets/images/icons/search-btn.svg';
import search from '../assets/images/icons/search.svg';
import tags from '../assets/images/icons/tags.svg';
import user from '../assets/images/icons/user.svg';

import arrowDown from '../assets/images/icons/icon-arrow-down.svg';
import iconCross from '../assets/images/icons/icon-cross.svg';
import iconShare from '../assets/images/icons/icon-share.svg';
import iconTop from '../assets/images/icons/icon-top.svg';

import fbRound from '../assets/images/vendor/facebook.svg';
import twRound from '../assets/images/vendor/twitter.svg';
import ytRound from '../assets/images/vendor/youtube.svg';

import nextD from '../assets/images/arrows/next-double.svg';
import nextS from '../assets/images/arrows/next-single.svg';
import prevD from '../assets/images/arrows/prev-double.svg';
import prevS from '../assets/images/arrows/prev-single.svg';

import shareEmailHover from '../assets/images/shares/email-round-hover.svg';
import shareEmail from '../assets/images/shares/email-round.svg';
import shareFacebookHover from '../assets/images/shares/facebook-round-hover.svg';
import shareFacebook from '../assets/images/shares/facebook-round.svg';
import shareInstagram from '../assets/images/shares/instagram-round.svg';
import shareLink from '../assets/images/shares/link-round.svg';
import shareLinkedin from '../assets/images/shares/linkedin-round.svg';
import shareMessenger from '../assets/images/shares/messenger-round.svg';
import sharePinterest from '../assets/images/shares/pinterest-round.svg';
import sharePrintHover from '../assets/images/shares/print-round-hover.svg';
import sharePrint from '../assets/images/shares/print-round.svg';
import shareSaveHover from '../assets/images/shares/save-round-hover.svg';
import shareSave from '../assets/images/shares/save-round.svg';
import shareMainHover from '../assets/images/shares/share-round-hover.svg';
import shareMain from '../assets/images/shares/share-round.svg';
import shareTwitterHover from '../assets/images/shares/twitter-round-hover.svg';
import shareTwitter from '../assets/images/shares/twitter-round.svg';
import shareWhatsapp from '../assets/images/shares/whatsapp-round.svg';

import tClose from '../assets/images/template/close.svg';
import tConfirm from '../assets/images/template/confirm.svg';

import btnNextDay from '../assets/images/btn-next-day.svg';
import btnPrevDay from '../assets/images/btn-prev-day.svg';

export const icons = {
    fbRound,
    twRound,
    ytRound,
    emailRound,
    bell,
    bellDot,
    user,
    menu,
    menuWhite,
    search,
    searchBtn,
    editor,
    editor2,
    gallery,
    tags,
    likeUp,
    likeDown,
    likeUpFill,
    likeDownFill,
    reply,
    icoDelete,
    close,
    profileFill,
    logoutFill,
    iconCross,
    iconShare,
    iconTop,
    arrowDown
};

export const arrows = {
    nextS,
    nextD,
    prevS,
    prevD
};

export const logos = {
    bahannoLogoLg,
    bahannoLogoSm
};

export const shares = {
    shareWhatsapp,
    shareEmailHover,
    shareEmail,
    shareFacebookHover,
    shareFacebook,
    shareInstagram,
    shareLinkedin,
    shareMessenger,
    sharePrintHover,
    sharePrint,
    shareSaveHover,
    shareSave,
    shareMainHover,
    shareMain,
    shareTwitterHover,
    shareTwitter,
    shareLink,
    sharePinterest
};

export const templateIcons = {
    tClose,
    tConfirm
};

export const buttons = {
    btnPrevDay,
    btnNextDay
};

function Icons() {
    return null;
}

export default Icons;
