import React, { useEffect, useRef } from 'react';
import placeholder from '../../assets/images/placeholder.svg';
import iopt from './iopt';

function Img({ src, pRef, cName }) {
    const rImg = useRef();

    useEffect(() => {
        if (src) {
            const w = Math.ceil(pRef.current.offsetWidth / 95) * 95;
            rImg.current.src = iopt(src, w);
        } else {
            rImg.current.src = placeholder;
        }
    }, [pRef, src]);

    return <img className={cName} src={placeholder} alt="" ref={rImg} />;
}

export default Img;
