import React from 'react';
import HeaderContainer from '../../app/websites/comps/HeaderContainer';
import LayoutProvider from '../../contexts/LayoutProvider';
import MenuProvider from '../../contexts/MenuProvider';
import StickyProvider from '../../contexts/StickyProvider';
import MenuDevice from './MenuDevice';
import MenuDeviceSm from './MenuDeviceSm';

function MenuManage() {
    return (
        <MenuProvider>
            <StickyProvider>
                <div className="_header">
                    <div className="_container">
                        <HeaderContainer />
                    </div>
                </div>
                <LayoutProvider id="menu">
                    <MenuDevice />
                    <MenuDeviceSm layout={['sm', 'xs', 'xxs']} />
                </LayoutProvider>
            </StickyProvider>
        </MenuProvider>
    );
}

export default MenuManage;
