/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { icons } from '../../../../configs/Icons';

function SearchOptH({ title, id, handelShowClick }) {
    return (
        <h5
            className="flex-row align-space-between position-middle"
            onClick={() => {
                handelShowClick(id);
            }}
        >
            <span>{title}</span>
            <img
                src={icons.arrowDown}
                alt="Arrow Down"
                className="search-option-arrow-down animate"
            />
        </h5>
    );
}

export default SearchOptH;
