import { Link, useLocation } from 'react-router-dom';
import SidebarMenuTopRow from './SidebarMenuTopRow';

const sidebarMenuList = [
    {
        id: 'profile',
        label: 'My Profile',
        link: '/user/profile/',
        type: 'profile'
    },
    {
        id: 'comments',
        label: 'Comment History',
        link: '/user/comments/',
        type: 'comments'
    },
    {
        id: 'saved-news',
        label: 'Saved News',
        link: '/user/saved-news/',
        type: 'saved-news'
    },
    {
        id: 'history',
        label: 'Browsing History',
        link: '/user/history/',
        type: 'history'
    }
];

function SidebarMenu() {
    const { pathname } = useLocation();

    return (
        <aside className="_sidebar">
            <SidebarMenuTopRow />
            <ul>
                {sidebarMenuList?.map((menu) => (
                    <li
                        key={menu?.id}
                        className={`flex-row position-middle ${menu.link === pathname ? 'active' : ''}`}
                    >
                        <Link to={menu.link} className="user_menu flex-row position-middle">
                            <div className={`user_menu_logo ${menu.type}`} />
                            <span>{menu.label}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default SidebarMenu;
