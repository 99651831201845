import React from 'react';
import { Link } from 'react-router-dom';
import SectionList from '../../system/SectionList';
import { LatestHead } from '../../websites/comps/SectionHead';

const latestPl = [
    {
        sl: 1,
        image: null,
        title: '------------------',
        pre_title: '-----------------------',
        empty: true
    },
    {
        sl: 2,
        image: null,
        title: '------------------',
        pre_title: '-----------------------',
        empty: true
    },
    {
        sl: 3,
        image: null,
        title: '------------------',
        pre_title: '-----------------------',
        empty: true
    },
    {
        sl: 4,
        image: null,
        title: '------------------',
        pre_title: '-----------------------',
        empty: true
    },
    {
        sl: 5,
        image: null,
        title: '------------------',
        pre_title: '-----------------------',
        empty: true
    }
];

function LatestMain({ latest }) {
    const arr = latest || latestPl;
    return (
        <div className="latest-main h-100p flex-col position-space-between align-stretch">
            <div className="latest-head">
                <LatestHead />
            </div>
            <div className="fill">
                <SectionList articles={arr} />
            </div>
            <div className={`latest-footer ${!latest ? 'placeholder-mode' : ''}`}>
                <Link to="/latest/">
                    <div>
                        <span>আরও...</span>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default LatestMain;
