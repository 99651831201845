import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLinkProperty } from '../../../configs/ConfigLinks';
import { useInit } from '../../contexts/InitProvider';
import LayoutProvider from '../../contexts/LayoutProvider';
import { useTemplate } from '../../contexts/TemplateProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import ArticleWide from '../home/comps/ArticleWide';
import LeadLarge from '../home/comps/LeadLarge';
import LeadSmall from '../home/comps/LeadSmall';
import Sidebar from '../system/Sidebar';
import SidebarSm from '../system/SidebarSm';
import AdsContainer0 from '../websites/ads/AdsContainer0';
import AdsContainer1 from '../websites/ads/AdsContainer1';
import { EducationHead } from '../websites/comps/SectionHead';

const req = {
    recent: 5,
    gallery: 5,
    'category/sports': 5,
    'category/entertainment': 5,
    'category/jobs': 5
};

function ShowLoadMore({ handleMoreClick, count }) {
    const ref = useRef();
    const { setMessage } = useTemplate();

    const onclickHandler = () => {
        setMessage('লোড হচ্ছে...');
        ref.current.disabled = true;
        handleMoreClick()
            .then(() => {
                setMessage(null);
                ref.current.disabled = false;
            })
            .catch(() => {
                ref.current.disabled = false;
            });
    };

    return count > 0 ? (
        <div className="center">
            <button
                type="button"
                onClick={onclickHandler}
                ref={ref}
                className="btn btn-red"
                style={{ margin: '10px auto' }}
            >
                আরও দেখান
            </button>
        </div>
    ) : (
        <p className="sr-message-2">এই পাতায় আর কোন সংবাদ নাই।</p>
    );
}

function CategoryPage() {
    const { setSelectedMenu, categories } = useInit();
    const { pathname } = useLocation();
    const [page, setPage] = useState(1);

    const category = useMemo(
        () => getLinkProperty(pathname, categories) || {},
        [pathname, categories]
    );

    const catKey = `category/${category?.link
        ?.split('/')
        ?.filter((item) => !!item)
        ?.join('/')}`;

    // --Articles
    const [articles, setArticles] = useState(null);
    const [newArticles, setNewArticles] = useState(['---']);
    const catArticles = articles ? articles[catKey] : null;

    const handleMoreClick = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
                .post({ [catKey]: { cpp: 20, page: page + 1 } })
                .then(({ data }) => {
                    const nArticles = data[catKey];

                    setArticles((cuData) => ({
                        ...cuData,
                        [catKey]: [...cuData[catKey], ...nArticles]
                    }));
                    setNewArticles(nArticles);
                    setPage((p) => p + 1);
                    resolve(data);
                })
                .then((err) => {
                    reject(err);
                });
        });

    useEffect(() => {
        setArticles(null);
        AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
            .post({ ...req, [catKey]: { cpp: 20, page: 1 } })
            .then(({ data }) => {
                setArticles(data);
            });
    }, [catKey]);

    useEffect(() => {
        const { link, title } = category;
        setSelectedMenu(link);

        document.title = (title !== '' ? `${title} || ` : '') + process.env.REACT_APP_COMPANY_TITLE;
    }, [category, setSelectedMenu]);

    // --Scroll to Top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [category]);

    return (
        <>
            <div className="_ads">
                <div className="_container">
                    <AdsContainer0 />
                </div>
            </div>

            <div className="_category">
                <div className="_container flex-row align-space-between">
                    <div
                        className={`_page_left category-area flex-row flex-wrap align-space-between ${
                            !articles ? 'placeholder-mode' : ''
                        }`}
                    >
                        <div>
                            <EducationHead title={category.title} />
                        </div>
                        <div>
                            <LeadLarge article={catArticles ? catArticles[0] : null} />
                        </div>
                        <div className="flex-col align-stretch position-space-between">
                            <div>
                                <LeadSmall article={catArticles ? catArticles[1] : null} />
                            </div>
                            <div>
                                <LeadSmall article={catArticles ? catArticles[2] : null} />
                            </div>
                        </div>

                        <div>
                            <AdsContainer1 />
                        </div>
                        <div>
                            <AdsContainer1 />
                        </div>
                        <div>
                            <AdsContainer1 />
                        </div>

                        <div>
                            <LeadSmall article={catArticles ? catArticles[3] : null} />
                        </div>
                        <div>
                            <LeadSmall article={catArticles ? catArticles[4] : null} />
                        </div>
                        <div>
                            <LeadSmall article={catArticles ? catArticles[5] : null} />
                        </div>

                        <div>
                            <LeadSmall article={catArticles ? catArticles[6] : null} />
                        </div>
                        <div>
                            <LeadSmall article={catArticles ? catArticles[7] : null} />
                        </div>
                        <div>
                            <LeadSmall article={catArticles ? catArticles[8] : null} />
                        </div>

                        <div>
                            <AdsContainer0 />
                        </div>

                        {catArticles?.slice(9).map((item) => (
                            <div key={item.sl}>
                                <ArticleWide article={item} />
                            </div>
                        ))}

                        <ShowLoadMore
                            handleMoreClick={handleMoreClick}
                            count={newArticles.length}
                        />
                    </div>
                    <div className="_page_right">
                        <LayoutProvider>
                            <Sidebar articles={articles} />
                            <SidebarSm articles={articles} layout="md" />
                        </LayoutProvider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryPage;
