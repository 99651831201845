import React from 'react';
import { Link } from 'react-router-dom';

function FloatingButton() {
    return (
        <div className="floating-button">
            <Link to="/jobs/">চাকরির খবর</Link>
        </div>
    );
}

export default FloatingButton;
