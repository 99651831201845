import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useInit } from '../../../contexts/InitProvider';
import { useMultiPage } from '../../../contexts/MultiPageProvider';
import CommentSection from '../../../utils/comments/CommentSection';
import { EducationHead } from '../../websites/comps/SectionHead';
import DetSectionContent from './DetSectionContent';
import DetSectionRelated from './DetSectionRelated';
import DetSectionSocial from './DetSectionSocial';
import DetSectionTags from './DetSectionTags';
import DetSectionTitle from './DetSectionTitle';

function DetailsMain({ article, related }) {
    const ref = useRef();
    const { setSelectedMenu, initData } = useInit();
    const { changeIndex } = useMultiPage();

    const shareUrl = article?.id
        ? `${process.env.REACT_APP_LIVE_WEB_URL}/details/article/${article.id}`
        : '';
    // https://www.bahannonews.com/details/article/10072181/

    const pageTitle = useMemo(() => {
        // Update title
        const categories = initData?.siteData?.categories || [];
        const category = categories.find((item) => item.id === article?.page) || null;
        // document.title = article?.title || 'Bahanno News';
        setSelectedMenu(category?.link);

        return category?.title;
    }, [article, initData, setSelectedMenu]);

    useLayoutEffect(() => {
        const worker = (id) => {
            changeIndex(id);
        };

        const onScroll = () => {
            const { top } = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (top > 0 && top < windowHeight) {
                worker(article.id, top, windowHeight);
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [article.id, changeIndex]);

    return (
        <div className={`details ${article && article?.demo ? 'placeholder-mode' : ''}`} ref={ref}>
            {/* Article Head */}
            <EducationHead title={pageTitle} />

            <DetSectionTitle preTitle={article?.pre_title} title={article?.title} />

            <DetSectionSocial
                author={article?.author}
                authorImage={article?.author_image}
                timePublished={article?.time_published}
                shareUrl={shareUrl}
                imageUrl={article?.image || ''}
            />

            <DetSectionContent
                article={Object.keys(article).length === 0 ? null : article || null}
                related={related}
            />

            <DetSectionTags tags={article?.tags} />

            <DetSectionSocial hideAuthor shareUrl={shareUrl} imageUrl={article?.image || ''} />

            <CommentSection />

            <DetSectionRelated related={related} />
        </div>
    );
}

export default DetailsMain;
