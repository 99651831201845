import React from 'react';
import iopt from '../../../utils/iopt';

function AdsContainer1() {
    return (
        <img
            src={iopt('https://www.bahannonews.com/static/media/Panjeree-1.4afed62d.png', 400)}
            alt=""
            style={{ width: '100%', height: '100%' }}
        />
    );
}

export default AdsContainer1;
