import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';

function DetSectionTags({ tags }) {
    const tagsAr = tags
        ?.split('#')
        ?.map((item) => item.trim())
        ?.filter((item) => !!item);

    if (!tagsAr?.length) {
        return null;
    }

    return (
        <div className="det-tags flex-row align-space-between position-top">
            <div>
                <img src={icons.tags} alt="" />
            </div>
            <div className="flex-row flex-wrap align-left position-middle fill">
                {tagsAr?.slice(0, 4).map((item) => (
                    <Link to={`/tags/${item}/`} key={item}>
                        {item}
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default DetSectionTags;
