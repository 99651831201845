import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { useAuth } from '../../../contexts/AuthProvider';
import iopt from '../../../utils/iopt';

function getOptions(signOut, setShow) {
    return [
        {
            id: 'profile',
            title: 'আমার প্রোফাইল',
            icon: icons.profileFill,
            link: '/user/profile/',
            onClick: null
        },
        {
            id: 'logout',
            title: 'লগআউট',
            icon: icons.logoutFill,
            link: '/',
            onClick: (ev) => {
                ev.preventDefault();
                signOut();
                setShow(false);
            }
        }
    ];
}

function AuthOptions({ show, setShow, signOut }) {
    const ref = useRef();
    const options = getOptions(signOut, setShow);

    useEffect(() => {
        const handleClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setShow]);

    if (!show) {
        return null;
    }

    return (
        <div className="options" ref={ref}>
            <ul>
                {options.map((item) => (
                    <li key={item.id}>
                        <Link
                            to={item.link}
                            onClick={item.onClick}
                            className="flex-row position-middle h-100p"
                        >
                            <img src={item.icon} alt="" />
                            <span>{item.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function HeaderAuthIcon() {
    const { currentUser, openLoginModal, signOut } = useAuth();
    const [showOptions, setShowOptions] = useState(false);

    if (currentUser?.loginStatus) {
        return (
            <div style={{ position: 'relative' }}>
                <span
                    className="btn btn-30 header-profile-image"
                    onClick={() => {
                        setShowOptions(true);
                    }}
                    role="button"
                    tabIndex={-1}
                >
                    {currentUser?.photoUrl ? (
                        <img src={iopt(currentUser.photoUrl)} alt="" className="auth-user-icon" />
                    ) : (
                        <img src={icons.user} alt="" className="auth-icon" />
                    )}
                </span>
                <AuthOptions show={showOptions} setShow={setShowOptions} signOut={signOut} />
            </div>
        );
    }

    return (
        <div>
            <span role="button" tabIndex={-1} className="btn btn-30" onClick={openLoginModal}>
                {currentUser?.photoUrl ? (
                    <img src={iopt(currentUser.photoUrl)} alt="" className="auth-user-icon" />
                ) : (
                    <img src={icons.user} alt="" className="auth-icon" />
                )}
            </span>
        </div>
    );
}

export default HeaderAuthIcon;
