/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSearch } from '../../../contexts/SearchProvider';
import { TextInput } from '../../../utils/inputs/FormInput';
import SearchOptH from './SearchOptH';

function SearchOptionWriter({ id, handelShowClick, showOption }) {
    const { panelData, onChangeWriter } = useSearch();
    const [filter, setFilter] = useState('');

    const handelFilter = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div className={`search-page-option ${id === showOption ? 'show' : ''}`}>
            <SearchOptH
                title="লেখক"
                id={id}
                handelShowClick={handelShowClick}
                showOption={showOption}
            />
            <div className="option-content animate">
                <div className="search-input-text">
                    <TextInput
                        name="writer_search"
                        value={filter}
                        setValue={handelFilter}
                        label=""
                        type="text"
                        placeholder="লেখক অনুসন্ধান"
                    />
                </div>
                <div className="search-input-checkbox">
                    <ul>
                        {panelData.writer
                            .filter((item) => item.title.split(filter).length > 1)
                            .map(({ id: itemId, title, checked }) => (
                                <li key={itemId}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={itemId}
                                            checked={checked}
                                            onChange={onChangeWriter}
                                        />
                                        <span>{title}</span>
                                    </label>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SearchOptionWriter;
