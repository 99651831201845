import dayjs from 'dayjs';
import 'dayjs/locale/bn'; // Import Bengali locale for Day.js

const bnNumArray = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
const bnMonthArray = [
    'জানুয়ারি',
    'ফেব্রুয়ারি',
    'মার্চ',
    'এপ্রিল',
    'মে',
    'জুন',
    'জুলাই',
    'আগস্ট',
    'সেপ্টেম্বর',
    'অক্টোবর',
    'নভেম্বর',
    'ডিসেম্বর'
];

export const GetDateInBangla = (timeStamp) => {
    const date = dayjs.unix(timeStamp);
    const day = date.date();
    const month = date.month();
    const year = date.year();

    // Formatting day, month, and year to Bangla numerals
    const bnDay = `${bnNumArray[Math.floor(day / 10)]}${bnNumArray[day % 10]}`;
    const bnYear = `${bnNumArray[Math.floor(year / 1000)]}${bnNumArray[Math.floor((year / 100) % 10)]}${bnNumArray[Math.floor((year / 10) % 10)]}${bnNumArray[year % 10]}`;

    const bnDate = `${bnDay} ${bnMonthArray[month]} ${bnYear}`;

    return bnDate;
};

export const GetTimeInBangla = (timeStamp) => {
    // Set Day.js locale to Bengali
    dayjs.locale('bn');

    const date = dayjs.unix(timeStamp);
    const localDate = date.locale('bn'); // Set locale to Bengali

    const hours = localDate.format('hh'); // Get hours in 12-hour format
    const minutes = localDate.format('mm');
    const ampm = localDate.format('A') === 'AM' ? 'এ.এম.' : 'পি.এম.';

    const bnHours = hours
        .split('')
        .map((digit) => bnNumArray[digit])
        .join(''); // Convert each digit to Bangla numeral
    const bnMinutes = minutes
        .split('')
        .map((digit) => bnNumArray[digit])
        .join('');

    const bnTime = `${bnHours} : ${bnMinutes} ${ampm}`;

    return bnTime;
};

export default null;
