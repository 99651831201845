/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { icons } from '../../../../configs/Icons';
import { TextInput } from '../../../utils/inputs/FormInput';

function AcceptTc() {
    return (
        <div>
            <label>
                <input type="checkbox" /> আমাদের{' '}
                <a href="/privacy-policy/" target="_blank">
                    নীতিমালা ও শর্তাবলীতে
                </a>{' '}
                সন্মতি দিন
            </label>
        </div>
    );
}

function BtnSignUp() {
    return (
        <div className="login-btn">
            <button type="button" className="auth-btn">
                অ্যাকাউন্ট খুলুন
            </button>
        </div>
    );
}

function SocialButtons() {
    return (
        <div className="flex-row align-center position-middle">
            <button type="button" className="auth-btn google">
                গুগল
            </button>
            <button type="button" className="auth-btn facebook">
                ফেইসবুক
            </button>
        </div>
    );
}

function LoginOptions() {
    return (
        <div className="text-center">
            <span>আপনার অ্যাকাউন্ট আছে?</span> <a href="/">লগইন করুন</a>
        </div>
    );
}

function UserRegistrationModal({ show }) {
    if (!show) {
        return false;
    }

    return (
        <div className="modal auth">
            <div className="modal-main">
                <div className="modal-head flex-row align-space-between position-middle">
                    <h3>পাঠক রেজিস্ট্রেশন</h3>
                    <img src={icons.close} alt="" />
                </div>
                <div className="modal-body">
                    <div>
                        <TextInput
                            value=""
                            setValue={null}
                            label="নাম"
                            type="email"
                            placeholder="আপনার পূর্ণ নাম লিখুন"
                            className={null}
                        />

                        <TextInput
                            value=""
                            setValue={null}
                            label="ইমেইল"
                            type="email"
                            placeholder="আপনার ইমেইল এড্রেস লিখুন"
                            className={null}
                        />

                        <div className="flex-row align-space-none">
                            <div className="full">
                                <TextInput
                                    value=""
                                    setValue={null}
                                    label="পাসওয়ার্ড"
                                    type="password"
                                    placeholder="আপনার পাসওয়ার্ড লিখুন"
                                    className="full"
                                />
                            </div>
                            <div style={{ maxWidth: '15px' }} />
                            <div className="full">
                                <TextInput
                                    value=""
                                    setValue={null}
                                    label="পুনঃ পাসওয়ার্ড"
                                    type="password"
                                    placeholder="আপনার পাসওয়ার্ড লিখুন"
                                    className={null}
                                />
                            </div>
                        </div>

                        <AcceptTc />

                        <BtnSignUp />

                        <div className="text-center">অথবা</div>

                        <SocialButtons />

                        <LoginOptions />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserRegistrationModal;
