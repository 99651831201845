import React from 'react';
import { EducationHead } from '../../websites/comps/SectionHead';
import LeadLarge from '../comps/LeadLarge';
import LeadSmall from '../comps/LeadSmall';

function SportsContainer({ moreLink, articles }) {
    return (
        <div className="sports-main flex-col align-stretch h-100p">
            <div>
                <EducationHead
                    moreLink={moreLink}
                    title="খেলাধুলা"
                    titleBg="red-gradient"
                    myStyles={null}
                />
            </div>
            <div style={{ height: '10px' }} />
            <div className="sports-sub flex-row align-space-between position-stretch fill">
                <div className="sports-col-1 flex-col align-stretch">
                    <div className="fill">
                        <LeadSmall article={articles ? articles[0] : null} />
                    </div>
                    <div className="fill">
                        <LeadSmall article={articles ? articles[1] : null} />
                    </div>
                </div>
                <div className="sports-col-2 flex-col align-stretch">
                    <div className="fill">
                        <LeadLarge article={articles ? articles[2] : null} />
                    </div>
                </div>
                <div className="sports-col-1 flex-col align-stretch">
                    <div className="fill">
                        <LeadSmall article={articles ? articles[3] : null} />
                    </div>
                    <div className="fill">
                        <LeadSmall article={articles ? articles[4] : null} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SportsContainer;
