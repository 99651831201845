import { MD5 } from 'crypto-js';
import { getToken } from 'firebase/messaging';
import React from 'react';
import { messaging } from '../../../configs/ConfigFirebase';
import AxiosAuth from '../../utils/AxiosAuth';

const vapidKey = process.env.REACT_APP_VAPID_KEY;
const DOMAIN_ID = process.env.REACT_APP_BIKIRAN_PUSH_DOMAIN_ID;
const DOMAIN_SECRET = process.env.REACT_APP_BIKIRAN_PUSH_DOMAIN_SECRET;

function BikiranPush() {
    return <div>BikiranPush</div>;
}

// https://push.bikiran.com/api/save-fcm/10000001/d8e34668eacc5a2673c8096a2d5f0b55/eMcxFn1ZP46SBfa3EcOPsP:APA91bHbg3u0Mzd0MeXc7-luOj44Mkh2ItCTKxgv05PVeUChXFiMN_QVIvjl5EejEcI84iS1Jwy5Y6MOAJlkqiaMt5k13FbxqMKIQzhOVvtMPEvh1z5JljAMeWs003cOMP49v9ybw82P/

function addFcmToPushDb(currentToken) {
    return new Promise(() => {
        const hash = MD5(`${DOMAIN_ID}/${DOMAIN_SECRET}/${currentToken}`);

        AxiosAuth.setUrl(
            `https://push.bikiran.com/api/save-fcm/${DOMAIN_ID}/${hash}/?token=${currentToken}`
        ).get();
    });
}

export function RegForNotification() {
    return new Promise((resolve, reject) => {
        if (messaging !== null) {
            getToken(messaging, { vapidKey })
                .then((currentToken) => {
                    // --Registering with BIKIRAN
                    addFcmToPushDb(currentToken)
                        .then(({ data }) => {
                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            reject(Error('Messaging Disabled'));
        }
    });
}

export default BikiranPush;
