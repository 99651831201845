// Usage:
// call the function with the html string and the element types you want to separate from the html string
// for example:
// const { p, figure } = separateElements(article?.details, ['p', 'figure']);

const mixupTag = (html, elementTypes) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const elements = {};

    // Separate elements based on provided types
    elementTypes.forEach((elementType) => {
        const elementsOfType = [];
        doc.querySelectorAll(elementType).forEach((element) => {
            elementsOfType.push(element.outerHTML);
        });
        elements[elementType] = elementsOfType;
    });

    return elements;
};

export default mixupTag;
