import '../assets/style.css';

import RouteHandler from './RouteHandler';
import AuthProvider from './contexts/AuthProvider';
import InitProvider from './contexts/InitProvider';
import SearchProvider from './contexts/SearchProvider';
import TemplateProvider from './contexts/TemplateProvider';
import ComposeProviders from './utils/ComposeProviders';

function App() {
    return (
        <ComposeProviders
            components={[TemplateProvider, InitProvider, AuthProvider, SearchProvider]}
        >
            <RouteHandler />
        </ComposeProviders>
    );
}

export default App;
