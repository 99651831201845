import { Link } from 'react-router-dom';
import { useInit } from '../../../contexts/InitProvider';
import SocialMenu from '../comps/SocialMenu';

// const menuAr = [
//     'প্রচ্ছদ',
//     'সর্বশেষ',
//     'জাতীয়',
//     'রাজনীতি',
//     'সারাদেশ',
//     'আন্তর্জাতিক',
//     'অর্থনীতি',
//     'আইন-আদালত',
//     'শিক্ষা',
//     'বিনোদন',
//     'মতামত',
//     'খেলাধুলা',
//     'স্বাস্থ্যসেবা',
//     'বিজ্ঞান ও প্রযুক্তি',
//     'কাতার বিশ্বকাপ'
// ];

const menu2Ar = [
    { id: 1, title: 'যোগাযোগ', url: '/contact/' },
    { id: 2, title: 'শর্তাবলী', url: '/terms-condition/' },
    { id: 3, title: 'মন্তব্যের নীতিমালা', url: '/terms-condition/comment/' },
    { id: 4, title: 'আর্কাইভ', url: '/archive/' },
    { id: 5, title: 'গোপনীয়তা নীতি', url: '/privacy-policy/' }
];

function FooterRow1() {
    const { categories } = useInit();

    return (
        <div className="flex-row align-space-none position-stretch text-center h-100p">
            <div className="flex-row align-center position-middle footer-menu-1">
                <div>
                    <Link to="/">প্রচ্ছদ</Link>
                    <Link to="/latest/">সর্বশেষ</Link>
                    {categories?.map(({ id, title, link }) => (
                        <Link to={link} key={id}>
                            {title}
                        </Link>
                    ))}
                </div>
            </div>
            <div className="flex-row align-center position-middle footer-menu-1">
                <div>
                    {menu2Ar.map((item) => (
                        <Link to={item.url} key={item.id}>
                            {item.title}
                        </Link>
                    ))}
                    <a href="https://converter.bahannonews.com/" target="_blank" rel="noreferrer">
                        বাংলা কনভার্টার
                    </a>
                </div>
            </div>
            <div className="flex-row align-center position-middle footer-menu-2">
                <div>
                    <span>আমাদেরকে অনুসরণ করুন</span>
                    <br />
                    <SocialMenu />
                </div>
            </div>
        </div>
    );
}

export default FooterRow1;
