import React, { Fragment } from 'react';
import { icons } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import ConvertStringBn from '../ConvertStringBn';

function CommentOptions({ comment, setShow, handelUpdateLike, handelDeleteClick }) {
    const { currentUser } = useAuth();
    const handelShow = () => {
        setShow(true);
    };

    const options = [];
    if (setShow) {
        options.push({
            id: 'setShow',
            comp: (
                <span onClick={handelShow} role="button" tabIndex={-1}>
                    <img src={icons.reply} alt="" />
                </span>
            )
        });
    }

    if (comment.profileId === currentUser.profileId) {
        options.push({
            id: 'delete',
            comp: (
                <span onClick={handelDeleteClick} role="button" tabIndex={-1}>
                    <img src={icons.icoDelete} alt="" />
                </span>
            )
        });
    }

    return (
        <div>
            <div className="comment-op-area flex-row inline position-middle">
                <div className="comment-options op-1 flex-row position-middle">
                    <span onClick={handelUpdateLike} role="button" tabIndex={-1}>
                        <img src={icons.likeUp} alt="" id="like" />
                    </span>
                    <span>{ConvertStringBn(comment.likeCount.toString())}</span>
                    <span onClick={handelUpdateLike} role="button" tabIndex={-1}>
                        <img src={icons.likeDown} alt="" id="dislike" />
                    </span>
                </div>

                {options.length ? (
                    <>
                        <div className="comment-options op-2" />
                        <div className="comment-options op-3 flex-row position-middle">
                            {options.map((item) => (
                                <Fragment key={item.id}>{item.comp}</Fragment>
                            ))}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default CommentOptions;
