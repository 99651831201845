import dayjs from 'dayjs';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CalContext = createContext();

export function useCalc() {
    return useContext(CalContext);
}

function CalProvider({ children }) {
    const navigate = useNavigate();
    const [now, setNow] = useState(dayjs());

    const value = useMemo(() => {
        const onDayClick = (ev, yearX, monthX, dateX) => {
            ev.preventDefault();
            navigate(
                `/archive/${yearX.toString().padStart(2, '0')}-${monthX
                    .toString()
                    .padStart(2, '0')}-${dateX.toString().padStart(2, '0')}/`
            );
        };

        const onNextSingle = (ev) => {
            ev.preventDefault();

            setNow((cNow) => cNow.add(1, 'month'));
        };

        const onPrevSingle = (ev) => {
            ev.preventDefault();

            setNow((cNow) => cNow.subtract(1, 'month'));
        };

        const onNextDouble = (ev) => {
            ev.preventDefault();
            setNow((cNow) => cNow.add(1, 'year'));
        };

        const onPrevDouble = (ev) => {
            ev.preventDefault();
            setNow((cNow) => cNow.subtract(1, 'year'));
        };
        return {
            now,
            onDayClick,
            onNextSingle,
            onPrevSingle,
            onNextDouble,
            onPrevDouble
        };
    }, [navigate, now]);

    return <CalContext.Provider value={value}>{children}</CalContext.Provider>;
}

export default CalProvider;
