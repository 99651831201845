/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../configs/Icons';
import MoreShareIcons from '../../app/details/comps/MoreShareIcons';
import { useJustFound } from '../../contexts/JustFoundProvider';

function JustFound() {
    const { show, article, closeWindow } = useJustFound();
    const [showShare, setShowShare] = useState(false);
    const [cuArticle, setCuArticle] = useState({});

    const shareUrl = article?.sl
        ? `${process.env.REACT_APP_LIVE_WEB_URL}/details/article/${article.sl}`
        : '';

    const handelCloseClick = () => {
        closeWindow();
    };

    const handelShareClick = () => {
        setShowShare(true);
    };

    // --Store the last Article Information
    useEffect(() => {
        if (article) {
            setCuArticle(article);
        }
    }, [article]);

    return (
        <div className={`container-just-found animate ${show ? 'show' : ''}`}>
            <div className="buttons flex-row align-space-around">
                <span>
                    <MoreShareIcons
                        showMore={showShare}
                        setShowMore={setShowShare}
                        position="top"
                        shareUrl={shareUrl}
                        imageUrl={article?.image || ''}
                    />
                    <img src={icons.iconShare} alt="Share Icon" onClick={handelShareClick} />
                </span>
                <span>
                    <img src={icons.iconCross} alt="Cross Icon" onClick={handelCloseClick} />
                </span>
            </div>

            <div className="_container">
                <Link to="/latest/" className="news-btn">
                    সদ্য পাওয়া
                </Link>
                <Link to={cuArticle.link3} className="article-title" onClick={handelCloseClick}>
                    {cuArticle.title}
                </Link>
            </div>
        </div>
    );
}

export default JustFound;
