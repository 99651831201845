import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import Img from '../../../utils/Img';
import { GetDate } from '../../../utils/Show';

function ArticleWide({ article }) {
    const ref = useRef();

    return (
        <Link
            to={article?.link}
            className="article-wide flex-row align-space-between position-middle"
        >
            <div ref={ref}>
                <Img src={article.image} pRef={ref} />
            </div>
            <div className="fill">
                <h2>
                    {article?.pre_title ? (
                        <span className="pre">{article?.pre_title} / </span>
                    ) : null}
                    <span>{article?.title}</span>
                </h2>
                <p>
                    <span>{article?.summery}</span>
                </p>
                <span className="time">{ConvertStringBn(GetDate(article?.published))}</span>
            </div>
        </Link>
    );
}

export default ArticleWide;
