export const api =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEMO_API_URL
        : process.env.REACT_APP_LIVE_API_URL;

const ConfigApi = {
    APP_INIT: `/api-3/init/`,
    PAGE_REQUIREMENT: '/api-3/required/',
    COMMENT: `/api-2/article/comment/`,
    LIKE: `/api-2/article/like/`,
    AUTH: '/bikiran/auth/',

    SEARCH_PANEL: '/api-3/search/panel-data/',
    SEARCH_RESULT: '/api-3/search/result/'
};

export default ConfigApi;
