import React from 'react';

function DetSectionTitle({ preTitle, title }) {
    return (
        <>
            {preTitle ? (
                <h3 className="det-pre-title">
                    <span>{preTitle || '------------'}</span>
                </h3>
            ) : (
                ''
            )}
            <h1 className="det-title">
                <span>{title || '-----------------'}</span>
            </h1>
        </>
    );
}

export default DetSectionTitle;
