import React from 'react';
import { icons } from '../../../../configs/Icons';

function FooterRow2() {
    return (
        <div className="flex-row align-space-between position-middle h-100p">
            <div>
                <img src={icons.editor2} alt="" className="ico-f-editor" />
            </div>
            <div>সম্পাদক ও প্রকাশক: বিভাষ বাড়ৈ</div>
            <div className="fill" />
            <div className="developed-by">
                Developed By:{' '}
                <a href="https://www.bikiran.com/" target="_blank" rel="noreferrer">
                    BIKIARN
                </a>
            </div>
        </div>
    );
}

export default FooterRow2;
