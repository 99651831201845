import React from 'react';
import LeadSmall from '../../home/comps/LeadSmall';

function DetSectionRelated({ related }) {
    return (
        <div className="flex-row flex-wrap align-space-between position-stretch details-related">
            <h3 className="fill">এই সম্পর্কিত আরও সাংবাদ</h3>

            <div>
                <LeadSmall article={related ? related[0] : null} />
            </div>
            <div>
                <LeadSmall article={related ? related[1] : null} />
            </div>
            <div>
                <LeadSmall article={related ? related[2] : null} />
            </div>
            <div>
                <LeadSmall article={related ? related[3] : null} />
            </div>
        </div>
    );
}

export default DetSectionRelated;
