import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Img from '../../utils/Img';

function LatestLarge({ item }) {
    const ref = useRef();

    return (
        <Link
            to={item?.link}
            className={`flex-row position-middle ${item?.empty ? 'placeholder-mode' : ''}`}
        >
            <div ref={ref}>
                <Img src={item?.image} pRef={ref} />
            </div>
            <div className="span">
                {item?.pre_title ? <span className="pre">{item?.pre_title} / </span> : null}
                <span>{item?.title}</span>
            </div>
        </Link>
    );
}

function SectionList({ articles }) {
    return (
        <div className="section-list flex-col align-stretch position-space-around">
            {articles?.slice(0, 4)?.map((item) => (
                <LatestLarge item={item} key={item.sl} />
            ))}
        </div>
    );
}

export default SectionList;
