/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSearch } from '../../../contexts/SearchProvider';
import SearchOptH from './SearchOptH';

function SearchOptionOrganize({ id, handelShowClick, showOption }) {
    const { panelData, onChangeOrganize } = useSearch();

    return (
        <div className={`search-page-option ${id === showOption ? 'show' : ''}`}>
            <SearchOptH
                title="সাজানো"
                id={id}
                handelShowClick={handelShowClick}
                showOption={showOption}
            />
            <div className="option-content animate">
                <div className="search-input-checkbox">
                    <ul>
                        {panelData.organize.map(({ id: typeId, title, checked }) => (
                            <li key={typeId}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={typeId}
                                        checked={checked}
                                        onChange={onChangeOrganize}
                                    />
                                    <span>{title}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SearchOptionOrganize;
