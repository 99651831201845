import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInit } from '../../contexts/InitProvider';
import LayoutProvider from '../../contexts/LayoutProvider';
import { useTemplate } from '../../contexts/TemplateProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import Sidebar from '../system/Sidebar';
import SidebarSm from '../system/SidebarSm';
import AdsContainer0 from '../websites/ads/AdsContainer0';
import { EducationHead } from '../websites/comps/SectionHead';
import TagSection from './comps/TagSection';

const req = {
    recent: 5,
    gallery: 5,
    'category/sports': 5,
    'category/entertainment': 5,
    'category/jobs': 5
};

function ShowLoadMore({ handleMoreClick, count }) {
    const ref = useRef();
    const { setMessage } = useTemplate();

    const onclickHandler = () => {
        setMessage('লোড হচ্ছে...');
        ref.current.disabled = true;
        handleMoreClick()
            .then(() => {
                setMessage(null);
                ref.current.disabled = false;
            })
            .catch(() => {
                ref.current.disabled = false;
            });
    };

    return count > 0 ? (
        <div className="center">
            <button
                type="button"
                onClick={onclickHandler}
                ref={ref}
                className="btn btn-red"
                style={{ margin: '10px auto' }}
            >
                আরও দেখান
            </button>
        </div>
    ) : (
        <p className="sr-message-2">এই পাতায় আর কোন সংবাদ নাই।</p>
    );
}

function TagsPage() {
    const { setSelectedMenu } = useInit();
    const { tag } = useParams();
    const pageKey = `tag/${tag}`;

    // --Articles
    const [articles, setArticles] = useState(null);
    const [page, setPage] = useState(1);
    const [newArticles, setNewArticles] = useState(['---']);

    const handleMoreClick = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
                .post({ [pageKey]: { cpp: 30, page: page + 1 } })
                .then(({ data }) => {
                    const nArticles = data[pageKey];

                    setArticles((cuData) => ({
                        ...cuData,
                        [pageKey]: [...cuData[pageKey], ...nArticles]
                    }));
                    setNewArticles(nArticles);
                    setPage((p) => p + 1);
                    resolve(data);
                })
                .then((err) => {
                    reject(err);
                });
        });

    useEffect(() => {
        setArticles(null);
        AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
            .post({ ...req, [pageKey]: 30 })
            .then(({ data }) => {
                setArticles(data);
            });
    }, [pageKey]);

    useEffect(() => {
        document.title = `TAG || ${process.env.REACT_APP_COMPANY_TITLE}`;
    }, [setSelectedMenu]);

    // --Scroll to Top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <div className="_ads">
                <div className="_container">
                    <AdsContainer0 />
                </div>
            </div>

            <div className="_tags">
                <div className="_container flex-row align-space-between">
                    <div
                        className={`_page_left flex-row flex-wrap align-space-between ${
                            !articles ? 'placeholder-mode' : ''
                        }`}
                    >
                        <div className="tag-section">
                            <EducationHead title={tag} />

                            <TagSection articles={articles?.[pageKey] || null} />
                        </div>

                        <ShowLoadMore
                            handleMoreClick={handleMoreClick}
                            count={newArticles.length}
                        />
                    </div>
                    <div className="_page_right">
                        <LayoutProvider>
                            <Sidebar articles={articles} />
                            <SidebarSm articles={articles} layout="md" />
                        </LayoutProvider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TagsPage;
