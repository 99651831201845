import React, { useEffect, useState } from 'react';

const cookieAcceptName = 'accept-cookie';

function CookieAccept() {
    const [show, setShow] = useState(false);

    const handelAccept = () => {
        localStorage.setItem(cookieAcceptName, '1');
        setShow(false);
    };

    useEffect(() => {
        const cookieAcceptSt = localStorage.getItem(cookieAcceptName) === '1';
        setShow(!cookieAcceptSt);
    }, []);

    if (!show) {
        return null;
    }

    return (
        <div className="cookie-accept-container">
            <p>
                This site uses cookies. By continuing to use this website, you agree to its use. For
                details, please check our{' '}
                <a href="/privacy-policy/" target="_blank">
                    Privacy Policy
                </a>
                .
            </p>
            <button type="button" onClick={handelAccept}>
                Accept Cookie
            </button>
        </div>
    );
}

export default CookieAccept;
