import ConvertStringBn from '../ConvertStringBn';
import { ArrowNextD, ArrowNextS, ArrowPrevD, ArrowPrevS } from './CalArrows';
import { useCalc } from './CalProvider';

export function CalHeadTr({ monthInfo }) {
    const { now, onPrevSingle, onPrevDouble, onNextSingle, onNextDouble } = useCalc();

    return (
        <tr className="calendar-month">
            <td colSpan="7">
                <div>
                    <ArrowPrevD onPrevDouble={onPrevDouble} />
                    <ArrowPrevS onPrevSingle={onPrevSingle} />

                    <span className="calendar-month-text">
                        {ConvertStringBn(`${monthInfo.name}, ${now.year()}`)}
                    </span>

                    <ArrowNextD onNextDouble={onNextDouble} />
                    <ArrowNextS onNextSingle={onNextSingle} />
                </div>
            </td>
        </tr>
    );
}

export function CalWeekDaysTr({ dayNamesMap, conf }) {
    return (
        <tr className="calendar-weekdays">
            {dayNamesMap.map((item) => {
                const clsName = [];
                if (item.index === conf.holiday) {
                    clsName.push('holiday');
                }

                return (
                    <td className={clsName.join(' ')} key={item.sortName}>
                        <span className="calendar-weekdays-text">
                            {ConvertStringBn(item.sortName)}
                        </span>
                    </td>
                );
            })}
        </tr>
    );
}

export function CalTd({ clsName, year, month, date, onDayClick }) {
    return (
        <td className={clsName.join(' ')}>
            <a
                href="/"
                onClick={(ev) => {
                    onDayClick(ev, year, month + 1, date);
                }}
                className="calendar-days-text"
            >
                {date > 0 ? ConvertStringBn(date.toString()) : ' '}
            </a>
        </td>
    );
}

export function CalDays({
    weeks,
    dayNamesMap,
    dayNamesLength,
    dayNames,
    dayStart,
    monthInfo,
    dateToday,
    conf
}) {
    const { now, onDayClick } = useCalc();

    return (
        <>
            {weeks.map((wIndex) => (
                <tr key={weeks[wIndex]}>
                    {dayNamesMap.map((item, i) => {
                        const x = item.index > i ? item.index - dayNamesLength : item.index;
                        const date = wIndex * dayNames.length + x - dayStart + 1;

                        if (date > monthInfo.days) {
                            return <td key={date}> </td>;
                        }

                        const clsName = [];
                        if (date === now.day()) {
                            clsName.push('selected');
                        }

                        if (date === dateToday) {
                            clsName.push('today');
                        }

                        if (item.index === conf.holiday) {
                            clsName.push('holiday');
                        }

                        return (
                            <CalTd
                                key={date}
                                clsName={clsName}
                                year={now.year()}
                                month={now.month()}
                                date={date}
                                onDayClick={onDayClick}
                            />
                        );
                    })}
                </tr>
            ))}
        </>
    );
}

function CalElement() {
    return null;
}

export default CalElement;
