import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';
import { useInit } from '../../contexts/InitProvider';

function MarqueeNews({ articles }) {
    return (
        <>
            <span className="bull">&bull;</span>
            <Link to={articles.link}>{articles.title}</Link>
        </>
    );
}

function TvStyle() {
    const { latestArticles } = useInit();

    return (
        <div className="tv-style flex-row align-space-none position-middle">
            <div className="title">শিরোনাম:</div>
            <div className="text">
                <Marquee pauseOnHover speed={40} overlay={false}>
                    {Object.values(latestArticles).map((rows) => (
                        <MarqueeNews articles={rows} key={rows.sl} />
                    ))}
                </Marquee>
            </div>
        </div>
    );
}

export default TvStyle;
