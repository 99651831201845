import React from 'react';
import Calendar from '../../utils/calender/Calendar';
import LatestMain from '../home/comps/LatestMain';
import ThreeCat from '../home/comps/ThreeCat';
import AdsContainer1 from '../websites/ads/AdsContainer1';

function SidebarSm({ articles }) {
    return (
        <div className="flex-row align-space-between position-stretch bottom-sidebar">
            <div className="flex-col align-stretch position-space-between">
                <div>
                    <AdsContainer1 />
                </div>
                <div>
                    <ThreeCat articles={articles} />
                </div>
            </div>
            <div className="flex-col align-stretch position-space-between">
                <div>
                    <LatestMain latest={articles ? articles.recent : null} />
                </div>
                <div>
                    <Calendar />
                </div>
            </div>
        </div>
    );
}

export default SidebarSm;
