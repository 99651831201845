import dayjs from 'dayjs';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import AxiosAuth from '../utils/AxiosAuth';
import { useInit } from './InitProvider';

const SearchContext = createContext();

export function useSearch() {
    return useContext(SearchContext);
}

const initialPanelData = {
    writer: [],
    section: [],
    type: [
        {
            id: 'all',
            title: 'সব',
            checked: true
        },
        {
            id: 'text',
            title: 'টেক্সট',
            checked: false
        },
        {
            id: 'image',
            title: 'ছবি',
            checked: false
        },
        {
            id: 'video',
            title: 'ভিডিও',
            checked: false
        }
    ],
    organize: [
        {
            id: 'related',
            title: 'প্রাসঙ্গিক',
            checked: true
        },
        {
            id: 'nto',
            title: 'নতুন থেকে পুরোনো',
            checked: false
        },
        {
            id: 'otn',
            title: 'পুরোনো থেকে নতুন',
            checked: false
        }
    ],
    date: `${dayjs().subtract(30, 'days').format('YYYY-MM-DD')} to ${dayjs().format('YYYY-MM-DD')}`
};

function filterSelected(data, initData) {
    const output = {};

    //--
    output.writer = data.writer.filter((item) => item.checked).map((item) => item.id);
    output.section = data.section.filter((item) => item.checked).map((item) => item.id);
    output.type = data.type.filter((item) => item.checked).map((item) => item.id)[0] || '';
    output.organize = data.organize.filter((item) => item.checked).map((item) => item.id)[0] || '';
    output.date = data.date;
    output.count =
        output.writer.length +
        output.section.length +
        (output.type === 'all' ? 0 : 1) +
        (output.organize === 'related' ? 0 : 1) +
        (data.date === initData.date ? 0 : 1);

    return output;
}

function dateToYmd(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and pad with '0' if needed
    const dd = String(date.getDate()).padStart(2, '0'); // Pad with '0' if needed

    return `${yyyy}-${mm}-${dd}`;
}

function SearchProvider({ children }) {
    const { categories } = useInit();
    const [q, setQ] = useState('');
    const [page, setPage] = useState(1);
    const [contentPerPage, setContentPerPage] = useState(20);
    const [panelData, setPanelData] = useState(initialPanelData);
    const [result, setResult] = useState([]);
    const [visible, setVisible] = useState(false);

    // --Load Search Basic Data
    useEffect(() => {
        AxiosAuth.setApiUrl('SEARCH_PANEL')
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    setPanelData((cuData) => ({
                        ...cuData,
                        writer:
                            data?.data?.author?.map((item) => ({
                                id: item,
                                title: item,
                                checked: false
                            })) || [],
                        section:
                            categories?.map(({ id, title }) => ({
                                id,
                                title,
                                checked: false
                            })) || []
                    }));
                }
            });
    }, [categories]);

    // --Load Search Result Data
    useEffect(() => {
        if (visible) {
            const qParams = filterSelected(panelData, initialPanelData);
            AxiosAuth.setApiUrl('SEARCH_RESULT')
                .post({ q, ...qParams, page: 1, contentPerPage })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setResult(data.data);
                    }
                });
        }
    }, [contentPerPage, panelData, q, visible]);

    const value = useMemo(() => {
        const filteredData = filterSelected(panelData, initialPanelData);

        const onChangeWriter = (ev) => {
            const writer = [];

            for (let i = 0; i < panelData.writer.length; i += 1) {
                const obj = panelData.writer[i];
                writer.push({
                    id: obj.id,
                    title: obj.title,
                    checked: ev.target.value === obj.id ? !obj.checked : obj.checked
                });
            }

            setPanelData((cuData) => ({ ...cuData, writer }));
            setPage(1);
        };

        const onChangeSection = (ev) => {
            const section = [];

            for (let i = 0; i < panelData.section.length; i += 1) {
                const obj = panelData.section[i];
                section.push({
                    id: obj.id,
                    title: obj.title,
                    checked: ev.target.value === obj.id ? !obj.checked : obj.checked
                });
            }

            setPanelData((cuData) => ({ ...cuData, section }));
            setPage(1);
        };

        const onChangeType = (ev) => {
            const type = [];
            for (let i = 0; i < panelData.type.length; i += 1) {
                const obj = panelData.type[i];
                type.push({
                    id: obj.id,
                    title: obj.title,
                    checked: ev.target.value === obj.id
                });
            }

            setPanelData((cuData) => ({ ...cuData, type }));
            setPage(1);
        };

        const onChangeOrganize = (ev) => {
            const organize = [];
            for (let i = 0; i < panelData.organize.length; i += 1) {
                const obj = panelData.organize[i];
                organize.push({
                    id: obj.id,
                    title: obj.title,
                    checked: ev.target.value === obj.id
                });
            }

            setPanelData((cuData) => ({ ...cuData, organize }));
            setPage(1);
        };

        const onChangeDate = (values) => {
            if (values.length === 2) {
                const dRange = `${dateToYmd(values[0])} to ${dateToYmd(values[1])}`;
                setPanelData((cuData) => ({ ...cuData, date: dRange }));
                setPage(1);
            }
        };

        const onClickLoadMore = () =>
            new Promise((resolve, reject) => {
                const qParams = filterSelected(panelData, initialPanelData);
                AxiosAuth.setApiUrl('SEARCH_RESULT')
                    .post({ q, ...qParams, page: page + 1, contentPerPage })
                    .then(({ data }) => {
                        if (data.error === 0) {
                            setResult((cuData) => ({
                                ...cuData,
                                count: data.data.count,
                                articles: [...cuData.articles, ...data.data.articles]
                            }));
                            setPage((cuData) => cuData + 1);
                            resolve(data);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const clearFilter = () => {
            setPanelData((cuData) => {
                const { writer, section, type, organize } = cuData;

                return {
                    writer: [...writer.map((d) => ({ ...d, checked: false }))],
                    section: [...section.map((d) => ({ ...d, checked: false }))],
                    type: [...type.map((d) => ({ ...d, checked: d.id === 'all' }))],
                    organize: [...organize.map((d) => ({ ...d, checked: d.id === 'related' }))],
                    date: initialPanelData.date
                };
            });
        };

        return {
            q,
            setQ,
            panelData,
            filteredData,
            result,
            clearFilter,
            onChangeWriter,
            onChangeSection,
            onChangeType,
            onChangeOrganize,
            onChangeDate,
            onClickLoadMore,
            page,
            setPage,
            contentPerPage,
            setContentPerPage,
            setVisible
        };
    }, [contentPerPage, page, panelData, q, result]);

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default SearchProvider;
