import React from 'react';
import { icons } from '../../../../configs/Icons';
import HeaderAuthIcon from './HeaderAuthIcon';

function getLinks(configs) {
    return [
        {
            id: 'favebook',
            link: configs.facebook,
            icon: icons.fbRound
        },
        {
            id: 'twitter',
            link: configs.twitter,
            icon: icons.twRound
        },
        {
            id: 'youtube',
            link: configs.youtube,
            icon: icons.ytRound
        },
        {
            id: 'organizationEmail',
            link: `mailto:${configs.organizationEmail}`,
            icon: icons.emailRound
        }
    ];
}

function ShowLinks({ item }) {
    return (
        <div>
            <a href={item.link} target="_blank" className="btn btn-30" rel="noreferrer">
                <img src={item.icon} alt="" />
            </a>
        </div>
    );
}

function HeaderRightMenu({ configs }) {
    const links = getLinks(configs);

    return (
        <>
            <div className="flex-row align-space-between position-middle header-right-buttons-top">
                <div>
                    <span className="btn">
                        <img src={icons.bell} alt="" />
                    </span>
                </div>

                <HeaderAuthIcon />
            </div>

            <div className="flex-row align-space-between header-right-buttons">
                {links.map((item) => (
                    <ShowLinks item={item} key={item.id} />
                ))}
            </div>
        </>
    );
}

export default HeaderRightMenu;
