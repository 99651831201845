import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Img from '../../../utils/Img';

function LeadSmall({ article, noBorder, placed }) {
    const ref = useRef();

    return (
        <Link
            to={article?.link}
            className={`lead-small ${placed || ''} ${noBorder ? 'no-border' : ''} ${!article ? 'placeholder-mode' : ''}`}
            ref={ref}
        >
            <Img src={article?.image} pRef={ref} />
            <div>
                <h3>
                    {article?.pre_title ? (
                        <span className="pre">{article?.pre_title} / </span>
                    ) : null}
                    {article?.title ? (
                        <span>{article?.title}</span>
                    ) : (
                        <>
                            <span>-----------------------------------------------</span>
                            <br />
                            <span>---------------------</span>
                        </>
                    )}
                </h3>
            </div>
        </Link>
    );
}

export default LeadSmall;
