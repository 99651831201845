import dayjs from 'dayjs';

export function GetDate(timeStamp) {
    const dateFormat = `${dayjs(timeStamp * 1000).format(`DD MMMM YYYY h:mm A`)}`;
    return dateFormat.toString() !== 'Invalid Date' ? dateFormat : '--';
}

function Show() {
    return null;
}

export default Show;
