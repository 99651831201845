import React from 'react';
import LeadSmall from '../../home/comps/LeadSmall';

function TagSection({ articles }) {
    return (
        <div
            className={`archive-article flex-row flex-wrap align-space-between ${
                !articles ? 'placeholder-mode' : ''
            }`}
        >
            {articles?.map((item) => (
                <div key={item.sl}>
                    <LeadSmall article={item} />
                </div>
            ))}

            {/* Empty Fill */}
            {articles && articles.length % 3 === 2 ? <div /> : null}
        </div>
    );
}

export default TagSection;
