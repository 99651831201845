import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMultiPage } from '../../../contexts/MultiPageProvider';
import PopupContent from './comps/PopupContent';
import ToggleArowButton from './comps/ToggleArowButton';

function SidebarPopup() {
    const [scrollDirection, setScrollDirection] = useState('');
    const [show, setShow] = useState(true);

    const { id: paramId } = useParams();

    const { articles } = useMultiPage();

    const data = articles.find((item) => item.id === paramId)?.data;

    const { id } = data || {};
    const shareUrl = id ? `${process.env.REACT_APP_LIVE_WEB_URL}/details/article/${id}` : '';

    // check window scroll
    useEffect(() => {
        let prevTime = dayjs().valueOf();
        let prevScrollPos = window.scrollY;

        const handleScroll = () => {
            const cuTime = dayjs().valueOf();
            const currentScrollPos = window.scrollY;

            if (cuTime - prevTime > 200) {
                if (prevScrollPos - currentScrollPos > 0) {
                    setScrollDirection('up');
                } else if (prevScrollPos - currentScrollPos < 0) {
                    setScrollDirection('down');
                }
                prevTime = cuTime;
            }

            prevScrollPos = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isScrollDown = scrollDirection === 'down';

    const handleToggle = () => {
        setShow((prev) => !prev);
    };

    if (data?.demo) {
        return null;
    }
    if (!show) {
        return (
            <div className={`sidebar-popup_container ${isScrollDown ? 'active' : ''}`}>
                <ToggleArowButton show={show} onClick={handleToggle} />
            </div>
        );
    }

    return (
        <div className={`sidebar-popup_container ${isScrollDown ? 'active' : ''}`}>
            <ToggleArowButton show={show} onClick={handleToggle} />
            <PopupContent shareUrl={shareUrl} imageUrl={data?.image} />
        </div>
    );
}

export default SidebarPopup;
