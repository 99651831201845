export default class FindInArray {
    constructor() {
        this.indexes = {};
        this.parentArray = [];
        this.count = 0;
    }

    init(array, cbOrKey) {
        this.parentArray = array;

        array.forEach((element, i) => {
            if (typeof cbOrKey === 'function') {
                // if callback
                cbOrKey(element, i, this.indexes);
            } else {
                // if key
                this.indexes[element[cbOrKey]] = i;
            }
        });
        return this;
    }

    find(value) {
        const index = this.indexes[value];
        return this.parentArray[index];
    }
}
