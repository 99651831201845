import { Outlet } from 'react-router-dom';

function MenuBrowseHistory() {
    return (
        <div className="_container">
            <div className="flex-row align-center">
                <div>This is Browse History page.</div>
                <Outlet />
            </div>
        </div>
    );
}

export default MenuBrowseHistory;
