import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LayoutProvider from '../../contexts/LayoutProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import Sidebar from '../system/Sidebar';
import SidebarLg from '../system/SidebarLg';
import SidebarSm from '../system/SidebarSm';
import AdsContainer0 from '../websites/ads/AdsContainer0';
import SearchSection from './comps/SearchSection';

const req = {
    recent: 5,
    gallery: 5,
    'category/sports': 5,
    'category/entertainment': 5,
    'category/jobs': 5
};

function SearchPage() {
    const { tag } = useParams();
    const pageKey = `tag/${tag}`;

    // --Articles
    const [articles, setArticles] = useState(null);

    useEffect(() => {
        setArticles(null);
        AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
            .post({ ...req, [pageKey]: 200 })
            .then(({ data }) => {
                setArticles(data);
            });
    }, [pageKey]);

    // --Scroll to Top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <div className="_ads">
                <div className="_container">
                    <AdsContainer0 />
                </div>
            </div>

            <div className="_search">
                <div className="_container flex-row align-space-between">
                    <div
                        className={`_page_left flex-row flex-wrap align-space-between ${
                            !articles ? 'placeholder-mode' : ''
                        }`}
                    >
                        <SearchSection />
                    </div>
                    <div className="_page_right">
                        <LayoutProvider>
                            <Sidebar articles={articles} />
                            <SidebarLg articles={articles} layout="lg" />
                            <SidebarSm articles={articles} layout="md" />
                        </LayoutProvider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchPage;
