import React from 'react';
import { EducationHead } from '../../websites/comps/SectionHead';
import LeadLarge from './LeadLarge';
import LeadSmall from './LeadSmall';

function EconomicsMain({ articles, moreLink, title, titleBg }) {
    return (
        <div className="economics-main flex-row flex-wrap align-space-between position-stretch">
            <div>
                <EducationHead moreLink={moreLink} title={title} titleBg={titleBg} />
            </div>

            <div>
                <LeadLarge article={articles ? articles[0] : null} noBorder placed="grid-head" />
            </div>
            <hr />

            <div>
                <LeadSmall article={articles ? articles[1] : null} noBorder />
            </div>
            <div>
                <LeadSmall article={articles ? articles[2] : null} noBorder />
            </div>
            <div>
                <LeadSmall article={articles ? articles[3] : null} noBorder />
            </div>
            <div>
                <LeadSmall article={articles ? articles[4] : null} noBorder />
            </div>
        </div>
    );
}

export default EconomicsMain;
