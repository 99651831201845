import dayjs from 'dayjs';
import React from 'react';
import { LatestHead } from '../../app/websites/comps/SectionHead';
import CalConfig, { dayNames, months, weeks } from './CalConfig';
import { CalDays, CalHeadTr, CalWeekDaysTr } from './CalElement';
import CalProvider, { useCalc } from './CalProvider';

function getNameMap(conf) {
    const dayNamesMap = [];
    for (let i = conf.dayStart; i < conf.dayStart + dayNames.length; i += 1) {
        const j = i % dayNames.length;
        dayNamesMap.push(dayNames[j]);
    }
    return dayNamesMap;
}

function CalenderVw() {
    const { now } = useCalc();

    //--
    const conf = CalConfig;

    //--
    const monCal = dayjs(new Date(now.year(), now.month(), 1));
    const dayStart = monCal.day();
    const monthInfo = months.find((item) => item.code === now.month()) || {};
    monthInfo.days = monCal.daysInMonth();

    const dayNamesLength = dayNames.length;
    const dayNamesMap = getNameMap(conf);

    const todayTime = new Date();
    const dateToday = todayTime.getDate();

    return (
        <div className="calendar">
            <div className="update-news-title" style={{ marginBottom: 5 }}>
                <LatestHead title="আর্কাইভ" />
            </div>

            <table width="100%" style={{ textAlign: 'center' }}>
                <thead>
                    <CalHeadTr monthInfo={monthInfo} />

                    <CalWeekDaysTr dayNamesMap={dayNamesMap} conf={conf} />
                </thead>

                <tbody>
                    <CalDays
                        weeks={weeks}
                        dayNamesMap={dayNamesMap}
                        dayNamesLength={dayNamesLength}
                        dayNames={dayNames}
                        dayStart={dayStart}
                        monthInfo={monthInfo}
                        dateToday={dateToday}
                        conf={conf}
                    />
                </tbody>
            </table>
        </div>
    );
}

function Calendar() {
    return (
        <CalProvider>
            <CalenderVw />
        </CalProvider>
    );
}

export default Calendar;
