import adsT20 from '../../../../assets/images/ads/ads-T20_2.jpg';
import adsT20sm from '../../../../assets/images/ads/ads-T20_sm_2.jpg';
import { useInit } from '../../../contexts/InitProvider';

function T20Banner() {
    const {
        initData: { t20 }
    } = useInit();

    const size = t20?.length;

    if (size === 2) {
        return (
            <div className="mb-4" style={{ background: '#10054a' }}>
                <div className="t20-ads">
                    <img src={adsT20} alt="T20" />

                    <div className="info">
                        <span className="txt-0">১</span>
                        <span className="txt-1">{`${t20[0].team_1} বনাম ${t20[0].team_2}`}</span>
                        <span className="txt-2">{t20[0].schedule}</span>
                    </div>

                    <div className="info" style={{ left: '66%' }}>
                        <span className="txt-0">২</span>
                        <span className="txt-1">{`${t20[1].team_1} বনাম ${t20[1].team_2}`}</span>
                        <span className="txt-2">{t20[1].schedule}</span>
                    </div>
                </div>

                <div className="t20-ads sm">
                    <img src={adsT20sm} alt="T20" />

                    <div className="info">
                        <span className="txt-0">১</span>
                        <span className="txt-1">{`${t20[0].team_1} বনাম ${t20[0].team_2}`}</span>
                        <span className="txt-2">{t20[0].schedule}</span>
                    </div>

                    <div className="info" style={{ top: '162px' }}>
                        <span className="txt-0">২</span>
                        <span className="txt-1">{`${t20[1].team_1} বনাম ${t20[1].team_2}`}</span>
                        <span className="txt-2">{t20[1].schedule}</span>
                    </div>
                </div>
            </div>
        );
    }
    if (size === 1) {
        return (
            <div className="mb-4" style={{ background: '#10054a' }}>
                <div className="t20-ads">
                    <img src={adsT20} alt="T20" />

                    <div className="info" style={{ left: '49%' }}>
                        <span className="txt-0">১</span>
                        <span className="txt-1">{`${t20[0].team_1} বনাম ${t20[0].team_2}`}</span>
                        <span className="txt-2">{t20[0].schedule}</span>
                    </div>
                </div>

                <div className="t20-ads sm">
                    <img src={adsT20sm} alt="T20" />

                    <div className="info" style={{ top: '105px' }}>
                        <span className="txt-0">১</span>
                        <span className="txt-1">{`${t20[0].team_1} বনাম ${t20[0].team_2}`}</span>
                        <span className="txt-2">{t20[0].schedule}</span>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

export default T20Banner;
