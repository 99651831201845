import React from 'react';

function FooterRow3() {
    return (
        <div className="flex-row align-center position-middle h-100p">
            2022, © All rights reserved www.bahannonews.com
        </div>
    );
}

export default FooterRow3;
