import { onValue, ref } from 'firebase/database';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { rtDb } from '../../configs/ConfigFirebase';
import AxiosAuth from '../utils/AxiosAuth';

const CommentContext = createContext();

export function useComment() {
    return useContext(CommentContext);
}

function CommentProvider({ children, id, currentUser, openLoginModal }) {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const dbRef = ref(rtDb, `/comments/${id}`);
        onValue(dbRef, (snapshot) => {
            setComments(snapshot.val());
        });
    }, [id]);

    const value = useMemo(() => {
        const addComments = (parentSl, formData) =>
            new Promise((resolve, reject) => {
                if (!formData?.comment) {
                    resolve({
                        error: 1,
                        message: 'আপনার মন্তব্য লিখুন!'
                    });
                }

                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('COMMENT', '/add/')
                    .post({
                        articleSl: id,
                        parentSl,
                        commentText: formData?.comment
                    })
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const removeComment = (commentId) =>
            new Promise((resolve, reject) => {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('COMMENT', '/remove/')
                    .post({
                        id,
                        commentId
                    })
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

        const updateLike = (commentId, likeMode) =>
            new Promise((resolve, reject) => {
                if (currentUser?.email === null) {
                    openLoginModal();
                    resolve({ error: 1, message: 'লগইন করুন!' });
                }

                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('LIKE', '/add/')
                    .post({
                        articleSl: id,
                        commentId,
                        likeMode
                    })
                    .then((data2) => {
                        resolve(data2.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        return {
            comments,
            addComments,
            removeComment,
            updateLike
        };
    }, [comments, currentUser, id, openLoginModal]);

    return <CommentContext.Provider value={value}>{children}</CommentContext.Provider>;
}

export default CommentProvider;
