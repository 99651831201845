export default {
    recent: [
        {
            sl: '10119389',
            title: 'যে তিন ফিচারের জন্য অনন্য ইনফিনিক্স নোট ৪০ প্রো',
            pre_title: '',
            image: null,
            summery:
                'সম্প্রতি নোট ৪০ প্রো স্মার্টফোন বাজারে এনে সাড়া ফেলেছে তরুণদের প্রিয় প্রযুক্তি ব্র্যান্ড ইনফিনিক্স। ফোনটিতে যুক্ত করা হয়েছে বেশ কিছু উদ্ভাবনী প্রযুক্তি। এই ফিচারগুলোই বাজেটের ...',
            link: '/technology/10119389/',
            page: 'technology',
            author: 'বিজ্ঞান ও প্রযুক্তি ডেস্ক',
            published: '1714298400',
            demo: true
        },
        {
            sl: '10119388',
            title: '১৮ বছরেই রেফারি মাসফিয়ার ইতিহাস!',
            pre_title: '',
            image: null,
            summery:
                'বৈশ্বিক প্রেক্ষাপটে টেনিস বেশ জনপ্রিয় খেলা হলেও বাংলাদেশে অনগ্রসর খেলার তালিকায় অন্যতম। খেলার প্রচার-প্রসার সেভাবে না হওয়ায় স্বাভাবিকভাবে রেফারিংও অগ্রসর হয়নি। তাই ১৮ বছর বয়সেই ...',
            link: '/sports/10119388/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714298160',
            demo: true
        },
        {
            sl: '10119387',
            title: 'পদত্যাগ করতে পারেন ইসরায়েলি সেনাপ্রধান',
            pre_title: '',
            image: null,
            summery:
                'গোয়েন্দাপ্রধানের পদত্যাগের পর এবার ইসরায়েলি সেনাপ্রধান পদত্যাগ করতে পারেন বলে গুঞ্জণ উঠেছে। শনিবার (২৭ এপ্রিল) ইসরায়েলের বেসরকারি সংবাদমাধ্যম ‘চ্যানেল ১২’ এর বরাতে এই তথ্য জানায় ...',
            link: '/international/10119387/',
            page: 'international',
            author: 'আন্তর্জাতিক ডেস্ক',
            published: '1714298100',
            demo: true
        },
        {
            sl: '10119386',
            title: 'বিএনপি অসহায়ের পাশে দাঁড়ায়, আ’লীগ সরকারি ত্রাণ চুরি করে',
            pre_title: 'ইশরাক',
            image: null,
            summery:
                'বিএনপির আন্তর্জাতিক বিষয়ক কমিটির সদস্য ইশরাক হোসেন বলেছেন, ‘জেল, জুলুম আর নির্যাতিত থাকা অবস্থায়ও দেশের যেকোনো দুর্যোগে অসহায় মানুষের পাশে দাঁড়ায় বিএনপি। আর আওয়ামী লীগ ...',
            link: '/politics/10119386/',
            page: 'politics',
            author: 'স্টাফ রিপোর্টার',
            published: '1714297980',
            demo: true
        },
        {
            sl: '10119385',
            title: 'তীব্র গরমে তিন শিক্ষকের মৃত্যু, ধারণা হিট স্ট্রোক',
            pre_title: '',
            image: null,
            summery:
                'তীব্র গরমের কারণে এক সপ্তাহ ছুটি শেষে দেশের শিক্ষাপ্রতিষ্ঠানে আজ রোববার পাঠদান শুরু হয়েছে। এরই মধ্যে গত ২৪ ঘণ্টায় তিনজন শিক্ষক প্রাণ হারিয়েছেন। ধারণা করা ...',
            link: '/education/10119385/',
            page: 'education',
            author: 'স্টাফ রিপোর্টার',
            published: '1714297860',
            demo: true
        },
        {
            sl: '10119384',
            title: 'ভারতের বিশ্বকাপজয়ী কোচকে নিয়োগ দিলো পাকিস্তান',
            pre_title: '',
            image: null,
            summery:
                'পাকিস্তান ক্রিকেটের রদবদল আর নিয়োগের পর্ব যেন এক অবিরাম প্রকিয়া। তারই অংশ হিসেবে দীর্ঘ প্রতীক্ষার পর দেশটির ক্রিকেট পেল স্থায়ী কোচ। তাও একজন না, সাদা ...',
            link: '/sports/10119384/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714297620',
            demo: true
        },
        {
            sl: '10119383',
            title: 'টানা পাঁচ দফায় কমলো সোনার দাম',
            pre_title: '',
            image: null,
            summery:
                'চলতি মাসে টানা পঞ্চমবারের মতো সোনার দাম কমানোর ঘোষণা দিয়েছে জুয়েলারি ব্যবসায়ীদের সংগঠন বাংলাদেশ জুয়েলার্স অ্যাসোসিয়েশন (বাজুস)। সব‌শেষ ভালো মানের সোনার দাম ভরিতে ৩১৫ টাকা ...',
            link: '/economics/10119383/',
            page: 'economics',
            author: 'অর্থনৈতিক রিপোর্টার',
            published: '1714297260',
            demo: true
        },
        {
            sl: '10119382',
            title: 'ডিপিএস এসটিএস স্কুল ঢাকার ২০২৩-২৪ সেশনের গ্র্যাজুয়েশন অনুষ্ঠান আয়োজিত',
            pre_title: '',
            image: null,
            summery:
                'ডিপিএস এসটিএস সিনিয়র স্কুল অডিটোরিয়ামে আয়োজিত হয়েছে ডিপিএস এসটিএস স্কুল ঢাকার ক্লাস অব ২০২৪-এর গ্র্যাজুয়েশন অনুষ্ঠান। স্কুল থেকে ২০২৩-২৪ শিক্ষাবর্ষে মোট ১১৪ জন শিক্ষার্থী গ্র্যাজুয়েশন ...',
            link: '/education/10119382/',
            page: 'education',
            author: 'স্টাফ রিপোর্টার',
            published: '1714297080',
            demo: true
        },
        {
            sl: '10119381',
            title: 'তাপপ্রবাহে ভয়াবহ সংকটের কবলে মৎস্য ও কৃষিখাত',
            pre_title: '',
            image: null,
            summery:
                'নজিরবিহীন তাপপ্রবাহে ভয়াবহ সংকটের কবলে পরেছে বরিশালসহ উপকূলীয় অঞ্চলের মৎস্য ও কৃষিখাত। মাঠে থাকা প্রায় চার লাখ হেক্টরের বোরো ধান থোর থেকে ফুলস্তরে থাকায় সেচসহ ...',
            link: '/bangladesh/10119381/',
            page: 'bangladesh',
            author: 'বরিশাল প্রতিনিধি',
            published: '1714296900',
            demo: true
        },
        {
            sl: '10119380',
            title: 'অনলাইন নয়, মঙ্গলবার থেকে সশরীরে ক্লাস চবিতে',
            pre_title: '',
            image: null,
            summery:
                'অনলাইনে ক্লাস নেওয়ার সিদ্ধান্ত থেকে সরে এসেছে চট্টগ্রাম বিশ্ববিদ্যালয় (চবি) প্রশাসন। ৩০ এপ্রিল থেকে যাবতীয় ক্লাস কার্যক্রম ও পরীক্ষা সশরীরে নেওয়ার সিদ্ধান্ত নিয়েছে বিশ্ববিদ্যালয় কর্তৃপক্ষ।রোববার ...',
            link: '/education/10119380/',
            page: 'education',
            author: 'চবি প্রতিনিধি',
            published: '1714296840',
            demo: true
        },
        {
            sl: '10119379',
            title: 'দিনমজুর ও রিকশাচালকদের মাঝে পানি ও খাবার স্যালাইন বিতরণ',
            pre_title: 'বাংলাদেশ ইউনিভার্সিটির উদ্যোগ',
            image: null,
            summery:
                'তীব্র তাপপ্রবাহে পুড়ছে দেশ। এতে বিপর্যস্ত হয়ে পড়েছে ঢাকাসহ সারাদেশের জনজীবন। প্রচন্ড গরমেও জীবিকা নির্বাহে কাজ করতে হচ্ছে ঢাকার শ্রমজীবী দিনমজুর, রিকশাচালকদের।রোববার (২৮ এপ্রিল) রাজধানীর ...',
            link: '/national/10119379/',
            page: 'national',
            author: 'স্টাফ রিপোর্টার',
            published: '1714296720',
            demo: true
        },
        {
            sl: '10119378',
            title: 'অভিনেতা সাহিল খান গ্রেপ্তার!',
            pre_title: '',
            image: null,
            summery:
                'বম্বে হাইকোর্ট অভিনেতা সাহিল খানের আগাম জামিনের আবেদন খারিজ করে দেয়। তারপর থেকেই ‘পলাতক’ ছিলেন তিনি। অবশেষে ছত্রিশগড় থেকে গ্রেপ্তার হয়েছেন এই অভিনেতা। মহাদেব বেটিং ...',
            link: '/entertainment/10119378/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1714296600',
            demo: true
        },
        {
            sl: '10119377',
            title: 'দেশের গর্ব নারী আম্পায়ার জেসি নাকি অনভিজ্ঞ!',
            pre_title: '',
            image: null,
            summery:
                'আন্তর্জাতিক ক্রিকেট কাউন্সিল বা আইসিসি আম্পায়ারিং ডেভেলপমেন্ট প্যানেলে হওয়া নারী আম্পায়ার সাথিরা জাকির জেসি। বহু পরীক্ষা নিরিক্ষা, বহু চ্যালেঞ্জ সফলতার সঙ্গে পার হয়ে জাতীয় দলের ...',
            link: '/opinion/10119377/',
            page: 'opinion',
            author: 'স্টাফ রিপোর্টার',
            published: '1714294980',
            demo: true
        },
        {
            sl: '10119376',
            title: 'ইদুল আজহায় পশু আমদানির পরিকল্পনা নেই: প্রাণিসম্পদমন্ত্রী',
            pre_title: '',
            image: null,
            summery:
                'মৎস্য ও প্রাণিসম্পদমন্ত্রী আব্দুর রহমান বলেছেন, ইদুল আজহা উপলক্ষে কোনো পশু আমদানি করার কোনো পরিকল্পনা নেই। পশু যাতে মানুষের ক্রয় ক্ষমতার মধ্যে থাকে সে ব্যাপারে ...',
            link: '/national/10119376/',
            page: 'national',
            author: 'স্টাফ রিপোর্টার',
            published: '1714294860',
            demo: true
        },
        {
            sl: '10119375',
            title: 'এক ম্যাচে বাবরের তিন কীর্তি',
            pre_title: '',
            image: null,
            summery:
                'পাকিস্তানের তারকা ব্যাটার বাবর আজমের অর্জনের মুকুটে আরও পালক যুক্ত হলো। নিউজিল্যান্ডের বিপক্ষে পঞ্চম টি-টোয়েন্টিতে ব্যাটার ও অধিনায়ক হিসেবে তিনটি রেকর্ড গড়েছেন বাবর।লাহোরের গাদ্দাফি স্টেডিয়ামে ...',
            link: '/sports/10119375/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714293960',
            demo: true
        },
        {
            sl: '10119374',
            title: 'সাবান চুরির সাজানো মামলায় কারাভোগ',
            pre_title: '',
            image: null,
            summery:
                'মাত্র একটি সাবান চুরির ঘটনায় সাজানো মামলায় ঘটনাস্থলে না থেকেও প্রধান আসামি হয়ে সাতদিন কারাভোগ করতে হয়েছে মোস্তফা কামালকে।বরিশাল নগরীর ২৮নম্বর ওয়ার্ডের বাসিন্দা মৃত মোজাফফর ...',
            link: '/bangladesh/10119374/',
            page: 'bangladesh',
            author: 'বরিশাল প্রতিনিধি',
            published: '1714293780',
            demo: true
        },
        {
            sl: '10119373',
            title: 'বরিশালের মানুষ তাদের ভোটাধিকার প্রয়োগ করতে চায়',
            pre_title: '',
            image: null,
            summery:
                'প্রথমধাপের বরিশাল সদর উপজেলা পরিষদ নির্বাচনে মোটরসাইকেল মার্কার চেয়ারম্যান প্রার্থী ও বরিশাল প্রেসক্লাবের সাধারণ সম্পাদক এসএম জাকির হোসেন তার নির্বাচনী পথসভায় বলেছেন, এবারের নির্বাচনে দলীয় ...',
            link: '/bangladesh/10119373/',
            page: 'bangladesh',
            author: 'বরিশাল প্রতিনিধি',
            published: '1714293660',
            demo: true
        },
        {
            sl: '10119372',
            title: 'ভারতের মণিপুরে আবারও জাতিগত সহিংসতা!',
            pre_title: '',
            image: null,
            summery:
                'আবারও নতুন করে জাতিগত সংঘর্ষে উত্তপ্ত হয়ে উঠেছে ভারতের মণিপুর রাজ্য। রোববার (২৮ এপ্রিল) সকালে পশ্চিম ইম্ফলে কাংপোকপি জেলা সংলগ্ন কোত্রুক গ্রামে দুই পক্ষের মধ্যে ...',
            link: '/international/10119372/',
            page: 'international',
            author: 'আন্তর্জাতিক ডেস্ক',
            published: '1714293300',
            demo: true
        },
        {
            sl: '10119371',
            title: 'দুর্নীতি লুকাতে বাংলাদেশ ব্যাংকে সাংবাদিক প্রবেশে নিষেধাজ্ঞা: রিজভী',
            pre_title: '',
            image: null,
            summery:
                'আওয়ামী লীগের এমপি, মন্ত্রীর ঘনিষ্ঠজনদের দুর্নীতি ও লুটপাট লুকিয়ে রাখার জন্যেই বাংলাদেশ ব্যাংকে সাংবাদিকদের নিষেধাজ্ঞা দেয়া হয়েছে বলে মন্তব্য করেছেন বিএনপির সিনিয়র যুগ্ম মহাসচিব রুহুল ...',
            link: '/politics/10119371/',
            page: 'politics',
            author: 'স্টাফ রিপোর্টার',
            published: '1714292820',
            demo: true
        },
        {
            sl: '10119370',
            title: 'ডিএনসিসির ৩৫ হাজার রিকশা চালকদের বিনামূল্যে ছাতা ও খাবার স্যালাইন দেওয়া হবে',
            pre_title: 'মেয়র আতিকুল ইসলাম ',
            image: null,
            summery:
                "ঢাকা উত্তর সিটি কর্পোরেশনের মেয়র মোঃ আতিকুল ইসলাম বলেছেন, ' ডিএনসিসি এলাকার ৩৫ হাজার রিকশা চালকদের বিনামূল্যে ১টি করে ছাতা, ১২ প্যাকেট খাবার স্যালাইন ও ...",
            link: '/national/10119370/',
            page: 'national',
            author: 'স্টাফ রিপোর্টার',
            published: '1714292640',
            demo: true
        }
    ],
    gallery: [
        {
            sl: '10119059',
            title: 'তাপমাত্রা ৪০ হলেও কেন হয় ৪৬ ডিগ্রির অস্বস্তি?',
            pre_title: 'আবহাওয়ায় ‘ফিল্‌স লাইক’ কী? মাপা হয় কীভাবে? ',
            image: null,
            summery:
                'বৈশাখী গরমের অস্বস্তিতে নাজেহাল বঙ্গবাসী। গত কয়েক দিন ধরে দক্ষিণবঙ্গের তাপমাত্রা ছাড়িয়ে গিয়েছে ৪০ ডিগ্রির গণ্ডি। জেলায় জেলায় চলছে তাপপ্রবাহ। এমনকি, কলকাতাতেও তাপমাত্রা ৪০-এর বেশি।এই ...',
            link: '/international/10119059/',
            page: 'international',
            author: 'আন্তর্জাতিক ডেস্ক',
            published: '1714031160',
            demo: true
        },
        {
            sl: '10117545',
            title: 'ডুবে যেতে পারে গোটা পৃথিবী!',
            pre_title: '',
            image: null,
            summery:
                'পৃথিবীর গভীরে লুকিয়ে রয়েছে এক সুবিশাল সমুদ্র! শুনতে অবাক লাগলেও বিজ্ঞানীদের নতুন এই দাবি নিয়ে চর্চা তুঙ্গে। কয়েক জন গবেষক দাবি করেছেন, ভূপৃষ্ঠের নীচে এক ...',
            link: '/international/10117545/',
            page: 'international',
            author: 'আন্তর্জাতিক ডেস্ক',
            published: '1712480280',
            demo: true
        },
        {
            sl: '10117542',
            title: 'পণ্ডিত রবিশঙ্করের শেষ কনসার্টে তোলা ছবি',
            pre_title: '',
            image: null,
            summery:
                'পণ্ডিত রবিশঙ্করের বয়স তখন ৯১ বছর। বয়সকে তুড়ি মেরে ক্যালিফোর্নিয়ার লং বিচের হলভর্তি দর্শকের সামনে পরিবেশনা নিয়ে হাজির হয়েছিলেন তিনি। এরপর আর কোনো পরিবেশনায় পাওয়া ...',
            link: '/entertainment/10117542/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1712479680',
            demo: true
        },
        {
            sl: '10116026',
            title: 'তুরস্কে নূহের নৌকার সন্ধান পাওয়া গেছে?',
            pre_title: '',
            image: null,
            summery:
                'নূহের নৌকার গল্পকে সবচেয়ে বেশি চর্চিত ধর্মীয় গল্পগুলোর একটি হিসেবে বিবেচনা করা হয়। বলা হয়ে থাকে যে, এই গল্প শোনেননি, এমন লোক পৃথিবীতে খুব কমই ...',
            link: '/international/10116026/',
            page: 'international',
            author: 'আন্তর্জাতিক ডেস্ক',
            published: '1711139400',
            demo: true
        },
        {
            sl: '10115122',
            title: 'বক্স অফিসে ব্যর্থ, বলিউডের যেসব বড় বাজেটের ছবি!',
            pre_title: '',
            image: null,
            summery:
                'ছবি তৈরির সময় বাজেট বিপুল পরিমাণ হলেও মুক্তির পর বক্স অফিসে তেমন ব্যবসা করতে পারে না অসংখ্য ছবি। বলিপাড়ায় এমন বহু ছবি রয়েছে যেগুলির নির্মাণে ...',
            link: '/entertainment/10115122/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1710310560',
            demo: true
        }
    ],
    'category/sports': [
        {
            sl: '10119388',
            title: '১৮ বছরেই রেফারি মাসফিয়ার ইতিহাস!',
            pre_title: '',
            image: null,
            summery:
                'বৈশ্বিক প্রেক্ষাপটে টেনিস বেশ জনপ্রিয় খেলা হলেও বাংলাদেশে অনগ্রসর খেলার তালিকায় অন্যতম। খেলার প্রচার-প্রসার সেভাবে না হওয়ায় স্বাভাবিকভাবে রেফারিংও অগ্রসর হয়নি। তাই ১৮ বছর বয়সেই ...',
            link: '/sports/10119388/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714298160',
            demo: true
        },
        {
            sl: '10119384',
            title: 'ভারতের বিশ্বকাপজয়ী কোচকে নিয়োগ দিলো পাকিস্তান',
            pre_title: '',
            image: null,
            summery:
                'পাকিস্তান ক্রিকেটের রদবদল আর নিয়োগের পর্ব যেন এক অবিরাম প্রকিয়া। তারই অংশ হিসেবে দীর্ঘ প্রতীক্ষার পর দেশটির ক্রিকেট পেল স্থায়ী কোচ। তাও একজন না, সাদা ...',
            link: '/sports/10119384/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714297620',
            demo: true
        },
        {
            sl: '10119375',
            title: 'এক ম্যাচে বাবরের তিন কীর্তি',
            pre_title: '',
            image: null,
            summery:
                'পাকিস্তানের তারকা ব্যাটার বাবর আজমের অর্জনের মুকুটে আরও পালক যুক্ত হলো। নিউজিল্যান্ডের বিপক্ষে পঞ্চম টি-টোয়েন্টিতে ব্যাটার ও অধিনায়ক হিসেবে তিনটি রেকর্ড গড়েছেন বাবর।লাহোরের গাদ্দাফি স্টেডিয়ামে ...',
            link: '/sports/10119375/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714293960',
            demo: true
        },
        {
            sl: '10119369',
            title: 'করোনার টিকাকে দায়ী করে অবসরে ফরাসি ফুটবলার',
            pre_title: '',
            image: null,
            summery:
                'ফ্রাঁসোয়া জাভিয়ের ফুমু তামুজো নামটা বিশ্ব ফুটবলে একেবারেই অপরিচিত। কখনো নামীদামি ক্লাবে খেলার সুযোগও হয়নি। তবু তাকে নিয়ে আলোচনা হচ্ছে নতুন এক কারণে। চোটের কারণে ...',
            link: '/sports/10119369/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714292700',
            demo: true
        },
        {
            sl: '10119356',
            title: 'মাঠে নামছে চেন্নাই, একাদশে থাকবেন মুস্তাফিজ?',
            pre_title: '',
            image: null,
            summery:
                'ইন্ডিয়ান প্রিমিয়ার লিগের (আইপিএল) ম্যাচে আজ সানরাইজার্স হায়দরাবাদকে ঘরের মাঠে আতিথ্য দেবে চেন্নাই সুপার কিংস। সর্বশেষ দুই ম্যাচে হেরে কিছুটা ব্যাকফুটে আছে মুস্তাফিজুর রহমানরা। তার ...',
            link: '/sports/10119356/',
            page: 'sports',
            author: 'স্পোর্টস ডেস্ক',
            published: '1714289100',
            demo: true
        }
    ],
    'category/entertainment': [
        {
            sl: '10119378',
            title: 'অভিনেতা সাহিল খান গ্রেপ্তার!',
            pre_title: '',
            image: null,
            summery:
                'বম্বে হাইকোর্ট অভিনেতা সাহিল খানের আগাম জামিনের আবেদন খারিজ করে দেয়। তারপর থেকেই ‘পলাতক’ ছিলেন তিনি। অবশেষে ছত্রিশগড় থেকে গ্রেপ্তার হয়েছেন এই অভিনেতা। মহাদেব বেটিং ...',
            link: '/entertainment/10119378/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1714296600',
            demo: true
        },
        {
            sl: '10119367',
            title: 'আইনজীবীর পোশাকে পিয়ার হাসি ভাইরাল, কী বলছেন অভিনেত্রী',
            pre_title: '',
            image: null,
            summery:
                'মডেল, অভিনেত্রী ও আইনজীবী—অনেক পরিচয়েই চেনানো যায় পিয়া জান্নাতুলকে। স্পষ্ট বক্তা হিসেবেও তাঁর পরিচিতি আছে। সম্প্রতি আইনজীবীর পোশাকে পিয়ার একটি ভিডিও ক্লিপ সামাজিক যোগাযোগমাধ্যমে ভাইরাল ...',
            link: '/entertainment/10119367/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1714292100',
            demo: true
        },
        {
            sl: '10119354',
            title: 'ফের বিয়ে করছেন শাকিব খান!',
            pre_title: '',
            image: null,
            summery:
                'পর্দায় একাধিকার বিয়ের পিঁড়িতে বসতে দেখা গেছে শাকিব খানকে। বাস্তবেও বসেছেন দুইবার। শোনা যাচ্ছে ফের বিয়ে করতে যাচ্ছেন এ নায়ক। এবার আর নিজের পছন্দে না, ...',
            link: '/entertainment/10119354/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1714288740',
            demo: true
        },
        {
            sl: '10119345',
            title: 'পাকিস্তানের প্রেক্ষাগৃহে বাংলাদেশের সিনেমা',
            pre_title: '',
            image: null,
            summery:
                'দেশের সীমানা পেরিয়ে এবার পাকিস্তানের প্রেক্ষাগৃহে মুক্তি পেলো বাংলাদেশের সিনেমা ‘মোনা: জ্বীন-২’। লাহোর, করাচি, ইসলামাবাদসহ পাকিস্তানের বিভিন্ন প্রদেশের ২৪টি প্রেক্ষাগৃহে মুক্তি পেয়েছে সিনেমাটি। ‘মোনা: জ্বীন-২’ ...',
            link: '/entertainment/10119345/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1714286160',
            demo: true
        },
        {
            sl: '10119341',
            title: 'শিল্পী সমিতির সদস্যপদ ফিরে পাচ্ছেন জায়েদ খান',
            pre_title: '',
            image: null,
            summery:
                'কয়েকদিন আগেই অনুষ্ঠিত হয় বাংলাদেশ চলচ্চিত্র শিল্পী সমিতির নির্বাচন। বিজয়ের হাসি হেসেছে মিশা-ডিপজল পরিষদ। এবার তারা দিলেন জায়েদ খানসহ বেশ ক’জন শিল্পীকে সুখবর। সমিতির সদস্যপদ ...',
            link: '/entertainment/10119341/',
            page: 'entertainment',
            author: 'বিনোদন ডেস্ক',
            published: '1714285740',
            demo: true
        }
    ],
    'category/jobs': [
        {
            sl: '10119330',
            title: 'বিশাল নিয়োগ দিচ্ছে প্রাণিসম্পদ অধিদপ্তর',
            pre_title: '',
            image: null,
            summery:
                'সম্প্রতি জনবল নিয়োগের বিজ্ঞপ্তি প্রকাশ করেছে প্রাণিসম্পদ অধিদপ্তর। প্রতিষ্ঠানটিতে রাজস্ব খাতভুক্ত ১৩ ক্যাটাগরির পদে ১৬তম গ্রেডে ৬৩৮ জনকে নিয়োগ দেওয়া হবে। আগ্রহীরা আগামী ১৯ মে ...',
            link: '/jobs/10119330/',
            page: 'jobs',
            author: 'বাহান্ন নিউজ ডেস্ক',
            published: '1714277280',
            demo: true
        },
        {
            sl: '10119212',
            title: 'স্নাতক পাশেই ইউএস-বাংলায় চাকরির সুযোগ',
            pre_title: '',
            image: null,
            summery:
                'সম্প্রতি নিয়োগ বিজ্ঞপ্তি প্রকাশ করেছে ইউএস-বাংলা গ্রুপ। প্রতিষ্ঠানটির মেডিকেল ট্যুরিজম বিভাগ চিফ এক্সিকিউটিভ অফিসার পদে লোকবল নিয়োগের জন্য এ বিজ্ঞপ্তি দিয়েছে। ২২ এপ্রিল থেকেই আবেদন ...',
            link: '/jobs/10119212/',
            page: 'jobs',
            author: 'বাহান্ন নিউজ ডেস্ক',
            published: '1714191300',
            demo: true
        },
        {
            sl: '10119162',
            title: 'শেষ হলো ৪৬তম বিসিএসের প্রিলি পরীক্ষা',
            pre_title: '',
            image: null,
            summery:
                'দেশের ৮ বিভাগীয় শহরের ২১৫ কেন্দ্রে একযোগে অনুষ্ঠিত হয়েছে ৪৬তম বিসিএসের প্রিলিমিনারি পরীক্ষা। এবার ৩ হাজার ১৪০টি আসনের বিপরীতে প্রার্থী ছিলেন ৩ লাখ ৩৮ হাজার। ...',
            link: '/jobs/10119162/',
            page: 'jobs',
            author: 'স্টাফ রিপোর্টার',
            published: '1714119300',
            demo: true
        },
        {
            sl: '10119140',
            title: '৫৪ জনকে নিয়োগ দেবে বাংলাদেশ পুলিশ, কর্মস্থল ঢাকা',
            pre_title: '',
            image: null,
            summery:
                'বাংলাদেশ পুলিশের স্পেশাল ব্র্যাঞ্চ, রাজারবাগ বিভাগে ৪টি পদে ৫৪ জনকে নিয়োগ দেওয়া হবে। আগ্রহীরা আগামী ১৬ মে পর্যন্ত আবেদন করতে পারবেন।প্রতিষ্ঠানের নাম: বাংলাদেশ পুলিশবিভাগের নাম: ...',
            link: '/jobs/10119140/',
            page: 'jobs',
            author: 'বাহান্ন নিউজ ডেস্ক',
            published: '1714104540',
            demo: true
        }
    ]
};
