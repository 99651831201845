import React from 'react';
import { Link } from 'react-router-dom';
import SectionList from '../../system/SectionList';
import LeadSmall from './LeadSmall';

const eduMainPl = [
    {
        sl: 1,
        image: null,
        title: '-----------------------------',
        empty: true
    },
    {
        sl: 2,
        image: null,
        title: '---------------------',
        pre_title: '---------------------------',
        empty: true
    },
    {
        sl: 3,
        image: null,
        title: '---------------------',
        empty: true
    },
    {
        sl: 4,
        image: null,
        title: '---------------------',
        pre_title: '---------------------------',
        empty: true
    },
    {
        sl: 5,
        image: null,
        title: '---------------------',
        pre_title: '---------------------------',
        empty: true
    }
];

function EducationMain({ head, articles, moreLink, limit = 6 }) {
    return (
        <div className="education-main flex-col align-stretch">
            <div className="education-head">{head}</div>
            <LeadSmall article={articles ? articles[0] : null} noBorder placed="grid-head" />
            <hr />
            <div className="fill">
                <SectionList articles={articles ? articles.slice(1, limit) : eduMainPl} />
            </div>
            <div className="education-footer">
                <Link to={moreLink}>আরও...</Link>
            </div>
        </div>
    );
}

export default EducationMain;
