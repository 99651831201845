import { Outlet } from 'react-router-dom';

function MenuComments() {
    return (
        <div className="_container">
            <div className="flex-row align-center">
                <div>This is Comments page.</div>
                <Outlet />
            </div>
        </div>
    );
}

export default MenuComments;
