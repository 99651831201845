/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useSearch } from '../../../contexts/SearchProvider';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import SearchOptionDate from './SearchOptionDate';
import SearchOptionOrganize from './SearchOptionOrganize';
import SearchOptionSection from './SearchOptionSection';
import SearchOptionType from './SearchOptionType';
import SearchOptionWriter from './SearchOptionWriter';

function SearchFilterPanel() {
    const {
        result: { count, articles },
        filteredData,
        clearFilter,
        setVisible
    } = useSearch();
    const [showOption, setShowOption] = useState(null);
    const [open, setOpen] = useState(false);

    const handelShowClick = (id) => {
        if (id === showOption) {
            setShowOption(null);
        } else {
            setShowOption(id);
        }
    };

    const handelOpenClick = () => {
        setOpen((cuVal) => !cuVal);
    };

    useEffect(() => {
        setVisible(true);
    }, [setVisible]);

    return (
        <div className="search-page-sidebar">
            <div className={`search-page-sidebar-title animate ${open ? 'open' : ''}`}>
                <div className="flex-row align-space-between position-middle">
                    <span className="search-result-summery fill">
                        প্রাপ্ত ফলাফল: {ConvertStringBn(count || 0)}
                        {articles?.length ? `(১ -${ConvertStringBn(articles.length)})` : null}
                    </span>
                    {filteredData?.count ? (
                        <span onClick={clearFilter} className="search-filter-count">
                            মুছে ফেলুন {ConvertStringBn(filteredData?.count)}
                        </span>
                    ) : null}
                    <span className="btn-search-filter">
                        <button type="button" onClick={handelOpenClick}>
                            বাছাই করুন
                        </button>
                    </span>
                </div>

                <SearchOptionWriter
                    id="writer"
                    handelShowClick={handelShowClick}
                    showOption={showOption}
                />

                <SearchOptionSection
                    id="section"
                    handelShowClick={handelShowClick}
                    showOption={showOption}
                />

                <SearchOptionType
                    id="type"
                    handelShowClick={handelShowClick}
                    showOption={showOption}
                />

                <SearchOptionOrganize
                    id="organize"
                    handelShowClick={handelShowClick}
                    showOption={showOption}
                />

                <SearchOptionDate
                    id="date"
                    handelShowClick={handelShowClick}
                    showOption={showOption}
                />
            </div>
        </div>
    );
}

export default SearchFilterPanel;
