import React from 'react';
import { XlHome01, XlHome08 } from '../../websites/ads/AdManagerXl';
import T20Banner from '../../websites/ads/T20Banner';
import { EducationHead } from '../../websites/comps/SectionHead';
import EconomicsMain from '../comps/EconomicsMain';
import EducationMain from '../comps/EducationMain';
import EntertainmentMain from '../comps/EntertainmentMain';
import GalleryMain from '../comps/GalleryMain';
import NationalMain from '../comps/NationalMain';
import LeadContainer from '../leads/LeadContainer';
import SportsContainer from '../sports/SportsContainer';

function HomeLayout({ articles }) {
    return (
        <>
            <div className="_ads">
                {/* <div className="_container">
                    <AdsContainer0 />
                </div> */}
                <div className="_container not-print-class">
                    <div>
                        <T20Banner />
                        {/* <Ads10 show /> */}
                        <XlHome01 />
                    </div>
                </div>
            </div>

            <div className="_lead">
                <div className="_container">
                    <LeadContainer
                        lead={articles ? articles['selected/lead'] : null}
                        latest={articles ? articles.recent : null}
                        opinion={articles ? articles['category/opinion'] : null}
                        education={articles ? articles['category/education'] : null}
                    />
                </div>
            </div>

            <div className="_ads">
                <div className="_container">
                    <div style={{ paddingLeft: 286, paddingRight: 286 }}>
                        <XlHome08 />
                    </div>
                </div>
            </div>

            <div className="_national">
                <div className="_container flex-row align-space-between position-stretch">
                    <div>
                        <NationalMain
                            moreLink="/national/"
                            articles={articles ? articles['category/national'] : null}
                            title="জাতীয়"
                            className="red-gradient"
                        />
                    </div>
                    <div>
                        <EducationMain
                            moreLink="/politics/"
                            articles={articles ? articles['category/politics'] : null}
                            head={<EducationHead title="রাজনীতি" className="red-gradient" />}
                        />
                    </div>
                </div>
            </div>

            <div className="_ads">
                <div className="_container">
                    <div style={{ paddingLeft: 286, paddingRight: 286 }}>
                        <XlHome08 />
                    </div>
                </div>
            </div>

            <div className="_sport">
                <div className="_container">
                    <SportsContainer
                        moreLink="/sports/"
                        articles={articles ? articles['category/sports'] : null}
                    />
                </div>
            </div>

            <div className="_ads">
                <div className="_container">
                    <div style={{ paddingLeft: 286, paddingRight: 286 }}>
                        <XlHome08 />
                    </div>
                </div>
            </div>

            <div className="_national">
                <div className="_container flex-row align-space-between position-stretch">
                    <div>
                        <NationalMain
                            moreLink="/bangladesh/"
                            articles={articles ? articles['category/bangladesh'] : null}
                            title="সারাদেশ"
                            className="green-gradient"
                        />
                    </div>
                    <div>
                        <EducationMain
                            moreLink="/international/"
                            articles={articles ? articles['category/international'] : null}
                            head={<EducationHead title="আন্তর্জাতিক" className="green-gradient" />}
                        />
                    </div>
                </div>
            </div>

            <div className="_ads">
                <div className="_container">
                    <div style={{ paddingLeft: 286, paddingRight: 286 }}>
                        <XlHome08 />
                    </div>
                </div>
            </div>

            <div className="_entertainment">
                <div className="_container">
                    <EntertainmentMain
                        moreLink="/entertainment/"
                        articles={articles ? articles['category/entertainment'] : null}
                    />
                </div>
            </div>

            <div className="_economics">
                <div className="_container flex-row align-space-between">
                    <div>
                        <EconomicsMain
                            moreLink="/economics/"
                            articles={articles ? articles['category/economics'] : null}
                            title="অর্থনীতি"
                        />
                    </div>
                    <div>
                        <EconomicsMain
                            moreLink="/crime/"
                            articles={articles ? articles['category/crime'] : null}
                            title="আইন-আদালত"
                        />
                    </div>
                    <div>
                        <EconomicsMain
                            moreLink="/health/"
                            articles={articles ? articles['category/health'] : null}
                            title="স্বাস্থ্যসেবা"
                        />
                    </div>
                </div>
            </div>

            <div className="_ads">
                <div className="_container">
                    <div style={{ paddingLeft: 286, paddingRight: 286 }}>
                        <XlHome08 />
                    </div>
                </div>
            </div>

            <div className="_science">
                <div className="_science_row _container flex-row align-space-between position-stretch">
                    <div>
                        <EducationMain
                            moreLink="/technology/"
                            articles={articles ? articles['category/technology'] : null}
                            limit={5}
                            head={
                                <EducationHead
                                    title="বিজ্ঞান ও প্রযুক্তি"
                                    className="red-gradient"
                                />
                            }
                        />
                    </div>
                    <div>
                        <EducationMain
                            moreLink="/lifestyle/"
                            articles={articles ? articles['category/lifestyle'] : null}
                            limit={5}
                            head={<EducationHead title="লাইফস্টাইল" className="red-gradient" />}
                        />
                    </div>
                    <div>
                        <EducationMain
                            moreLink="/expatriation/"
                            articles={articles ? articles['category/expatriation'] : null}
                            limit={5}
                            head={<EducationHead title="প্রবাস" className="red-gradient" />}
                        />
                    </div>
                    <div>
                        <EducationMain
                            moreLink="/jobs/"
                            articles={articles ? articles['category/jobs'] : null}
                            limit={5}
                            head={<EducationHead title="চাকরির খবর" className="red-gradient" />}
                        />
                    </div>
                </div>
            </div>

            <div className="_ads">
                <div className="_container">
                    <div style={{ paddingLeft: 286, paddingRight: 286 }}>
                        <XlHome08 />
                    </div>
                </div>
            </div>

            <div className="_gallery">
                <div className="_container flex-row align-space-between position-stretch">
                    <div className="_gallery_left">
                        <GalleryMain articles={articles ? articles.gallery : null} />
                    </div>
                    <div className="_gallery_right">
                        <EducationMain
                            moreLink="/literature/"
                            articles={articles ? articles['category/literature'] : null}
                            limit={5}
                            head={<EducationHead title="শিল্প ও সাহিত্য" titleBg="red-gradient" />}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeLayout;
