import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import Img from '../../../utils/Img';
import iopt from '../../../utils/iopt';
import { OpinionHead } from '../../websites/comps/SectionHead';

const emtOpinion = [
    {
        sl: 1,
        image: null,
        pre_title: '---------------------',
        title: '---------------------',
        summery: '--------------------------------------------------------------------',
        editor: null,
        author: '------------------------------',
        empty: true
    },
    {
        sl: 2,
        image: null,
        pre_title: '---------------------',
        title: '---------------------',
        summery: '--------------------------------------------------------------------',
        editor: null,
        author: '------------------------------',
        empty: true
    }
];

function OpinionLarge({ item }) {
    const ref = useRef();

    if (item?.empty) {
        return (
            <Link to={item?.link} className="opinion-main-item placeholder-mode">
                <div className="flex-row align-space-between position-middle article">
                    <div ref={ref}>
                        <Img src={null} pRef={ref} />
                    </div>
                    <div>
                        <h3>
                            <span>---------------------------------------</span>
                        </h3>
                        <p>
                            <span>-------------------------------------------</span>
                            <span>---------------------------------------</span>
                        </p>
                    </div>
                </div>

                <div className="flex-row position-middle w-100p editor">
                    <img src={icons.editor} alt="" />
                    <span>----------------------------------------------------</span>
                </div>
            </Link>
        );
    }
    return (
        <Link to={item.link} className="opinion-main-item">
            <div className="flex-row align-space-between position-middle article">
                <div ref={ref}>
                    <Img src={iopt(item?.image)} pRef={ref} />
                </div>
                <div>
                    <h3>
                        <span>
                            {item?.pre_title ? (
                                <span className="pre">{item?.pre_title} / </span>
                            ) : null}
                        </span>
                        <span>{item?.title}</span>
                    </h3>
                    <p>
                        {' '}
                        <span>{item.summery}</span>{' '}
                    </p>
                </div>
            </div>

            <div className="flex-row position-middle w-100p editor">
                <img src={icons.editor} alt="" />
                <span>{item.author}</span>
            </div>
        </Link>
    );
}

function OpinionMain({ opinion }) {
    const arr = opinion || emtOpinion;

    return (
        <div className={`opinion-main h-100p flex-col position-space-between align-stretch `}>
            <div className="opinion-head">
                <OpinionHead />
            </div>
            {arr.slice(0, 2).map((item) => (
                <OpinionLarge item={item} key={item?.sl} />
            ))}
            <div className={`opinion-footer ${opinion === null ? 'placeholder-mode' : ''}`}>
                <Link to="/opinion/">
                    <span>আরও...</span>
                </Link>
            </div>
        </div>
    );
}

export default OpinionMain;
