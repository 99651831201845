import AxiosAuth from '../../utils/AxiosAuth';

export const ApiUpdateProfileInfo = (formData) =>
    new Promise((resolve, reject) => {
        AxiosAuth.setUrl(`/activity/user/update/`)
            .post(formData)
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(data.message);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export default null;
