import React, { useEffect, useRef, useState } from 'react';
import {
    EmailShareButton,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { shares } from '../../../../configs/Icons';
import { useInit } from '../../../contexts/InitProvider';

function CopyButton({ shareUrl }) {
    const [copyText, setCopyText] = useState('লিঙ্ক কপি করুন');

    return (
        <>
            <button
                type="button"
                style={{ background: 'transparent', border: '0', padding: '0', cursor: 'pointer' }}
                className="flex-row position-middle"
                onClick={(ev) => {
                    ev.preventDefault();
                    setCopyText('লিঙ্ক কপি হয়েছে');
                    navigator.clipboard.writeText(shareUrl);
                }}
                onMouseMove={() => {
                    setCopyText('লিঙ্ক কপি করুন');
                }}
                id="copy-button"
            >
                <img src={shares.shareLink} alt="" />
                <span>লিংক কপি করুন</span>
            </button>
            <Tooltip anchorId="copy-button" place="top" content={copyText} />
        </>
    );
}

function MoreShareIcons({ showMore, setShowMore, position, shareUrl, imageUrl }) {
    const ref = useRef();
    const { configs } = useInit();

    const handleLinkClick = () => {
        setShowMore(false);
    };

    useEffect(() => {
        const handleClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                setShowMore(false);
            }
        };
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setShowMore]);

    if (!shareUrl) {
        return null;
    }

    return (
        <div
            className={`share-more-area ${position || ''}`}
            style={{ display: showMore ? 'block' : 'none' }}
            ref={ref}
        >
            <ul className="flex-row flex-wrap">
                <li>
                    <FacebookShareButton
                        className="flex-row position-middle"
                        onClick={handleLinkClick}
                        url={shareUrl}
                    >
                        {/* <a href="/" className="flex-row position-middle"> */}
                        <img src={shares.shareFacebookHover} alt="" />
                        <span>ফেসবুকে শেয়ার করুন</span>
                        {/* </a> */}
                    </FacebookShareButton>
                </li>
                {configs ? (
                    <li>
                        <FacebookMessengerShareButton
                            appId={configs.fbAppId}
                            className="flex-row position-middle"
                            onClick={handleLinkClick}
                            url={shareUrl}
                        >
                            <img src={shares.shareMessenger} alt="" />
                            <span> ম্যাসেঞ্জারে শেয়ার করুন</span>
                        </FacebookMessengerShareButton>
                    </li>
                ) : null}
                {imageUrl ? (
                    <li>
                        <PinterestShareButton
                            media={imageUrl}
                            className="flex-row position-middle"
                            onClick={handleLinkClick}
                            url={shareUrl}
                        >
                            <img src={shares.sharePinterest} alt="" />
                            <span>পিন্টারিস্টে শেয়ার করুন</span>
                        </PinterestShareButton>
                    </li>
                ) : null}
                <li>
                    <WhatsappShareButton
                        className="flex-row position-middle"
                        onClick={handleLinkClick}
                        url={shareUrl}
                    >
                        <img src={shares.shareWhatsapp} alt="" />
                        <span> ওয়াটসঅ্যাপে শেয়ার করুন</span>
                    </WhatsappShareButton>
                </li>
                <li>
                    <TwitterShareButton
                        className="flex-row position-middle"
                        onClick={handleLinkClick}
                        url={shareUrl}
                    >
                        <img src={shares.shareTwitterHover} alt="" />
                        <span>টুইটারে শেয়ার করুন</span>
                    </TwitterShareButton>
                </li>
                <li>
                    <LinkedinShareButton
                        className="flex-row position-middle"
                        onClick={handleLinkClick}
                        url={shareUrl}
                    >
                        <img src={shares.shareLinkedin} alt="" />
                        <span> লিংকডইনে শেয়ার করুন</span>
                    </LinkedinShareButton>
                </li>
                <li>
                    <EmailShareButton
                        openShareDialogOnClick
                        className="flex-row position-middle"
                        onClick={handleLinkClick}
                        url={shareUrl}
                    >
                        <img src={shares.shareEmailHover} alt="" />
                        <span>ইমেইল করুন </span>
                    </EmailShareButton>
                </li>
                <li>
                    <CopyButton shareUrl={shareUrl} />
                </li>
            </ul>
        </div>
    );
}

export default MoreShareIcons;
