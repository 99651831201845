import React, { useState } from 'react';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { icons, logos, shares } from '../../../../configs/Icons';
import { GetDateInBangla, GetTimeInBangla } from '../../../utils/timeFormat';
import MoreShareIcons from './MoreShareIcons';

function ShareIcons({ shareUrl, imageUrl }) {
    const [showMore, setShowMore] = useState(false);

    const handelShow = (ev) => {
        ev.preventDefault();
        setShowMore(true);
    };

    return (
        <ul className="share-area flex-row align-space-between position-middle">
            <li>
                <FacebookShareButton className="flex-row position-middle" url={shareUrl}>
                    <img src={shares.shareFacebook} alt="" />
                    <img src={shares.shareFacebookHover} alt="" />
                </FacebookShareButton>
            </li>
            <li>
                <TwitterShareButton className="flex-row position-middle" url={shareUrl}>
                    <img src={shares.shareTwitter} alt="" />
                    <img src={shares.shareTwitterHover} alt="" />
                </TwitterShareButton>
            </li>
            <li>
                <EmailShareButton
                    openShareDialogOnClick
                    className="flex-row position-middle"
                    url={shareUrl}
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                >
                    <img src={shares.shareEmail} alt="" />
                    <img src={shares.shareEmailHover} alt="" />
                </EmailShareButton>
            </li>
            <li>
                <a href="/" onClick={handelShow}>
                    <img src={shares.shareMain} alt="" />
                    <img src={shares.shareMainHover} alt="" />
                </a>
                <MoreShareIcons
                    showMore={showMore}
                    setShowMore={setShowMore}
                    shareUrl={shareUrl}
                    imageUrl={imageUrl}
                />
            </li>
            <li>
                <a
                    href="/"
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                >
                    <img src={shares.shareSave} alt="" />
                    <img src={shares.shareSaveHover} alt="" />
                </a>
            </li>
            <li>
                <a
                    href="/"
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                >
                    <img src={shares.sharePrint} alt="" />
                    <img src={shares.sharePrintHover} alt="" />
                </a>
            </li>
        </ul>
    );
}

function DetSectionSocial({ hideAuthor, author, authorImage, timePublished, shareUrl, imageUrl }) {
    if (hideAuthor) {
        return (
            <div className="det-info flex-row position-middle">
                <div> </div>
                <div className="fill"> </div>
                <div>
                    <ShareIcons shareUrl={shareUrl} imageUrl={imageUrl} />
                </div>
            </div>
        );
    }

    return (
        <div className="det-info flex-row position-middle">
            <div>
                <span>
                    <img src={logos.bahannoLogoSm} alt="" className="com-logo" />
                </span>
            </div>
            <div className="fill">
                <div className="flex-row position-middle">
                    <span className="gap-r-1 gap-b-1">
                        <img src={authorImage || icons.editor2} alt="" className="ico-f-editor" />
                    </span>
                    <span className="reporter gap-b-1">{author || 'স্টাফ রিপোর্টার'}</span>
                </div>
                <hr />
                <div className="report-time">
                    <span>
                        প্রকাশ : {GetDateInBangla(timePublished)}, {GetTimeInBangla(timePublished)}
                    </span>
                </div>
            </div>
            <div>
                <ShareIcons shareUrl={shareUrl} imageUrl={imageUrl} />
            </div>
        </div>
    );
}

export default DetSectionSocial;
