import { useState } from 'react';

function FormHandler(initState) {
    const [formData, setFormData] = useState(initState || {});

    const handle = (ev) => {
        const { name, value } = ev.target;
        setFormData((cuData) => ({ ...cuData, [name]: value }));
    };

    return { formData, setFormData, handle };
}

export default FormHandler;
