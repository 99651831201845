import React from 'react';
import Calendar from '../../utils/calender/Calendar';
import LatestMain from '../home/comps/LatestMain';
import ThreeCat from '../home/comps/ThreeCat';
import { Ads05, Ads20 } from '../websites/ads/Ads';

function Sidebar({ articles }) {
    return (
        <>
            <div>
                {/* <AdsContainer1 /> */}
                <Ads05 show />
                {/* <Ads05 show />
                <Ads20 show name="gpt-passback6" /> */}
            </div>
            <div>
                <ThreeCat articles={articles} />
            </div>
            <div>
                <Ads20 show />
            </div>
            <div>
                <LatestMain latest={articles ? articles.recent : null} />
            </div>
            <div>
                <Calendar />
            </div>
        </>
    );
}

export default Sidebar;
