import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    TwitterAuthProvider
} from 'firebase/auth';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { firebaseAuth } from '../../configs/ConfigFirebase';
import ForgetPasswordModal from '../app/auth/modals/ForgetPasswordModal';
import UserLoginModal from '../app/auth/modals/UserLoginModal';
import UserRegistrationModal from '../app/auth/modals/UserRegistrationModal';
import AxiosAuth from '../utils/AxiosAuth';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const initialState = {
    loginStatus: false,
    email: '',
    phoneNumber: '',
    displayName: '',
    photoUrl: '',
    lastLogin: 0,
    provider: '',
    refreshToken: '',
    userUid: '',
    profileId: '',
    firebase: null
};

export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [modalType, setModalType] = useState(null); // sign-in, sign-up, forget-password
    // const [otpConfig, setOtpConfig] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            const providerData = user?.providerData || [];

            if (providerData.length > 0) {
                AxiosAuth.setApiUrl('AUTH', '/firebase-login/')
                    .post({
                        uid: user.uid,
                        refreshToken: user.refreshToken
                    })
                    .then(({ data }) => {
                        if (data.otpRequired) {
                            // --OTP Required
                            // setOtpConfig(true);
                        } else if (data.error) {
                            // --Login Error
                            // setCurrentUser(emptyUser);
                        } else {
                            // --Login Success
                            const cUser = {
                                loginStatus: true,
                                email: data.user.default_email,
                                phoneNumber: data.user.default_contact,
                                displayName: providerData[0].displayName,
                                photoUrl: data.user.photo_url,
                                lastLogin: 0,
                                provider: providerData[0].providerId,
                                refreshToken: user.refreshToken,
                                userUid: user.uid,
                                profileId: data.user.profileId,
                                firebase: null
                            };

                            setCurrentUser(cUser);
                            setModalType(null);
                        }
                    });
            } else {
                setCurrentUser(initialState);
            }

            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const isSignIn = currentUser && currentUser?.userFound;

    const value = useMemo(() => {
        const signUp = (email, password) =>
            createUserWithEmailAndPassword(firebaseAuth, email, password);

        const signIn = (email, password) =>
            signInWithEmailAndPassword(firebaseAuth, email, password);

        function logout() {
            return signOut(firebaseAuth);
        }

        function resetPassword(email) {
            return sendPasswordResetEmail(firebaseAuth, email);
        }

        function updateEmail(email) {
            return currentUser.updateEmail(email);
        }

        function updatePassword(password) {
            return currentUser.updatePassword(password);
        }

        const signInWithGoogle = () => {
            const provider = new GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('profile');
            provider.addScope('email');
            provider.addScope('openid');

            return signInWithPopup(firebaseAuth, provider);
        };

        const signInWithFacebook = () => {
            const provider = new FacebookAuthProvider();
            provider.addScope('public_profile');
            provider.addScope('email');
            return signInWithPopup(firebaseAuth, provider);
        };

        const signInWithGithub = () => {
            const provider = new GithubAuthProvider();
            return signInWithPopup(firebaseAuth, provider);
        };

        const signInWithTwitter = () => {
            const provider = new TwitterAuthProvider();
            return signInWithPopup(firebaseAuth, provider);
        };

        const openLoginModal = () => {
            if (!currentUser?.userFound) {
                setModalType('sign-in');
            }
        };

        const openRegistrationModal = () => {
            if (!currentUser?.userFound) {
                setModalType('sign-up');
            }
        };
        const openForgetPasswordModal = () => {
            if (!currentUser?.userFound) {
                setModalType('forget-password');
            }
        };
        const closeModal = () => {
            setModalType(null);
        };
        return {
            currentUser,
            signUp,
            signIn,
            signOut: logout,
            resetPassword,
            updateEmail,
            updatePassword,
            openLoginModal,
            openRegistrationModal,
            openForgetPasswordModal,
            closeModal,
            isSignIn,
            signInWithGoogle,
            signInWithFacebook,
            signInWithGithub,
            signInWithTwitter
        };
    }, [currentUser, isSignIn]);

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}

            <UserLoginModal
                currentUser={currentUser}
                show={modalType === 'sign-in'}
                closeModal={value.closeModal}
                signIn={value.signIn}
                signInWithGoogle={value.signInWithGoogle}
                signInWithFacebook={value.signInWithFacebook}
                signInWithTwitter={value.signInWithTwitter}
                openRegistrationModal={value.openRegistrationModal}
                openForgetPasswordModal={value.openForgetPasswordModal}
            />

            <ForgetPasswordModal
                currentUser={currentUser}
                show={modalType === 'forget-password'}
                closeModal={value.closeModal}
                returnToLogin={value.openLoginModal}
            />

            <UserRegistrationModal
                currentUser={currentUser}
                show={modalType === 'sign-up'}
                closeModal={value.closeModal}
                signIn={value.signIn}
                signUp={value.signUp}
                updateProfile={value.updateProfile}
                signInWithGoogle={value.signInWithGoogle}
                signInWithFacebook={value.signInWithFacebook}
                signInWithGithub={value.signInWithGithub}
                signInWithTwitter={value.signInWithTwitter}
                openLoginModal={value.openLoginModal}
            />
        </AuthContext.Provider>
    );
}
