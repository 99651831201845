import React from 'react';
import MenuContSm from './MenuContSm';

function MenuDeviceSm() {
    return (
        <div className="_menu">
            <div className="_container h-100p">
                <MenuContSm />
            </div>
        </div>
    );
}

export default MenuDeviceSm;
