import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { logos } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import CommentProvider from '../../contexts/CommentProvider';
import LayoutProvider from '../../contexts/LayoutProvider';
import MultiPageProvider, { useMultiPage } from '../../contexts/MultiPageProvider';
import SidebarPopup from '../../utils/features/sidebar-popup/SidebarPopup';
import Sidebar from '../system/Sidebar';
import SidebarSm from '../system/SidebarSm';
import { XlHome01 } from '../websites/ads/AdManagerXl';
import T20Banner from '../websites/ads/T20Banner';
import DetailsMain from './comps/DetailsMain';

function MainContent() {
    const { currentUser, openLoginModal } = useAuth();
    const { articles, category } = useMultiPage();

    return articles
        .filter((item) => item.data !== null)
        .map((article) => (
            <CommentProvider
                id={article.id || 0}
                currentUser={currentUser}
                openLoginModal={openLoginModal}
                key={article.id}
            >
                <DetailsMain article={article.data} related={article.related} category={category} />
                <div className="flex-row align-space-between position-middle details-separator">
                    <hr />
                    <img src={logos.bahannoLogoSm} alt="Bahanno" />
                    <hr />
                </div>
            </CommentProvider>
        ));
}

function SidebarCont() {
    const { sidebarData } = useMultiPage();

    return (
        <LayoutProvider>
            <Sidebar articles={sidebarData} />
            <SidebarSm articles={sidebarData} layout="md" />
        </LayoutProvider>
    );
}

function DetailsPage() {
    const { id } = useParams();

    // --Scroll to Top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [id]);

    return (
        <MultiPageProvider id={id}>
            <div className="_ads">
                <div className="_container">
                    <div className="_container not-print-class">
                        <div>
                            <T20Banner />
                            {/* <Ads10 show /> */}
                            <XlHome01 />
                        </div>
                    </div>
                </div>
            </div>

            <div className="_details">
                <div className="_container flex-row align-space-between">
                    <div className="_page_left flex-row flex-wrap align-space-between">
                        <MainContent />
                    </div>

                    <div className="_page_right">
                        <SidebarCont />
                    </div>
                </div>
            </div>

            <SidebarPopup />
        </MultiPageProvider>
    );
}

export default DetailsPage;
