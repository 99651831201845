import React from 'react';

function AdsContainer0() {
    return (
        <img
            src="https://www.bahannonews.com/static/media/onnorokom_900.9982a144.gif"
            alt=""
            style={{ width: '100%', height: '100%' }}
        />
    );
}

export default AdsContainer0;
