/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { buttons } from '../../../../configs/Icons';
import ConvertStringBn from '../../../utils/ConvertStringBn';

function ArchiveDateNav({ totalCount, date }) {
    const navigate = useNavigate();
    const thisDay = dayjs(date);

    const handelPevClick = () => {
        const prevDay = thisDay.subtract(1, 'day').format('YYYY-MM-DD');
        navigate(`/archive/${prevDay}/`);
    };
    const handelNextClick = () => {
        const nextDay = thisDay.add(1, 'day').format('YYYY-MM-DD');
        navigate(`/archive/${nextDay}/`);
    };

    return (
        <div className="flex-row align-space-between position-middle">
            <div>
                <img src={buttons.btnPrevDay} alt="Prev Day" onClick={handelPevClick} />
            </div>
            <div>
                <b>{ConvertStringBn(date)}</b> তারিখ এর আর্কাইভে {ConvertStringBn(totalCount)} টি
                খবর পাওয়া গেছে
            </div>
            <div>
                <img src={buttons.btnNextDay} alt="Next Day" onClick={handelNextClick} />
            </div>
        </div>
    );
}

export default ArchiveDateNav;
