import React from 'react';
import { logos } from '../../../configs/Icons';
import { useComment } from '../../contexts/CommentProvider';
import { useTemplate } from '../../contexts/TemplateProvider';
import ConvertStringBn from '../ConvertStringBn';
import { GetDate } from '../Show';
import iopt from '../iopt';
import CommentOptions from './CommentOptions';

function CommentReplies({ comment }) {
    const { removeComment, updateLike } = useComment();
    const { openConfirm, closeConfirm, setMessage } = useTemplate();
    const img = comment?.profileImage ? iopt(comment?.profileImage) : logos.bahannoLogoSm;

    const handelYesClick = () => {
        setMessage('কমেন্টটি মুছে ফেলা হচ্ছে...');
        removeComment(comment.commentId)
            .then(({ error, message }) => {
                if (error === 0) {
                    closeConfirm();
                    setMessage('কমেন্টটি মুছে ফেলা হয়েছে।');
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    const handelDeleteClick = () => {
        //
        openConfirm(null, 'আপনি কি কমেন্টটি মুছতে চান?', [
            <button type="button" className="confirm-button gray" id="no" onClick={closeConfirm}>
                না
            </button>,
            <button type="button" className="confirm-button red" id="yes" onClick={handelYesClick}>
                হ্যাঁ
            </button>
        ]);
    };

    const handelUpdateLike = (ev) => {
        const likeMap = { like: 1, dislike: -1 };
        const likeMode = likeMap[ev.target.id] || 0;

        setMessage('আপডেট হচ্ছে...');
        updateLike(comment.commentId, likeMode)
            .then(({ error, message }) => {
                if (error === 0) {
                    closeConfirm();
                    setMessage('আপডেট হয়েছে।');
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="preview-section flex-row position-stretch">
            <img src={img} alt="" className="auth-image sm" />
            <div className="fill">
                <div className="flex-row position-middle">
                    <span className="auth-name">{comment.profileName}</span>
                    <span>&nbsp; &nbsp;</span>
                    <span className="auth-date-time">{ConvertStringBn(GetDate(comment.time))}</span>
                </div>
                <div className="auth-text">{comment.comment}</div>

                <CommentOptions
                    comment={comment}
                    handelUpdateLike={handelUpdateLike}
                    handelDeleteClick={handelDeleteClick}
                />
            </div>
        </div>
    );
}

export default CommentReplies;
