import React from 'react';
import { logos } from '../../configs/Icons';

function EmptyPlaceholder() {
    return (
        <div className="empty-placeholder">
            <img className="w-100" src={logos.bahannoLogoLg} alt="bahanno logo" />
        </div>
    );
}

export default EmptyPlaceholder;
