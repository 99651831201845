import { onValue, ref } from 'firebase/database';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { rtDb } from '../../configs/ConfigFirebase';

const storeName = 'just-found-closed';

const JustFoundContext = createContext();

export function useJustFound() {
    return useContext(JustFoundContext);
}

function JustFoundProvider({ children }) {
    const [article, setArticle] = useState(null);
    const sl = article?.sl || 0;
    const published = article?.published || 0;

    useEffect(() => {
        const dbRef = ref(rtDb, `/last-update/article/`);
        onValue(dbRef, (snapshot) => {
            const art = snapshot.val();

            const storeSl = parseInt(localStorage.getItem(storeName), 10);
            if (storeSl !== art.sl) {
                setArticle(art);
            }
        });
    }, []);

    const timeNow = Math.round(Date.now() / 1000);
    const show = article && timeNow - published < 600;

    const value = useMemo(() => {
        const closeWindow = () => {
            setArticle(null);
            localStorage.setItem(storeName, sl);
        };
        return {
            show,
            article,
            setArticle,
            closeWindow
        };
    }, [article, show, sl]);

    return <JustFoundContext.Provider value={value}>{children}</JustFoundContext.Provider>;
}

export default JustFoundProvider;
