import React, { useEffect, useState } from 'react';
import { logos } from '../../../configs/Icons';
import { RegForNotification } from '../../vendors/bikiranpush/BikiranPush';

const pushNotificationName = 'notification-status'; // 0, 1, -1

// Request URL: https://push.bikiran.com/api/save-fcm/10000001/f304b59384fdbbd645f0f6da5b24c043/?token=dXHjZhsSOQmfq2WP6_jwNi:APA91bFx9bG2KKovpO1AMhV3p4jcmFhmaeG_R7oAS-mD5wmIlqdHC9Dxb2LxfEclJw4Zr-DQNfeDLSrisEhzzS_aH6cdHjsvcS5w_zVXQ4jizGD9ahrK65Kpl1cTbkZqqmevrk5XKw8G

function PushNotification({ timeDelay = 0 }) {
    const [show, setShow] = useState(false);

    const handelAccept = () => {
        localStorage.setItem(pushNotificationName, '1');
        setShow(false);

        RegForNotification();
        // .then((data) => {
        //
        // })
        // .catch((err) => {
        //     console.log(err);
        // });
    };

    const handelDeny = () => {
        localStorage.setItem(pushNotificationName, '-1');
        setShow(false);
    };

    useEffect(() => {
        const timeOut = setTimeout(() => {
            const showSt = localStorage.getItem(pushNotificationName) === null;
            setShow(showSt);
        }, timeDelay);

        return () => {
            clearTimeout(timeOut);
        };
    }, [timeDelay]);

    if (!show) {
        return null;
    }

    return (
        <div className="push-notification-accept">
            <img src={logos.bahannoLogoLg} alt="Bahanno Logo" />
            <div className="notification-text">বাহান্ন নিউজ থেকে নিয়মিত এলার্ট পেতে চান?</div>
            <div className="notification-buttons">
                <button type="button" onClick={handelDeny}>
                    না
                </button>
                <button type="button" onClick={handelAccept}>
                    হা
                </button>
            </div>
        </div>
    );
}

export default PushNotification;
