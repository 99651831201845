export function LatestHead({ title = 'সর্বশেষ' }) {
    return (
        <div
            onClick={null}
            role="button"
            tabIndex={-1}
            className="btn-head green-gradient-dark flex-row align-center position-middle"
        >
            {title}
        </div>
    );
}

export function OpinionHead() {
    return (
        <div
            onClick={null}
            role="button"
            tabIndex={-1}
            className="btn-head red-gradient flex-row position-middle"
        >
            মতামত
        </div>
    );
}

export function EducationHead({ title, className, myStyles }) {
    return (
        <div
            onClick={null}
            role="button"
            tabIndex={-1}
            className={`btn-head flex-row position-middle ${className || 'green-gradient'}`}
            style={myStyles}
        >
            <span>{title || ''}</span>
        </div>
    );
}

export function ThreeCatHead({ title, className, myStyles }) {
    return (
        <div
            onClick={null}
            role="button"
            tabIndex={-1}
            className={`btn-head flex-row align-center position-middle ${
                className || 'green-gradient-dark'
            }`}
            style={myStyles}
        >
            {title}
        </div>
    );
}

function SectionHead() {
    return null;
}

export default SectionHead;
