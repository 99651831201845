import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { useSearch } from '../../../contexts/SearchProvider';

function SearchSectionHead() {
    const ref = useRef();
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [searchParams] = useSearchParams();
    const { setQ: setSearchQ } = useSearch();

    const handelFormSubmit = (ev) => {
        ev.preventDefault();
        navigate(`/search/?q=${ref.current.value}`);
    };

    // --Reactive when changed 'q'
    useEffect(() => {
        const urlQ = searchParams.get('q') || '';
        setQ(urlQ);
        setSearchQ(urlQ);
    }, [searchParams, setQ, setSearchQ]);

    return (
        <div className="flex-row align-space-between position-middle">
            <div className="search-page-title">
                <h4>অনুসন্ধান</h4>
                <span />
            </div>
            <div className="search-page-input">
                <form onSubmit={handelFormSubmit}>
                    <div className="form-group">
                        <input
                            ref={ref}
                            placeholder="অনুসন্ধান করুন..."
                            value={q}
                            onChange={(ev) => {
                                setQ(ev.target.value);
                            }}
                        />

                        <button type="button" className="header-search-send">
                            <img src={icons.searchBtn} alt="Send Icon" />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SearchSectionHead;
