/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { icons, logos } from '../../../../configs/Icons';
import { useInit } from '../../../contexts/InitProvider';
import { useMenu } from '../../../contexts/MenuProvider';
import HeaderRightMenu from './HeaderRightMenu';
import ShowTime from './ShowTime';

function SearchForm({ show, setShow }) {
    const ref = useRef();
    const ref2 = useRef();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [q, setQ] = useState('');

    const handelOnSubmit = (ev) => {
        ev.preventDefault();

        setShow(false);
        navigate(`/search/?q=${q}`);
    };

    const handelQChange = (ev) => {
        setQ(ev.target.value || '');
    };

    // --Close on Outside Click
    useEffect(() => {
        const handleClick = (event) => {
            if (!ref.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setShow]);

    // --Focus on Open
    useEffect(() => {
        ref2.current.focus();
    }, [show]);

    // --Reactive when changed 'q'
    useEffect(() => {
        const urlQ = searchParams.get('q') || '';
        setQ(urlQ);
    }, [searchParams]);

    return (
        <form
            className={`search-form-floating animate ${show ? 'show' : ''}`}
            ref={ref}
            onSubmit={handelOnSubmit}
        >
            <div className="form-group h-100p">
                <div style={{ overflow: 'hidden' }} className="fill">
                    <input
                        type="text"
                        name=""
                        value={q}
                        ref={ref2}
                        onChange={handelQChange}
                        placeholder="অনুসন্ধান করুন..."
                    />
                </div>
                <button type="submit">
                    <img src={icons.searchBtn} alt="" />
                </button>
            </div>
        </form>
    );
}

function SearchOption() {
    const [show, setShow] = useState(false);

    return (
        <span className="btn btn-30 search-menu-button">
            <img
                src={icons.search}
                alt=""
                onClick={() => {
                    setShow(true);
                }}
            />
            <SearchForm show={show} setShow={setShow} />
        </span>
    );
}

function LeftMenu({ currentTime }) {
    const { handelShowLeftMenu } = useMenu();

    return (
        <>
            <div className="flex-row align-space-between header-left-buttons">
                <span className="btn btn-30" onClick={handelShowLeftMenu}>
                    <img src={icons.menu} alt="" />
                </span>

                <SearchOption />
            </div>

            <div className="show-date-time">
                <ShowTime time={currentTime} />
            </div>
        </>
    );
}

function HeaderContainer() {
    const { currentTime, configs } = useInit();

    return (
        <div className="flex-row align-space-between position-middle h-100p">
            <div className="w-200">
                <LeftMenu currentTime={currentTime} />
            </div>
            <div className="text-center">
                <a href="/">
                    <img src={logos.bahannoLogoLg} alt="Bahanno Log" className="bahanno-banner" />
                </a>
            </div>
            <div className="w-200">
                <HeaderRightMenu configs={configs} />
            </div>
        </div>
    );
}

export default HeaderContainer;
