import BN from 'flatpickr/dist/l10n/bn';
import 'flatpickr/dist/themes/material_red.css';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { useSearch } from '../../../contexts/SearchProvider';
import SearchOptH from './SearchOptH';

function SearchOptionDate({ id, handelShowClick, showOption }) {
    const { panelData, onChangeDate } = useSearch();

    return (
        <div className={`search-page-option ${id === showOption ? 'show' : ''}`}>
            <SearchOptH
                title="তারিখ"
                id={id}
                handelShowClick={handelShowClick}
                showOption={showOption}
            />
            <div className="option-content animate">
                <div className="search-input-date">
                    <div className="form-input">
                        <Flatpickr
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                                locale: { ...BN.bn, firstDayOfWeek: 6 }
                            }}
                            value={panelData.date}
                            onChange={onChangeDate}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchOptionDate;
