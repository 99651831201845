import React, { createContext, useContext, useMemo, useState } from 'react';
import LeftSideMenu from '../utils/features/LeftSideMenu';

const MenuContext = createContext();

export function useMenu() {
    return useContext(MenuContext);
}

function StickyProvider({ children }) {
    const [showLeftMenu, setShowLeftMenu] = useState(false);

    const value = useMemo(() => {
        const handelShowLeftMenu = () => {
            setShowLeftMenu(true);
        };

        return {
            handelShowLeftMenu
        };
    }, []);

    return (
        <MenuContext.Provider value={value}>
            {children}
            <LeftSideMenu showLeftMenu={showLeftMenu} setShowLeftMenu={setShowLeftMenu} />
        </MenuContext.Provider>
    );
}

export default StickyProvider;
