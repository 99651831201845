import React from 'react';

function FacebookLike() {
    return (
        <div
            className="fb-page fb_iframe_widget"
            data-href="https://www.facebook.com/bahannonews"
            data-width="342"
            style={{ width: '100%', height: '100%' }}
        >
            <span style={{ verticalAlign: 'bottom', width: '100%', height: '100%' }}>
                <iframe
                    name="facfada283ae57bdb"
                    width="100%"
                    height="100%"
                    data-testid="fb:page Facebook Social Plugin"
                    title="fb:page Facebook Social Plugin"
                    frameBorder="0"
                    allow="encrypted-media"
                    src="https://www.facebook.com/v15.0/plugins/page.php?app_id=267468781915777&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df9d4d217467b3d2c1%26domain%3Dwww.bahannonews.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.bahannonews.com%252Ffffe6eb8d7c91a875%26relation%3Dparent.parent&amp;container_width=330&amp;href=https%3A%2F%2Fwww.facebook.com%2Fbahannonews&amp;locale=en_US&amp;sdk=joey&amp;width=330"
                    style={{
                        border: 'none',
                        visibility: 'visible',
                        width: '100%',
                        height: '100%'
                    }}
                    className=""
                />
            </span>
        </div>
    );
}

export function FacebookLikeSm() {
    return (
        <div
            className="fb-page fb_iframe_widget"
            data-href="https://www.facebook.com/bahannonews"
            data-width="500"
            style={{ width: '100%', height: '100%' }}
        >
            <span style={{ verticalAlign: 'bottom', width: '100%', height: '100%' }}>
                <iframe
                    name="facfada283ae57bdb"
                    width="100%"
                    height="100%"
                    data-testid="fb:page Facebook Social Plugin"
                    title="fb:page Facebook Social Plugin"
                    frameBorder="0"
                    allow="encrypted-media"
                    src="https://www.facebook.com/v15.0/plugins/page.php?app_id=267468781915777&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df9d4d217467b3d2c1%26domain%3Dwww.bahannonews.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.bahannonews.com%252Ffffe6eb8d7c91a875%26relation%3Dparent.parent&amp;container_width=500&amp;href=https%3A%2F%2Fwww.facebook.com%2Fbahannonews&amp;locale=en_US&amp;sdk=joey&amp;width=500"
                    style={{
                        border: 'none',
                        visibility: 'visible',
                        width: '100%',
                        height: '100%'
                    }}
                    className=""
                />
            </span>
        </div>
    );
}

export default FacebookLike;
