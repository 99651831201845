import { useState } from 'react';
import { useAuth } from '../../../contexts/AuthProvider';
import countryList from '../../../utils/country.json';
import { ApiUpdateProfileInfo } from '../UserPanelOperation';

const initialState = {
    name: '',
    email: '',
    phone: '',
    bDdate: '',
    country: ''
};

function SectionInto({ setEditMode, editMode }) {
    return (
        <div className="_intro flex-row position-middle align-space-between">
            <h2>My Profile</h2>

            <button
                type="button"
                className={editMode ? 'active' : ''}
                onClick={() => setEditMode((prev) => !prev)}
            >
                Edit Profile
            </button>
        </div>
    );
}

function InputField({ label, name, type, value, onChange, placeholder, options }) {
    if (options) {
        return (
            <div className="input-field flex-col position-top">
                <label htmlFor={name}>{label}</label>
                <select name={name} value={value} onChange={onChange}>
                    <option value="">Select a country</option>
                    {options.map((country) => (
                        <option key={country.code} value={country.code}>
                            {country.name}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
    return (
        <div className="input-field flex-col position-top">
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

function MenuProfile() {
    const { currentUser } = useAuth();
    const [formData, setFormData] = useState({
        ...initialState,
        ...currentUser,
        name: currentUser.displayName
    });
    const [editMode, setEditMode] = useState(false);
    const handleOnChange = (ev) => {
        const { name, value } = ev.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdateProfile = () => {
        ApiUpdateProfileInfo(formData)
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div className="_user_profile">
            <SectionInto setEditMode={setEditMode} editMode={editMode} />
            <hr className="divider" />
            <div className="_input_wrapper">
                <div className="flex-row position-middle flex-gap-25">
                    <InputField
                        label="Full Name"
                        name="name"
                        placeholder="Enter your full name"
                        type="text"
                        value={formData.name}
                        onChange={handleOnChange}
                    />
                    <InputField
                        label="Email"
                        name="email"
                        placeholder="Enter your email"
                        type="email"
                        value={formData.email}
                        onChange={handleOnChange}
                    />
                </div>
                <div className="flex-row position-middle flex-gap-25">
                    <InputField
                        label="Phone Number"
                        name="phone"
                        placeholder="Enter your phone number"
                        type="text"
                        value={formData.phoneNumber}
                        onChange={handleOnChange}
                    />
                    <InputField
                        label="Date of Birth"
                        name="bDdate"
                        placeholder="Enter your date of birth"
                        type="date"
                        value={formData.bDdate}
                        onChange={handleOnChange}
                    />
                </div>
                <InputField
                    label="Country"
                    name="country"
                    placeholder="Enter your country"
                    type="text"
                    value={formData.country}
                    onChange={handleOnChange}
                    options={countryList}
                />
            </div>
            {editMode ? (
                <div className="flex-row">
                    <button
                        type="button"
                        className="_cancel_btn"
                        onClick={() => setEditMode(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="_update_btn"
                        onClick={() => handleUpdateProfile()}
                    >
                        Update
                    </button>
                </div>
            ) : null}
        </div>
    );
}

export default MenuProfile;
