import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Img from '../../../utils/Img';
import { ThreeCatHead } from '../../websites/comps/SectionHead';

const storeName = 'three-cat';

function CatHead({ categories, handleSetCat, isSelected, index }) {
    return (
        <div
            onClick={() => {
                handleSetCat(categories[index], index);
            }}
            role="button"
            tabIndex={-1}
            className={`head ${isSelected ? 'selected' : ''}`}
        >
            <ThreeCatHead title={categories[index].title} />
        </div>
    );
}

function CatSmall({ article }) {
    const ref = useRef();

    return (
        <Link
            to={article?.link}
            className={`three-cat-item flex-row position-middle ${
                article?.demo ? 'placeholder-mode' : ''
            }`}
        >
            <div ref={ref}>
                <Img src={article?.image} pRef={ref} />
            </div>
            {article?.demo ? (
                <div>
                    <span className="">---------------------------</span>
                    <span className="">-------------------</span>
                </div>
            ) : (
                <div>
                    <span className="pre">{article?.pre_title} / </span>
                    <span>{article?.title}</span>
                </div>
            )}
        </Link>
    );
}

function ThreeCatSec({ categories }) {
    const [cat, setCat] = useState([]);

    const handleSetCat = (obj, index) => {
        setCat(obj);
        localStorage.setItem(storeName, index);
    };

    useEffect(() => {
        const i = parseInt(localStorage.getItem(storeName), 10) || 0;
        setCat(categories[i]);
    }, [categories]);

    return (
        <div className="three-cat-main flex-col align-stretch position-space-between">
            <div className="three-cat-head flex-row align-space-none position-stretch w-100p text-center">
                {categories.map((item, i) => (
                    <CatHead
                        categories={categories}
                        handleSetCat={handleSetCat}
                        index={i}
                        isSelected={cat.title === item.title}
                        key={item?.title}
                    />
                ))}
            </div>

            {cat?.items?.slice(0, 5).map((item) => (
                <CatSmall article={item} key={item.sl} />
            ))}

            <div className={`latest-footer ${!cat ? 'placeholder-mode' : ''}`}>
                <Link to={cat.moreLink}>
                    <span>আরও...</span>
                </Link>
            </div>
        </div>
    );
}

function ThreeCat({ articles }) {
    const categories = [
        {
            title: 'খেলা',
            moreLink: '/sports/',
            items: articles ? articles['category/sports'] : []
        },
        {
            title: 'বিনোদন',
            moreLink: '/entertainment/',
            items: articles ? articles['category/entertainment'] : []
        },
        {
            title: 'চাকরি',
            moreLink: '/jobs/',
            items: articles ? articles['category/jobs'] : []
        }
    ];
    return <ThreeCatSec categories={categories} />;
}

export default ThreeCat;
