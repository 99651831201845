import React from 'react';
import { EducationHead } from '../websites/comps/SectionHead';

function PrivacyPolicyPage() {
    return (
        <div className="_static">
            <div className="_container">
                <EducationHead title="গোপনীয়তা নীতি" />
                <div className="tc-gap"> </div>

                <div className="tc-info">
                    <p>
                        All contents, including news, quotes, data, photos, videos, graphics and
                        other information on{' '}
                        <a href="https://www.bahannonews.com/" target="blank">
                            bahannonews.com
                        </a>{' '}
                        site is provided for your personal information only. It is not placed for
                        any sort of commercial use. The information collected on this site are for
                        the exclusive use of
                        <a href="https://www.bahannonews.com/" target="blank">
                            bahannonews.com
                        </a>{' '}
                        and cannot be disclosed or sold to third parties. The readers of this site
                        have the rights of access for reading only.
                    </p>
                </div>
                <div className="tc-info">
                    <p>
                        <a href="https://www.bahannonews.com/" target="blank">
                            bahannonews.com
                        </a>{' '}
                        implements technology and security features in order to safeguard the
                        privacy of its readers from loss, unauthorized access or improper use.
                    </p>
                </div>
                <div className="tc-info">
                    <p>
                        Users of the site are invited to inform{' '}
                        <a href="https://www.bahannonews.com/" target="blank">
                            bahannonews.com
                        </a>{' '}
                        services on any dysfunctions of the site in the light of privacy rights. If
                        you need any more data or have any questions about our privacy policy,
                        please contact us by email at{' '}
                        <a href="mailto:bahannonews@gmail.com" target="blank">
                            bahannonews@gmail.com
                        </a>
                    </p>
                </div>

                <div className="tc-gap"> </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
