import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Img from '../../../utils/Img';

function LeadLarge({ article, noBorder, placed }) {
    const rArea = useRef();

    return (
        <Link
            to={article?.link}
            className={`lead-large flex-col align-stretch ${
                noBorder ? 'no-border' : ''
            } ${placed} ${!article ? 'placeholder-mode' : ''}`}
            ref={rArea}
        >
            <Img src={article ? article?.image : null} pRef={rArea} />
            <div className="fill flex-col position-middle">
                <h1>
                    {article?.pre_title ? (
                        <span className="pre">{article?.pre_title} / </span>
                    ) : null}
                    <span>{article?.title || '--------------------------------'}</span>
                </h1>
                <p>
                    {article?.summery ? (
                        <span>{article?.summery}</span>
                    ) : (
                        <>
                            <span className="d-block-i">
                                ---------------------------------------------
                            </span>
                            <span>---------------------------------------------</span>
                        </>
                    )}
                </p>
            </div>
        </Link>
    );
}

export default LeadLarge;
