import FindInArray from '../projects/utils/FindInArray';

const staticCat = [
    {
        id: '/',
        title: 'প্রচ্ছদ',
        link: '/'
    },
    {
        id: 'latest',
        title: 'সর্বশেষ',
        link: '/latest/'
    }
];

export function getTopLinks(categories) {
    const topMenu = [
        '/',
        '/latest/',
        '/national/',
        '/politics/',
        '/bangladesh/',
        '/international/',
        '/economics/',
        '/crime/',
        '/education/',
        '/entertainment/',
        '/opinion/',
        '/sports/',
        '/health/',
        '/technology/',
        '/literature/'
    ];

    const FindIn = new FindInArray().init([...staticCat, ...categories], 'link');
    return topMenu.map((link) => FindIn.find(link)).filter((item) => !!item);
}

export function getLinkProperty(link, categories) {
    return [...staticCat, ...categories].find((item) => item.link === link);
}

function ConfigLinks() {
    return null;
}

export default ConfigLinks;
