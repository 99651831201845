import React from 'react';
import { EducationHead } from '../websites/comps/SectionHead';

function TcCommentPage() {
    return (
        <div className="_static">
            <div className="_container">
                <EducationHead title="মন্তব্যের নীতিমালা" />
                <div className="tc-gap"> </div>

                <div className="tc-info">
                    <h3>মন্তব্য প্রকাশের নীতিমালাঃ</h3>
                    <p>
                        <ul>
                            <li>বাংলাদেশের প্রচলিত আইন লঙ্ঘন করে কোনো মন্তব্য করা যাবে না।</li>
                            <li>
                                দেশীয় বা দেশের বাইরের কোনো ব্যক্তি, জাতি, গোষ্ঠী, ভাষা ও ধর্মের
                                প্রতি অবমাননামূলক বা কারও অনুভূতিতে আঘাত দিতে পারে এমন কোনো মন্তব্য
                                করা যাবে না।
                            </li>
                            <li>
                                মন্তব্যে অশ্লীল ও অশালীন ইঙ্গিতপূর্ণ কোনো শব্দ, শব্দবন্ধ বা বাক্য
                                ব্যবহার করা যাবে না।
                            </li>
                            <li>
                                কাউকে হেয় প্রতিপন্ন করতে অবমাননামূলকভাবে কোনো প্রাণীবাচক নাম দেওয়া
                                যাবে না, নাম বিকৃত করা যাবে না।
                            </li>
                            <li>কাউকে ব্যক্তিগতভাবে আক্রমণ করা যাবে না।</li>
                            <li>কাউকে ভয় দেখানো বা হুমকি দেওয়া যাবে না।</li>
                            <li>
                                এমন কোনো নাম বা ছদ্মনাম (ইউজার নেম বা নিকনেম) ব্যবহার করা যাবে না যা
                                উদ্দেশ্যমূলক, আপত্তিকর বা ইঙ্গিতপূর্ণ।
                            </li>
                            <li>মন্তব্যে কোনো লিংক দেওয়া যাবে না।</li>
                            <li>ইংরেজি হরফে বাংলায় মন্তব্য করা যাবে না।</li>
                            <li>
                                দৃষ্টিকটু বানান ভুল, অসম্পূর্ণ বা অসংলগ্ন বাক্যের মন্তব্য প্রকাশ করা
                                হবে না।
                            </li>
                        </ul>
                    </p>
                </div>

                <div className="tc-gap"> </div>
            </div>
        </div>
    );
}

export default TcCommentPage;
