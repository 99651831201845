import React from 'react';
import { Link } from 'react-router-dom';
import { getTopLinks } from '../../../configs/ConfigLinks';
import { icons } from '../../../configs/Icons';
import { useInit } from '../../contexts/InitProvider';
import { useMenu } from '../../contexts/MenuProvider';
import { useSticky } from '../../contexts/StickyProvider';

function MenuCont() {
    const { selectedMenu, categories } = useInit();
    const { handelShowLeftMenu } = useMenu();
    const { scrollDirection } = useSticky();
    const topMenu = getTopLinks(categories);

    const isScrollDown = scrollDirection === 'down';

    const isScrollDownAndNotHomeCat = selectedMenu !== '/' && isScrollDown;

    return (
        <div className="h-100p">
            <ul className="menu flex-row align-space-between position-stretch">
                <li
                    className={`flex-row position-middle align-center animate ${isScrollDown ? 'show' : 'hidden'}`}
                >
                    <span
                        className="btn btn-30"
                        onClick={handelShowLeftMenu}
                        role="button"
                        tabIndex={-1}
                    >
                        <img src={icons.menuWhite} alt="menu" />
                    </span>
                </li>
                {topMenu.map((item) => (
                    <li
                        className={`${selectedMenu === item.link ? ' selected' : ''}`}
                        key={item.id}
                    >
                        {isScrollDownAndNotHomeCat && selectedMenu === item?.link ? (
                            <a
                                href={item.link}
                                className="flex-row position-middle h-100p menu-link"
                            >
                                <span>{item.title}</span>
                            </a>
                        ) : (
                            <Link
                                to={item.link}
                                className="flex-row position-middle h-100p menu-link"
                            >
                                <span>{item.title}</span>
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default MenuCont;
