import React from 'react';
import { arrows } from '../../../configs/Icons';

export function ArrowPrevS({ onPrevSingle }) {
    return (
        <a href="/" onClick={onPrevSingle} style={{ float: 'left', paddingLeft: 10 }}>
            <img src={arrows.prevS} alt="Cal Prev Single" />
        </a>
    );
}

export function ArrowPrevD({ onPrevDouble }) {
    return (
        <a href="/" onClick={onPrevDouble} style={{ float: 'left', paddingLeft: 10 }}>
            <img src={arrows.prevD} alt="Cal Prev Double" />
        </a>
    );
}

export function ArrowNextS({ onNextSingle }) {
    return (
        <a href="/" onClick={onNextSingle} style={{ float: 'right', paddingRight: 10 }}>
            <img src={arrows.nextS} alt="Cal Next Double" />
        </a>
    );
}

export function ArrowNextD({ onNextDouble }) {
    return (
        <a href="/" onClick={onNextDouble} style={{ float: 'right', paddingRight: 10 }}>
            <img src={arrows.nextD} alt="Cal Next Single" />
        </a>
    );
}

function CalArrows() {
    return <div>CalArrows</div>;
}

export default CalArrows;
