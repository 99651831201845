import React from 'react';
import ConvertStringBn from '../../../utils/ConvertStringBn';

function ShowTime({ time }) {
    const days = {
        Sun: 'Sunday',
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday'
    };
    const newDate = new Date(time * 1000);
    const [day, month, date, year] = newDate.toDateString().split(' ');

    let hours = newDate.getHours();
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;
    hours = hours.toString().padStart(2, '0');

    if (!time) {
        return null;
    }

    return <>{ConvertStringBn(`${days[day]}, ${date} ${month} ${year}`)}</>;
}

export default ShowTime;
