import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInit } from '../../contexts/InitProvider';
import LayoutProvider from '../../contexts/LayoutProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import Sidebar from '../system/Sidebar';
import SidebarSm from '../system/SidebarSm';
import AdsContainer0 from '../websites/ads/AdsContainer0';
import ArchiveCatSection from './comps/ArchiveCatSection';
import ArchiveDateNav from './comps/ArchiveDateNav';

const req = {
    recent: 5,
    gallery: 5,
    'category/sports': 5,
    'category/entertainment': 5,
    'category/jobs': 5
};

function ArchivePage() {
    const { setSelectedMenu, categories } = useInit();
    const { date: pDate } = useParams();
    const myDay = pDate ? dayjs(pDate) : dayjs(new Date());
    const date = myDay.format('YYYY-MM-DD');
    const pageKey = `archive/${date}`;

    // --Articles
    const [articles, setArticles] = useState(null);

    useEffect(() => {
        setArticles(null);
        AxiosAuth.setApiUrl('PAGE_REQUIREMENT')
            .post({ ...req, [pageKey]: 200 })
            .then(({ data }) => {
                setArticles(data);
            });
    }, [pageKey]);

    useEffect(() => {
        document.title = `আর্কাইভ || ${process.env.REACT_APP_COMPANY_TITLE}`;
    }, [setSelectedMenu]);

    // --Scroll to Top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <div className="_ads">
                <div className="_container">
                    <AdsContainer0 />
                </div>
            </div>

            <div className="_archive">
                <div className="_container flex-row align-space-between">
                    <div
                        className={`_page_left flex-row flex-wrap align-space-between ${
                            !articles ? 'placeholder-mode' : ''
                        }`}
                    >
                        <div className="archive-nav">
                            <ArchiveDateNav
                                totalCount={(articles?.[pageKey]?.length || 0).toString()}
                                date={date}
                            />
                        </div>

                        <div className="archive-section">
                            <ArchiveCatSection
                                articles={articles?.[pageKey] || null}
                                categories={categories}
                            />
                        </div>
                    </div>
                    <div className="_page_right">
                        <LayoutProvider>
                            <Sidebar articles={articles} />
                            <SidebarSm articles={articles} layout="md" />
                        </LayoutProvider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ArchivePage;
