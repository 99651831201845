/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

export function TextInput({ name, value, setValue, label, type, placeholder, className }) {
    return (
        <div className={`form-input ${className}`}>
            <label>{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={setValue}
                placeholder={placeholder}
            />
        </div>
    );
}

function FormInput() {
    return null;
}

export default FormInput;
