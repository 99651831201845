import React from 'react';
import { EducationHead } from '../../websites/comps/SectionHead';
import LeadLarge from './LeadLarge';
import LeadSmall from './LeadSmall';

// function LeadSmall({ article }) {
//     const ref = useRef();
//     return (
//         <Link
//             to={article?.link}
//             className="entertainment-small flex-col position-space-between align-stretch h-100p"
//             ref={ref}
//         >
//             <Img src={article?.image} pRef={ref} />
//             <h4>
//                 {article?.pre_title ? <span className="pre">{article?.pre_title} / </span> : null}
//                 {article?.title}
//             </h4>
//         </Link>
//     );
// }

function EntertainmentMain({ articles }) {
    return (
        <div className="entertainment-main flex-col align-stretch h-100p">
            <div>
                <EducationHead title="বিনোদন" titleBg="red-gradient" myStyles={null} />
            </div>
            <div style={{ height: '10px' }} />
            <div className="entertainment-sub flex-row align-space-between position-stretch fill">
                <div className="sports-col-1 flex-col align-stretch">
                    <div className="fill">
                        <LeadSmall article={articles ? articles[0] : null} />
                    </div>
                    <div className="fill">
                        <LeadSmall article={articles ? articles[1] : null} />
                    </div>
                </div>
                <div className="sports-col-2 flex-col align-stretch">
                    <div className="fill">
                        <LeadLarge article={articles ? articles[2] : null} />
                    </div>
                </div>
                <div className="sports-col-1 flex-col align-stretch">
                    <div className="fill">
                        <LeadSmall article={articles ? articles[3] : null} />
                    </div>
                    <div className="fill">
                        <LeadSmall article={articles ? articles[4] : null} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EntertainmentMain;
