function ToggleArowButton({ show, onClick }) {
    return (
        <button
            className={`sidebar-popup_toggle-arrow ${show ? 'active' : ''}`}
            type="button"
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="_7VZb-">
                <path
                    d="m8.326 12 3.875 3.9c.183.183.279.413.287.688a.93.93 0 0 1-.287.712.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 5.926 12c0-.133.02-.258.062-.375a.877.877 0 0 1 .213-.325l4.6-4.6a.977.977 0 0 1 .687-.287.93.93 0 0 1 .713.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L8.326 12zm6.6 0 3.875 3.9c.183.183.279.413.287.688a.93.93 0 0 1-.287.712.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.876.876 0 0 1-.213-.325 1.106 1.106 0 0 1-.062-.375c0-.133.02-.258.062-.375a.876.876 0 0 1 .213-.325l4.6-4.6a.977.977 0 0 1 .687-.287.93.93 0 0 1 .713.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L14.926 12z"
                    fill="#fff"
                    fillOpacity=".87"
                />
            </svg>
        </button>
    );
}

export default ToggleArowButton;
