/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { icons } from '../../../../configs/Icons';
import { TextInput } from '../../../utils/inputs/FormInput';

function BtnSignUp() {
    return (
        <div className="login-btn">
            <button type="button" className="auth-btn">
                পরিবর্তন করুন
            </button>
        </div>
    );
}

function InpOtp() {
    return (
        <div className="flex-row flex-wrap align-space-between position-bottom">
            <div className="fill">
                <TextInput
                    value=""
                    setValue={null}
                    label="ওটিপি"
                    type="text"
                    placeholder="ওটিপি লিখুন"
                    className={null}
                />
            </div>
            <div style={{ minWidth: '15px' }} />
            <div>
                <label className="w-100p d-block">&nbsp;</label>
                <button type="button" className="auth-btn otp">
                    রিকোয়েস্ট ওটিপি
                </button>
            </div>
        </div>
    );
}

function LoginOptions() {
    return (
        <div className="text-center">
            <span>লগইনে ফেরত যেতে চান?</span> <a href="/">ক্লিক করুন</a>
        </div>
    );
}

function ForgetPasswordModal({ show }) {
    if (!show) {
        return false;
    }

    return (
        <div className="modal auth">
            <div className="modal-main">
                <div className="modal-head flex-row align-space-between position-middle">
                    <h3>পাসওয়ার্ড রিসেট</h3>
                    <img src={icons.close} alt="" />
                </div>
                <div className="modal-body">
                    <div>
                        <TextInput
                            value=""
                            setValue={null}
                            label="ইমেইল"
                            type="email"
                            placeholder="আপনার ইমেইল এড্রেস লিখুন"
                            className={null}
                        />

                        <InpOtp />

                        <div className="flex-row align-space-none">
                            <div className="full">
                                <TextInput
                                    value=""
                                    setValue={null}
                                    label="পাসওয়ার্ড"
                                    type="password"
                                    placeholder="আপনার পাসওয়ার্ড লিখুন"
                                    className="full"
                                />
                            </div>
                            <div style={{ maxWidth: '15px' }} />
                            <div className="full">
                                <TextInput
                                    value=""
                                    setValue={null}
                                    label="পুনঃ পাসওয়ার্ড"
                                    type="password"
                                    placeholder="আপনার পাসওয়ার্ড লিখুন"
                                    className={null}
                                />
                            </div>
                        </div>

                        <BtnSignUp />

                        <LoginOptions />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgetPasswordModal;
