/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import { getTopLinks } from '../../../configs/ConfigLinks';
import { icons } from '../../../configs/Icons';
import { useInit } from '../../contexts/InitProvider';
import { useMenu } from '../../contexts/MenuProvider';

function MenuContSm() {
    const { selectedMenu, categories } = useInit();
    const topMenu = getTopLinks(categories);
    const { handelShowLeftMenu } = useMenu();

    return (
        <div className="h-100p flex-row position-middle mobile-menu">
            <div className="menu-opener animate" onClick={handelShowLeftMenu}>
                <img src={icons.menu} alt="menu" />
            </div>
            <div className="menu-index">
                <Link to="/" className="flex-row position-middle h-100p menu-link">
                    <span>প্রচ্ছদ</span>
                </Link>
            </div>
            <div className="menu-scroll fill">
                <ul className="menu flex-row align-space-between position-stretch">
                    {topMenu.map((item) => (
                        <li
                            className={`${selectedMenu === item.link ? ' selected' : ''}`}
                            key={item.id}
                        >
                            <Link
                                to={item.link}
                                className="flex-row position-middle h-100p menu-link"
                            >
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default MenuContSm;
