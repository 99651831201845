import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import Img from '../../../utils/Img';
import { EducationHead } from '../../websites/comps/SectionHead';

function GalleryObject({ article }) {
    const ref = useRef();

    return (
        <Link to={article?.link || '/'} className="gallery-object" ref={ref}>
            {/* <img src={icons.gallery} alt="" className="gallery-icon" /> */}
            <img src={icons.gallery} className="gallery-icon" alt="icon" />
            <Img src={article?.image || null} pRef={ref} cName="gallery-image" />
        </Link>
    );
}

function GallerySm({ articles }) {
    return (
        <div className="gallery-main flex-col position-space-between align-stretch h-100p">
            <div>
                <EducationHead title="ফটো গ্যালারী" titleBg="red-gradient" />
            </div>

            <div className="g-obg-1 flex-row align-space-between position-stretch">
                <div>
                    <GalleryObject article={articles ? articles[0] : null} />
                </div>
                {/* <div>
                    <GalleryObject article={articles ? articles[1] : null} />
                </div> */}
            </div>
            <div className="g-obg-2 flex-row align-space-between position-stretch">
                <div>
                    <GalleryObject article={articles ? articles[1] : null} />
                </div>
                <div>
                    <GalleryObject article={articles ? articles[2] : null} />
                </div>
            </div>
            <div className="g-obg-2 flex-row align-space-between position-stretch">
                <div>
                    <GalleryObject article={articles ? articles[3] : null} />
                </div>
                <div>
                    <GalleryObject article={articles ? articles[4] : null} />
                </div>
            </div>
        </div>
    );
}

export default GallerySm;
