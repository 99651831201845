import React, { useState } from 'react';
import { icons, logos } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import { useComment } from '../../contexts/CommentProvider';
import { useTemplate } from '../../contexts/TemplateProvider';
import ConvertStringBn from '../ConvertStringBn';
import FormHandler from '../FormHandler';
import { GetDate } from '../Show';
import iopt from '../iopt';
import CommentOptions from './CommentOptions';
import CommentReplies from './CommentReplies';

function ReplyInput({ show, comment, setShow }) {
    const { addComments } = useComment();
    const { setMessage } = useTemplate();
    const { formData, setFormData, handle } = FormHandler({});

    const handelCloseItem = () => {
        setShow(false);
    };

    const handelSubmitComment = (ev) => {
        ev.preventDefault();
        setMessage('সংরক্ষণ হচ্ছে...');

        addComments(comment.commentId, formData)
            .then(({ error, message }) => {
                if (error === 0) {
                    setFormData({});
                    setMessage('কমেন্টটি সংরক্ষণ করা হয়েছে।');
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    if (!show) {
        return null;
    }

    return (
        <form className="replay-section" onSubmit={handelSubmitComment}>
            <span onClick={handelCloseItem} role="button" tabIndex={-1}>
                <img src={icons.close} alt="" />
            </span>
            <textarea
                placeholder="আপনার মন্তব্যটি লিখুন..."
                className="comment-input"
                spellCheck="false"
                name="comment"
                value={formData.comment || ''}
                onChange={handle}
            />
            <div className="flex-row align-right">
                <button type="submit" className={`sm ${formData.comment ? 'active' : ''}`}>
                    উত্তর দিন
                </button>
            </div>
        </form>
    );
}

function CommentPreview({ comment, removeComment, updateLike }) {
    const { openLoginModal } = useAuth();
    const { setMessage, openConfirm, closeConfirm } = useTemplate();
    const [show, setShow] = useState(false);
    const img = comment?.profileImage ? iopt(comment?.profileImage) : logos.bahannoLogoSm;

    const handelYesClick = () => {
        setMessage('কমেন্টটি মুছে ফেলা হচ্ছে...');
        removeComment(comment.commentId)
            .then(({ error, message }) => {
                if (error === 0) {
                    closeConfirm();
                    setMessage('কমেন্টটি মুছে ফেলা হয়েছে।');
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    const handelDeleteClick = () => {
        //
        openConfirm(null, 'আপনি কি কমেন্টটি মুছতে চান?', [
            <button type="button" className="confirm-button gray" id="no" onClick={closeConfirm}>
                না
            </button>,
            <button type="button" className="confirm-button red" id="yes" onClick={handelYesClick}>
                হ্যাঁ
            </button>
        ]);
    };

    const handelUpdateLike = (ev) => {
        const likeMap = { like: 1, dislike: -1 };
        const likeMode = likeMap[ev.target.id] || 0;

        setMessage('আপডেট হচ্ছে...');
        updateLike(comment.commentId, likeMode)
            .then(({ error, message, action }) => {
                if (error === 0) {
                    closeConfirm();
                    setMessage('আপডেট হয়েছে।');
                } else {
                    setMessage(message);

                    if (action === 'openLoginWindow') {
                        openLoginModal();
                    }
                }
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="preview-section flex-row position-stretch">
            <img src={img} alt="" className="auth-image sm" />
            <div className="fill">
                <div className="flex-row position-middle">
                    <span className="auth-name">{comment.profileName}</span>
                    <span>&nbsp; &nbsp;</span>
                    <span className="auth-date-time">{ConvertStringBn(GetDate(comment.time))}</span>
                </div>
                <div className="auth-text">{comment.comment}</div>

                <CommentOptions
                    comment={comment}
                    setShow={setShow}
                    handelUpdateLike={handelUpdateLike}
                    handelDeleteClick={handelDeleteClick}
                />

                <ReplyInput show={show} setShow={setShow} comment={comment} />

                {comment?.childes
                    ?.sort((a, b) => {
                        if (a.time > b.time) return -1;
                        if (a.time < b.time) return 1;
                        return 0;
                    })
                    .map((item) => (
                        <CommentReplies comment={item} key={item.commentId} />
                    ))}
            </div>
        </div>
    );
}

export default CommentPreview;
