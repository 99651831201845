import dayjs from 'dayjs';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

const StickyContext = createContext();

export function useSticky() {
    return useContext(StickyContext);
}

function StickyProvider({ children }) {
    const ref = useRef();

    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
        if (scrollDirection === 'up') {
            ref.current.classList.remove('sticky-down');
            ref.current.classList.add('sticky-up');
        }
        if (scrollDirection === 'down') {
            ref.current.classList.remove('sticky-up');
            ref.current.classList.add('sticky-down');
        }
    }, [scrollDirection]);

    useEffect(() => {
        let prevTime = dayjs().valueOf();
        let prevScrollPos = window.scrollY;

        const handleScroll = () => {
            const cuTime = dayjs().valueOf();
            const currentScrollPos = window.scrollY;

            if (cuTime - prevTime > 200) {
                if (prevScrollPos - currentScrollPos > 0) {
                    setScrollDirection('up');
                } else if (prevScrollPos - currentScrollPos < 0) {
                    setScrollDirection('down');
                }
                prevTime = cuTime;
            }

            prevScrollPos = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const value = useMemo(() => {
        return {
            scrollDirection
        };
    }, [scrollDirection]);

    return (
        <StickyContext.Provider value={value}>
            <div className="sticky animate" ref={ref}>
                {children}
            </div>
        </StickyContext.Provider>
    );
}

export default StickyProvider;
