import { useRef } from 'react';
import { useAuth } from '../../../contexts/AuthProvider';
import Img from '../../../utils/Img';

function SidebarMenuTopRow() {
    const ref = useRef();
    const { currentUser } = useAuth();

    const { photoUrl, displayName, email } = currentUser;
    return (
        <div className="_sidebar_profile" ref={ref}>
            <div className="_sidebar_profile_img">
                <Img src={photoUrl || null} pRef={ref} />
            </div>
            <div className="_sidebar_profile_title">
                <h3>{displayName || '----'}</h3>
                <p>{email || '---'}</p>
            </div>
        </div>
    );
}

export default SidebarMenuTopRow;
