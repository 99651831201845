import { useState } from 'react';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { shares } from '../../../../../configs/Icons';
import MoreShareIcons from '../../../../app/details/comps/MoreShareIcons';

function Option({ children, active }) {
    return (
        <div className="option">
            <div className={`share-area ${active ? 'active' : ''}`}>{children}</div>
        </div>
    );
}

function OptionContainer({ children }) {
    return <div className="option_container">{children}</div>;
}

function PopupContent({ shareUrl, imageUrl }) {
    const [showMore, setShowMore] = useState(false);

    const handelShow = (ev) => {
        ev.preventDefault();
        setShowMore((prev) => !prev);
    };

    return (
        <div className="sidebar-popup_content">
            <OptionContainer>
                <Option>
                    <FacebookShareButton className="flex-row position-middle" url={shareUrl}>
                        <img src={shares.shareFacebook} alt="" />
                        <img src={shares.shareFacebookHover} alt="" />
                    </FacebookShareButton>
                </Option>
                <Option>
                    <TwitterShareButton className="flex-row position-middle" url={shareUrl}>
                        <img src={shares.shareTwitter} alt="" />
                        <img src={shares.shareTwitterHover} alt="" />
                    </TwitterShareButton>
                </Option>
                <Option>
                    <EmailShareButton
                        openShareDialogOnClick
                        className="flex-row position-middle"
                        url={shareUrl}
                        onClick={null}
                    >
                        <img src={shares.shareEmail} alt="" />
                        <img src={shares.shareEmailHover} alt="" />
                    </EmailShareButton>
                </Option>
                <Option active={showMore}>
                    <button type="button" className="share-other" onClick={handelShow}>
                        <img src={shares.shareMain} alt="" />
                        <img src={shares.shareMainHover} alt="" />
                    </button>

                    <MoreShareIcons
                        showMore={showMore}
                        setShowMore={setShowMore}
                        shareUrl={shareUrl}
                        imageUrl={imageUrl}
                    />
                </Option>
            </OptionContainer>
            <OptionContainer>
                <Option>
                    <button type="button" onClick={null} className="share-other">
                        <img src={shares.shareSave} alt="" />
                        <img src={shares.shareSaveHover} alt="" />
                    </button>
                </Option>
            </OptionContainer>
        </div>
    );
}
export default PopupContent;
